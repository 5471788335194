<div class="main-panel">
  <div>
    <div class="row m-0">
      <div class="col-12">
        <alert class="alert-position"></alert>
      </div>
    </div>
    <div class="col-12 p-2" style="display: contents;">
      <div class="spinner-container" *ngIf="loadingData">
        <span class="spinner-border spinner-large loadingSplinner" role="status">
          <span class="sr-only"></span>
        </span>
      </div>
      <div class="banner-wrpr" *ngIf="!loadingData && isYESPAY">
        <img src="../assets/img/arrow-back-black.svg" class="banner-back" (click)="goToSettings()">
        <img src="../assets/img/banner-holder.svg" class="banner-holder">
        <img src="../assets/img/banner-set-pin.svg" class="banner-img">
        <div class="img-container">
          <div class="cardBg ml-auto mr-auto text-center" [ngClass]="{'vcard':!userData?.isKeyChainUser}">
            <h1 class="cardNumber" style="padding-left: 27px;">
              <span>•••• •••• ••••</span><b> {{dataObj?.cardNumber}}</b>
            </h1>
            <div>
              <div class="d-flex align-items-center exp-txt-wrpr">
                <div class="exp-txt">Expiry Date</div>
                <div class="mh-ye-txt">
                  {{dataObj?.expiryDate ? (dataObj?.expiryDate)?.substr(0, 2) : '--'}}/{{dataObj?.expiryDate ?
                  (dataObj?.expiryDate)?.substr(-2, 4) : '--'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!loadingData && !isYESPAY">
        <div class="back-header">
          <img src="../assets/img/arrow-back-black.svg" (click)="goToSettings()">
        </div>
        <div class="img-container img-container-new">
          <div class="cardBg cardBg-set-pin ml-auto mr-auto text-center non-yespay-card ng-star-inserted">
            <h1 class="cardNumber">
              <span>{{physicalCard?.cardNumber}}</span>
            </h1>
            <h2 class="expDate clearfix">
              <span class="vld-thr">VALID <br> THRU</span>
              <div>
                <div class="mh-ye-txt">
                  {{menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'
                  ? (physicalCard?.expiryYear)?.substr(0, 2) + ' / ' + (physicalCard?.expiryYear)?.substr(-2, 4)
                  : '-- / --'}}
                </div>
              </div>
            </h2>
          </div>
        </div>
      </ng-container>

      <div class="row m-0 mt-1" id="tran-sce-info">
        <div class="col-12 mt-0 txnSec-wrpr h-100">
          <div class="h-100" *ngIf="!loadingData">
            <div class="txnSec-wrpr-content h-100">
              <iframe [attr.src]="iframeUrl" frameborder="0" width="100%" height="600"></iframe>
            </div>
          </div>
          <div class="spinner-container" *ngIf="loadingData">
            <span class="spinner-border spinner-large loadingSplinner" role="status">
              <span class="sr-only"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
