<div>
  <div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close model-cross-icon" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" class="pl-3 pr-3 pt-1 w-100">
      <mat-form-field class="form-group example-full-width mb-0 w-95">
        <mat-label>New Kit Number</mat-label>
        <input matInput type="text" numbersOnly class="form-control" placeholder="" name="kitNo" formControlName="kitNo" autocomplete="off">
        <div class="error-wrpr" *ngIf="editForm.get('kitNo').invalid && (editForm.get('kitNo').dirty || editForm.get('kitNo').touched || editForm.get('kitNo').errors.pattern)">
          <mat-error *ngIf="editForm.get('kitNo').errors.required || editForm.get('kitNo').errors.pattern">
            Please enter New Kit Number
          </mat-error>
        </div>
      </mat-form-field>
      <span class="fa fa-question-circle kitSuggetion2" (click)="infoModalClose()"></span>
      <mat-form-field class="form-group example-full-width mb-0 w-100">
        <mat-label>Last 4 digits of new card</mat-label>
        <input matInput type="text" numbersOnly class="form-control" placeholder="" name="cardLastDigits" formControlName="cardLastDigits" autocomplete="off">
        <div class="error-wrpr" *ngIf="editForm.get('cardLastDigits').invalid && (editForm.get('cardLastDigits').dirty || editForm.get('cardLastDigits').touched || editForm.get('cardLastDigits').errors.pattern)">
          <mat-error *ngIf="editForm.get('cardLastDigits').errors.required  || editForm.get('cardLastDigits').errors.minlength || editForm.get('cardLastDigits').errors.maxlength || editForm.get('cardLastDigits').errors.pattern">
            Please enter last 4 digits of New Card
          </mat-error>
        </div>
      </mat-form-field>
      <div class="text-center">
        <button type="button" class="btn btn-danger mr-2" (click)="decline()">{{ btnCancelText }}</button>
        <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="editForm.invalid || isSaving">{{ btnOkText }}</button>
      </div>
    </form>
  </div>
  <div id="myModal" class="img-modal info-modal" *ngIf="infoModalOpen">
    <span class="info-close" (click)="infoModalClose()">&times;</span>
    <img class="info-modal-content" id="img01" src="../../../assets/img/kit-number2.jpg">
  </div>
</div>
  <!-- <div class="modal-footer">
  </div> -->
