<div class="main-panel">
  <div>
    <div class="row m-0">
      <div class="col-12">
        <alert class="alert-position"></alert>
      </div>
    </div>
    <app-header title="Transaction Details" [showBackButton]="true" [showMenu]="false"></app-header>
    <div class="row m-0 mt-1" id="tran-sce-info">
      <div class="col-12 mt-0 txnSec-wrpr h-100">
        <div class="h-100">
          <div class="txnSec-wrpr-content h-100">
            <div class="txnSec h-100" *ngIf="!isLoading">
              <div *ngFor="let txn of txnsList;let last = last;" [ngClass]="{'last-item':last}">
                <div class="txnSec-bdr d-flex flex-wrap" (click)="getDisplayDetail(txn)">
                  <div class="txnSec-inner w-100">

                    <div class="txnSec-img">
                      <img *ngIf="txn.entry_type == 'DEBIT'" src="../../assets/img/DEBIT.svg">
                      <img *ngIf="txn.entry_type == 'CREDIT'" src="../../assets/img/CREDIT.svg">
                    </div>

                    <!-- INFO 1 -->
                    <div class="d-flex justify-content-between align-items-center" style="margin: 4px 0 8px;">
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'UPI_PPI_INWARD'">Money Received</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'UPI_PPI_OUTWARD'">{{txn.transaction_tag.Payee_Name ? txn.transaction_tag.Payee_Name : "UPI Spend"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'BBPS'">{{txn.transaction_tag.biller_name ? txn.transaction_tag.biller_name : "Bill Payment"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'COBANK_UPI_LOAD'">Wallet Top Up</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'RECHARGE'">{{txn.transaction_tag.operator ? txn.transaction_tag.operator + ' ' + txn.transaction_tag.mobile_number.slice(-4) : "Mobile Recharge"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'MERCHANT'">{{txn.transaction_tag.Merchant_Name ? txn.transaction_tag.Merchant_Name : "Merchant Spend"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'E_COLLECT'">Wallet Top Up</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'FastagRetail_Load'">Fastag recharge</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'SendMoney'">Wallet Transfer</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'Bank Transfer'">Bank Transfer</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'FEERATE'">Bank Transfer Fee</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'ECOMMERCE'">{{txn.transaction_tag.Merchant_Name ? txn.transaction_tag.Merchant_Name : "Merchant Spend"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'RequestMoney'">Money Received</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'PAYU'">Wallet Top Up</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'UPI'">Wallet Top Up</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'CC'">Wallet Top Up</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'CBS'">Wallet Top Up</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'CASHBACK'">Cashback Received</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'DEAL'">{{txn.transaction_tag.Merchant_Name ? txn.transaction_tag.Merchant_Name : "Merchant Spend"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'Dutch'">Money Received</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'Non App Transfer'">{{txn.entry_type === 'CREDIT' ? "Money Received" : "Money Spent"}}</div>
                      <div class="tran-ttl" *ngIf="txn.transaction_type === 'TEMP'">{{txn.entry_type === 'CREDIT' ? "Money Received" : "Money Spent"}}</div>
                      <div class="tran-ttl" *ngIf="!txnTypes.includes(txn.transaction_type) && txn.entry_type === 'CREDIT'">Money Received</div>
                      <div class="tran-ttl" *ngIf="!txnTypes.includes(txn.transaction_type) && txn.entry_type === 'DEBIT'">Money Spent</div>
                      <div class="tran-ttl" [ngClass]="{'active': txn.entry_type == 'CREDIT'}">{{(txn.entry_type == 'CREDIT' ? '+' : '-')+ ' ₹ '}}{{txn.amount | number}}</div>
                    </div>

                    <!-- INFO 2 -->
                    <div class="d-flex justify-content-between">
                      <div class="tran-dt">
                        <ng-container *ngIf="txn.displayDetail">
                          <div *ngIf="txn.transaction_type === 'UPI_PPI_INWARD'">UPI</div>
                          <div *ngIf="txn.transaction_type === 'UPI_PPI_OUTWARD'">{{txn.transaction_tag.Payee_VPA}}</div>
                          <div *ngIf="txn.transaction_type === 'BBPS'">{{txn.transaction_tag.biller_category}}</div>
                          <div *ngIf="txn.transaction_type === 'COBANK_UPI_LOAD'">{{txn?.transaction_tag?.Payer_Name}}{{(txn?.transaction_tag?.Payer_Name && txn?.transaction_tag?.Payer_VPA) ? ' | ' + txn?.transaction_tag?.Payer_VPA : (txn?.transaction_tag?.Payer_VPA ? txn?.transaction_tag?.Payer_VPA : '') }}</div>
                          <div *ngIf="txn.transaction_type === 'RECHARGE'">{{txn.transaction_tag.operator ? "Mobile Recharge" : ''}}</div>
                          <div *ngIf="txn.transaction_type === 'MERCHANT'">{{txn.transaction_tag.Txn_Type}}</div>
                          <div *ngIf="txn.transaction_type === 'E_COLLECT'">E-Collect</div>
                          <div *ngIf="txn.transaction_type === 'FastagRetail_Load'"></div>
                          <div *ngIf="txn.transaction_type === 'SendMoney'"></div>
                          <div *ngIf="txn.transaction_type === 'Bank Transfer'"></div>
                          <div *ngIf="txn.transaction_type === 'FEERATE'"></div>
                          <div *ngIf="txn.transaction_type === 'ECOMMERCE'">ECOM</div>
                          <div *ngIf="txn.transaction_type === 'RequestMoney'">UPI Collect</div>
                          <div *ngIf="txn.transaction_type === 'PAYU'">PayU</div>
                          <div *ngIf="txn.transaction_type === 'UPI'">UPI</div>
                          <div *ngIf="txn.transaction_type === 'CC'">Credit Card</div>
                          <div *ngIf="txn.transaction_type === 'CBS'">YES BANK</div>
                          <div *ngIf="txn.transaction_type === 'CASHBACK'"></div>
                          <div *ngIf="txn.transaction_type === 'DEAL'">ECOM</div>
                          <div *ngIf="txn.transaction_type === 'Dutch'">Collect</div>
                          <div *ngIf="txn.transaction_type === 'Non App Transfer'"></div>
                          <div *ngIf="txn.transaction_type === 'TEMP'"></div>
                        </ng-container>
                        <div *ngIf="txn?.wallet_balance" class="wlt-blnc" [ngStyle]="{'padding-top':
                                txn.displayDetail
                            && ((txnTypesForWalletBalance.includes(txn?.transaction_type?.trim()))
                            || (txn?.transaction_type === 'UPI_PPI_OUTWARD' && txn?.transaction_tag?.Payee_VPA)
                            || (txn?.transaction_type === 'BBPS' && txn?.transaction_tag?.biller_category)
                            || (txn?.transaction_type === 'RECHARGE' && txn?.transaction_tag?.operator)
                            || (txn?.transaction_type === 'MERCHANT' && txn?.transaction_tag?.Txn_Type)
                            || (txn?.transaction_type === 'COBANK_UPI_LOAD' && (txn?.transaction_tag?.Payer_Name || txn?.transaction_tag?.Payer_VPA)))
                            ? '8px' : '0px'}">
                          <img src="../../assets/img/wallet-ruppe.svg">
                          <span>: {{(txn?.wallet_balance) ? 'Rs. ' + txn?.wallet_balance : ''}}</span>
                        </div>
                      </div>
                      <div class="tran-dt">{{moment(txn.transaction_date).format('DD/MM/YYYY • h:mm A')}}</div>
                    </div>
                    <div class="arrow-icon">
                      <span class="arrow-down" *ngIf="!txn.displayDetail"></span>
                      <span class="arrow-up" *ngIf="txn.displayDetail"></span>
                    </div>
                  </div>
                  <div class="trx-dtls"  *ngIf="txn.displayDetail">
                    <div class="trx-dt">Txn ID: {{txn.merchant_reference_number}}
                    </div>
                  </div>
                </div>
                <div class="pagination-loader" *ngIf="last">
                </div>
              </div>
              <div class="txnSec-bdr" *ngIf="!isLoading && txnsList.length === 0">
                <div class="txnSec-inner-notext">
                  <span>No transactions present!</span>
                </div>
              </div>
            </div>
            <div class="spinner-container" *ngIf="isLoading">
              <span class="spinner-border spinner-large loadingSplinner" role="status">
                <span class="sr-only"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
