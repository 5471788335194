import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    if (value) {
      let clearTextLength = 0;
      let maskChar = '*';
      let maskCharLength = 4;

      if (args.length > 0)
        clearTextLength = args[0]

      if (args.length > 1)
        maskChar = args[1]

      if (args.length > 2)
        maskCharLength = args[2]

      if (clearTextLength > value.length)
        clearTextLength = 0;

      const visibleChars = value.substring(value.length - clearTextLength);
      const maskedChars = maskChar.repeat(maskCharLength);

      return maskedChars + visibleChars;
    }

  }

}
