<div class="main-panel">
  <div>
    <div class="col-12">
      <alert class="alert-position"></alert>
    </div>
    <app-header title="Manage Preferences" [showMenu]="false" [showBackButton]="true" (onBack)="goBack()"></app-header>
    <div class="setting-wrpr">
      <div class="login-wrpr">

        <ng-container *ngIf="!isPageLoading">
          <div *ngIf="(isYESPAY) || (!isYESPAY && !txnFlags['BL'])" class="setting-title">Wallet</div>

          <div *ngIf="(isYESPAY) || (!isYESPAY && !txnFlags['BL'])">
            <div [ngClass]="{'disable-section': txnFlags['L'] || isSavePrefLoader}">
              <div class="toggle-checkbox d-flex align-items-center justify-content-between">
                <div class="label-settings">Enable E-com Transactions</div>
                <input type="checkbox" [(ngModel)]="txnFlags['ECOM']" class="d-none" id="ecomTransactions">
                <label (click)="confirmationModal('ECOM')"></label>
              </div>

              <div class="align-items-center justify-content-between" [ngClass]="{'d-none':!txnFlags['ECOM'], 'd-flex':txnFlags['ECOM']}">
                <span class="label-settings">Per E-com Transaction Limit</span>
                <span class="range-amo"><input type="tel" maxlength="5" (input)="limitChange('ECOM')" numbersOnly [(ngModel)]="txnLimits['ECOM']" class="setting-inp"></span>
              </div>

              <div class="setting-slider" [ngClass]="{'d-none':!txnFlags['ECOM']}">
                <mat-slider min="0" max="10000" [(ngModel)]="txnLimits['ECOM']"></mat-slider>
              </div>

              <div class="label-range align-items-center justify-content-between c-pointer" [ngClass]="{'d-none':!txnFlags['ECOM'], 'd-flex':txnFlags['ECOM']}">
                <span *ngFor="let step of steps" (click)="txnLimits['ECOM']=(step*2)*1000">
                  <a>{{((10/5)*step) | number}}K</a>
                </span>
              </div>
            </div>
          </div>

          <div *ngIf="isYESPAY" class="toggle-checkbox d-flex align-items-center justify-content-between">
            <div class="label-settings d-flex align-items-center">
              Lock Wallet
              <span class="info-tooltip-icon" (click)="infoModal('LW')">
                <img src="../../assets/img/question-mark.svg" class="q-mrk">
              </span>
            </div>
            <input type="checkbox" [(ngModel)]="txnFlags['L']" class="d-none" id="lockWallet">
            <label (click)="confirmationModal('L')"> </label>
          </div>

          <!-- Uncomment to check SET PIN functionality, else keep the code commented -->
          <!-- <div class="replace-keychain toggle-checkbox d-flex align-items-center justify-content-between"
            (click)="gotoSetPinPage()">
            Set/Reset Keychain PIN
            <span class="spinner-border loadingSplinner" *ngIf="isSetPinLoading" role="status">
              <span class="sr-only"></span>
            </span>
            <img style="float: right" src="../../assets/img/play.svg" alt="play.svg">
          </div> -->

          <div *ngIf="(isYESPAY && userData?.isKeyChainUser) || (!isYESPAY)">

            <!-- Replace Keychain / Replace Card -->
            <div *ngIf="(isYESPAY && userData?.isKeyChainUser) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL')">
              <div class="setting-title setting-title-1">{{menu?.menuIdentifier === 'YESPAY' ? 'Keychain': 'Card'}}</div>
              <div class="toggle-checkbox d-flex align-items-center justify-content-between" *ngIf="txnFlags['BL'] && physicalCard && physicalCard?.kitNumber">
                <div class="label-settings">
                  Your {{isYESPAY ? "keychain" : "card"}} with Kit
                  No.{{physicalCard?.kitNumber|mask:'4':'•':'4'}} is permanently
                  blocked. Replace it with a new one.
                </div>
              </div>
              <div class="replace-keychain toggle-checkbox" *ngIf="txnFlags['BL'] && physicalCard && physicalCard?.kitNumber" (click)="toggleKeychainModal()">
                Replace {{isYESPAY ? 'Keychain': 'Card'}}
              </div>
            </div>

            <div *ngIf="!txnFlags['BL'] && ((isYESPAY && userData?.isKeyChainUser) || (!isYESPAY))">

              <div [ngClass]="{'disable-section':txnFlags['UL'] || isSavePrefLoader}">
                <div class="toggle-checkbox d-flex align-items-center justify-content-between" *ngIf="(isYESPAY && userData?.isKeyChainUser) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL')">
                  <div class="label-settings">Enable POS transactions</div>
                  <input type="checkbox" [(ngModel)]="txnFlags['POS']" class="d-none" id="enablePos">
                  <label (click)="confirmationModal('POS')"> </label>
                </div>

                <!-- Per POS Transaction Limit -->
                <div class="d-flex align-items-center justify-content-between" [ngClass]="{'d-none':!txnFlags['POS'], 'd-flex':txnFlags['POS']}" *ngIf="(isYESPAY && userData?.isKeyChainUser) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL')">
                  <span class="label-settings">Per POS Transaction Limit</span>
                  <span class="range-amo"><input type="tel" (input)="limitChange('POS')" min="0" max="10000" numbersOnly
                      maxlength="5" [(ngModel)]="txnLimits['POS']" class="setting-inp"></span>
                </div>

                <div class="setting-slider" [ngClass]="{'d-none':!txnFlags['POS']}" *ngIf="(isYESPAY && userData?.isKeyChainUser) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL')">
                  <mat-slider min="0" max="10000" [(ngModel)]="txnLimits['POS']"></mat-slider>
                </div>

                <div class="label-range align-items-center justify-content-between c-pointer" [ngClass]="{'d-none':!txnFlags['POS'], 'd-flex':txnFlags['POS']}" [hidden]="(isYESPAY && !giftCard?.isKeyChainUser) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL')">
                  <span *ngFor="let step of steps" (click)="txnLimits['POS']=(step*2)*1000"><a>{{((10/5)*step) | number}}K</a></span>
                </div>

                <!-- Enable contactless transactions -->
                <div *ngIf="(isYESPAY && userData?.isKeyChainUser)" class="toggle-checkbox d-flex align-items-center justify-content-between">
                  <div class="label-settings">Enable contactless transactions</div>
                  <input type="checkbox" [(ngModel)]="txnFlags['CL']" class="d-none" id="enableContactless">
                  <label (click)="confirmationModal('CL')"> </label>
                </div>
              </div>

              <!-- Lock Keychain / Lock Card -->
              <div [ngClass]="{'disable-section': isSavePrefLoader}" class="toggle-checkbox d-flex align-items-center justify-content-between" *ngIf="(isYESPAY && giftCard?.isKeyChainUser) || (!isYESPAY)">
                <div class="label-settings d-flex align-items-center">
                  Lock {{isYESPAY ? 'Keychain': 'Card'}}
                  <span class="info-tooltip-icon" (click)="infoModal('LK')">
                    <img src="../../assets/img/question-mark.svg" class="q-mrk">
                  </span>
                </div>
                <input type="checkbox" [(ngModel)]="txnFlags['UL']" class="d-none" id="lockKeychain">
                <label (click)="confirmationModal('UL')"> </label>
              </div>

              <!-- Block Keychain / Block Card -->
              <div [ngClass]="{'disable-section': isSavePrefLoader}" class="toggle-checkbox d-flex align-items-center justify-content-between" *ngIf="(isYESPAY && giftCard?.isKeyChainUser) || (!isYESPAY && (menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && cardSuccessStatus.includes(physicalCard?.cardStatus?.toLowerCase())))">
                <div class="label-settings d-flex align-items-center">
                  Block {{menu?.menuIdentifier === 'YESPAY' ? 'Keychain': 'Card'}}
                  <span class="info-tooltip-icon" (click)="infoModal('BK')">
                    <img src="../../assets/img/question-mark.svg" class="q-mrk">
                  </span>
                </div>
                <input type="checkbox" [(ngModel)]="txnFlags['BL']" class="d-none" id="blockKeychain">
                <label (click)="confirmationModal('BL')"> </label>
              </div>

              <!-- SET PIN -->
              <div class="replace-keychain toggle-checkbox d-flex align-items-center justify-content-between" [ngClass]="{'disable-section': isSavePrefLoader}" *ngIf="((isYESPAY && giftCard?.isKeyChainUser) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'))" (click)="gotoSetPinPage()">
                Set/Reset {{isYESPAY ? 'Keychain': ''}} PIN
                <span class="spinner-border loadingSplinner" *ngIf="isSetPinLoading" role="status">
                  <span class="sr-only"></span>
                </span>
                <img class="set-pin-arrow" src="../../assets/img/play.svg" alt="play.svg">
              </div>

            </div>
          </div>
        </ng-container>

        <div class="spinner-container" style="max-height:341px;margin-top:0" *ngIf="isPageLoading">
          <span class="spinner-border spinner-large loadingSplinner" role="status">
            <span class="sr-only"></span>
          </span>
        </div>
      </div>

      <div *ngIf="(isYESPAY) || (!isYESPAY && !txnFlags['BL']) || !displayOtpScreen" class="add-funds-btm-btn" (click)="savePreferences('getOTP')" [ngClass]="{'disable-section': isSavePrefLoader || ((!txnLimits?.ECOM && !txnLimits?.POS ) || (!txnLimits?.POS && txnLimits?.ECOM && responseECOM === txnLimits?.ECOM) || (!txnLimits?.ECOM && txnLimits?.POS && responsePOS === txnLimits?.POS) || (txnLimits?.ECOM && txnLimits?.POS && responsePOS === txnLimits?.POS && responseECOM === txnLimits?.ECOM))}">
        Save Preferences&nbsp;
        <span class="spinner-border loadingSplinner" *ngIf="isSavePrefLoader" role="status">
          <span class="sr-only">
          </span>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="prf-mdl-wrpr" [hidden]="!displayOtpScreen">
  <div class="prf-mdl-bg"></div>
  <div class="prf-mdl-body">
    <div class="prf-mdl-ttl">
      <span>OTP Verification</span>
      <!-- <span style="float: right; cursor: pointer;" (click)="hideOtpScreen()">x</span> -->
    </div>
    <form [formGroup]="otpForm">
      <div class="prf-mdl-inpt-wrpr">
        <input type="text" placeholder="000000" [attr.disabled]="(isSavePrefLoader || isVerifyOtpModifyLimitApiLoading) ? true : null" class="prf-mdl-inpt" [ngClass]="{'prf-mdl-inpt-error': otpForm.get('otp').touched && otpForm.get('otp').invalid}" id="otp" formControlName="otp" maxlength="6" #ngOtpInput (input)="onOtpChange($event.target)">

        <div class="prf-mdl-rsnd" (click)="savePreferences('resendOTP')" *ngIf="counter == 0 && !isSavePrefLoader">Resend</div>
        <div class="prf-mdl-rsnd" *ngIf="counter == 0 && isSavePrefLoader">
          <span class="spinner-border loadingSplinner spinner-size"role="status">
            <span class="sr-only"></span>
          </span>
        </div>

        <div class="prf-mdl-rsnd-timer" *ngIf="counter && counter!=0">Resend OTP in <span style="font-family: 'Roboto' !important">{{counter}} sec</span></div>
        <div class="prf-otp-error" *ngIf="otpForm.get('otp').touched && otpForm.get('otp').invalid">
          <span *ngIf="otpForm.get('otp').errors.max || otpForm.get('otp').errors.pattern">Please enter a valid OTP</span>
          <span *ngIf="otpForm.get('otp').errors.required">This field is required</span>
        </div>
        <div class="prf-otp-error" *ngIf="!otpForm.get('otp').invalid && invalidOtp">
          <span>Please enter a valid OTP.</span>
        </div>
      </div>
      <div class="prf-mdl-btn" [ngStyle]="{opacity: isSavePrefLoader || otpForm.get('otp').invalid ? '0.6' : '1'}" (click)="verifyOtpModifyLimit()">Proceed &nbsp;
        <span class="spinner-border loadingSplinner" *ngIf="isVerifyOtpModifyLimitApiLoading" role="status">
          <span class="sr-only"></span>
        </span>
      </div>
    </form>
  </div>
</div>

<div class="alert-modal d-none" [ngClass]="{'d-block':linkKeychain}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">
    <div class="alert-modal-head d-flex align-items-center justify-content-between">
      Replace {{menu?.menuIdentifier === 'YESPAY' ? 'Keychain': 'Card'}}
      <span (click)="toggleKeychainModal()"></span>
    </div>
    <div class="alert-modal-body" style="padding: 0px !important;">
      <div class="login-wrpr" [ngClass]="{'v-hidden':isLoading}" style="padding: 15px 20px 0px !important;">
        <form [formGroup]="keychainForm">
          <mat-form-field class="form-group example-full-width mb-3">
            <mat-label>New Kit No.</mat-label>
            <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" numbersOnly class="form-control" placeholder="" name="kitNo" formControlName="kitNo" autocomplete="off">
            <mat-error *ngIf="keychainForm?.get('kitNo').errors && keychainForm?.get('kitNo').errors.required"> New Kit Number is Required </mat-error>
          </mat-form-field>
          <mat-form-field class="form-group example-full-width mb-3 mt-2">
            <mat-label>New Validation No.</mat-label>
            <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" minlength="4" maxlength="4" numbersOnly class="form-control" placeholder="Please enter last 4 digits of the card no." name="validationNo" formControlName="validationNo" autocomplete="off">
            <mat-error *ngIf="keychainForm?.get('validationNo').errors && (keychainForm?.get('validationNo').errors.required || keychainForm?.get('validationNo').errors.pattern)">
              Validation number to be 4 numeric digits only
            </mat-error>
          </mat-form-field>
          <div *ngIf="errorMessage" class="alert-error-msg-popup"> {{errorMessage}} </div>
        </form>
      </div>
      <div class="spinner-container" *ngIf="isLoading">
        <span class="spinner-border spinner-large loadingSplinner" role="status">
          <span class="sr-only"></span>
        </span>
      </div>
    </div>
    <div class="alert-modal-footer d-flex align-items-center justify-content-between" [ngClass]="{'v-hidden':isLoading}">
      <div class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center" (click)="toggleKeychainModal()">Cancel</div>
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center" [ngClass]="{'disabled': keychainForm?.invalid}" (click)="linkKeyChain()">Proceed</div>
    </div>
  </div>
</div>

<div class="alert-modal d-none" [ngClass]="{'d-block': messageModal}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">

    <div class="alert-modal-head d-flex align-items-center justify-content-between" style="font-size: 18px;">
      {{alertTitle}}
      <span (click)="toggleMessageModal(false, '', '')"></span>
    </div>

    <div *ngIf="alertMessage" class="alert-modal-body">
      {{alertMessage}}
    </div>

    <!-- <div class="alert-modal-footer d-flex align-items-center justify-content-between"
      (click)="toggleMessageModal(false, '', '')">
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
        style="width: 100% !important;">Okay</div>
    </div> -->
  </div>
</div>

<jw-modal id="confirm-modal" title="{{title}}" [showButtons]="confirmationModalButtonFlag"
  (onClick)="enableDisable($event)">{{msg}}
</jw-modal>
