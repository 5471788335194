import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { PreventOrientation } from 'prevent-orientation';
import { style } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  logIn = false;

  constructor(
    private router: Router,
    private apiService: ApiService
  ) { };

  ngOnInit() {

    new PreventOrientation({
      text: 'Sorry, this orientation of device is not supported',
      background: 'url(assets/img/bg5.png)',
      fontSize: '1.4rem'
    }).preventLandscape();

    this.apiService.setBaseURL();
    const userObj = JSON.parse(localStorage.getItem("userObj"));
    const inactivityPeriod = JSON.parse(localStorage.getItem("inactivityPeriod"));
    let pathName = window.location.pathname;

    if (userObj && userObj.loggedIn) {
      if (inactivityPeriod) {
        if (pathName === '/') { this.router.navigate(['/menu']); }
        else if (pathName.includes('/login')) { this.router.navigate(['/menu']); }
        else { this.router.navigate([pathName]); }
      } else {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    } else {
      this.router.navigate(['/login']);
    }

  }
}
