/*
 * @Author: Suraj Deshmukh
 * @Date: 2022-10-06 10:28:48 
 * @Last Modified by: Suraj Deshmukh
 * @Last Modified time: 2022-10-06 12:45:56
 */

import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[date-slash]'
})
export class DateSlashDirective {
    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        const input = event.target as HTMLInputElement;

        const ddformat = /^[\d]{2}$/;
        const mmformat = /^[\d]{2}\/[\d]{2}$/;
        input.value = input.value.replace(/^\/+$/g, '');
        if (mmformat.test(input.value) || ddformat.test(input.value)) input.value += '/';

        // const input = event.target as HTMLInputElement;
        // input.value = input.value.replace(/[^0-9]*/g, '');
        // let trimmed = input.value.replace(/\/+/g, '');
        // if (trimmed.length > 10) {
        //     trimmed = trimmed.substr(0, 10);
        // }
        // let numbers = [];
        // for (let i = 0; i < trimmed.length; i == 4 ? i += 4 : i += 2) {
        //     numbers.push(trimmed.substr(i, i == 4 ? 4 : 2));
        // }
        // input.value = numbers.join('/');

    }
}