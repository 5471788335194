<div class="row m-0">
  <div class="col-12" style="margin-bottom: 34px;">
    <div class="topSec">
      <alert class="alert-position"></alert>
      <div>
        <div>
          <img class="logo" src="../../assets/img/logo/logo-blue.svg" alt="Logo">
          <div (click)="logout()" class="card-btn">Logout</div>
        </div>
        <div>
          <div class="wl-txt-dashboard">Welcome to E-Wallet</div>
          <div class="wl-t-ybp-dashboard">portal !</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="hm-tb-wrpr">
  <div class="col-12" style="display: contents;">
    <div class="img-container">

      <div class="spinner-container" *ngIf="isLoading">
        <span class="spinner-border spinner-large loadingSplinner" role="status">
          <span class="sr-only"></span>
        </span>
      </div>
      <ng-container *ngIf="menuList?.length > 0 && !isLoading">
        <ng-container *ngFor="let menu of menuList; let x = index">

          <!-- <div class="cardBg ml-auto mr-auto text-center mb-4 pointer"
            [style.background-image]="'url(' + menu.bannerImageLink + ')'" (click)="navigateToCardDetails(menu)">
            <div class="banner-title-text" title={{menu.menuTitle}} [ngStyle]="{'color': menu.bannerTextColorCode}">{{menu.menuTitle}}</div>
            <div class="banner-desc-text" title={{menu.menuDescription}} [ngStyle]="{'color': menu.bannerTextColorCode}">{{menu.menuDescription}}</div>
          </div> -->

          <div class="cardBg ml-auto mr-auto text-center mb-4 pointer" [ngStyle]="{'background-color': '#dbebf7'}">
            <div class="txnSec-spinner" *ngIf="menu?.show!=true && menu?.error!=true">
              <div class="txnSec-inner pl-0"><span class="spinner-border" role="status"><span class="sr-only">Loading...</span></span></div>
            </div>
            <div *ngIf="menu?.show!=true && menu?.error==true">
              <div class="txnSec-inner pl-0" style="margin-right: 21px;"><span>UNABLE TO LOAD IMAGE</span></div>
            </div>
            <div (click)="navigateToCardDetails(menu)">
              <img src="{{menu?.bannerImageLink}}" [ngClass]="{'card-image-visible' : menu?.show}" class="card-image" (load)="menu.show = true" (error)="menu.error = true">
              <div *ngIf="menu?.show" class="banner-title-text" title={{menu?.menuTitle}} [ngStyle]="{'color': menu?.bannerTextColorCode}">{{menu?.menuTitle}}</div>
              <div *ngIf="menu?.show" class="banner-desc-text" title={{menu?.menuDescription}} [ngStyle]="{'color': menu?.bannerTextColorCode}">{{menu?.menuDescription}}</div>
            </div>
          </div>

        </ng-container>
      </ng-container>
      <ng-container *ngIf="menuList?.length === 0 && !isLoading">
        <div class="banner-list-empty">
          No banners to display
        </div>
      </ng-container>

    </div>
  </div>
</div>
