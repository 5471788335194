<div class="loader-wrpr">
    <div class="loader-backdrop"></div>
    <div class="loader-wrpr-content d-flex align-items-center justify-content-center">
        <div>
            <div class="stage-dot-flashing">
                <div class="dot-flashing"></div>
            </div>
            <div class="loader-wrpr-text text-center">{{loaderText ? loaderText : "Please Wait.."}}</div>
        </div>
    </div>
</div>
