import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  public cardType = new BehaviorSubject<Object>(null);

  public cardDetails: any;
  public giftCardsImageUrl: any;

  kycDetails: any;
  userDetailsObj: any;

  constructor() { }
  setCardType(val: Object) {
    this.cardType.next(val);
  }

  getCardType() {
    return this.cardType.asObservable();
  }

  getpublicKey() {
    return environment.keys.publicKey;
  }

  setKycDetails(obj: any, userDetailsObj: any) {
    this.kycDetails = obj;
    this.userDetailsObj = userDetailsObj;
  }

  getKycDetails() {
    const obj: any = { kycDetails: this.kycDetails, userDetailsObj: this.userDetailsObj }
    return obj;
  }
}
