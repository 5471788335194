import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_alert';
import { Globals } from '../global.constants';
import { AdaptersService } from 'app/adapters.service';

@Component({
  selector: 'app-set-pin',
  templateUrl: './set-pin.component.html',
  styleUrls: ['./set-pin.component.scss']
})
export class SetPinComponent implements OnInit {

  menu: any;
  params: any;
  isYESPAY: any;
  userData: any;
  physicalCard: any;

  txnCall: boolean = false;
  loadingData: boolean = false;

  dataObj: any;
  iframeUrl: any;

  menuList: any = [];

  constructor(
    protected activatedRoute: ActivatedRoute,
    private adaptersService: AdaptersService,
    private domSanitizer: DomSanitizer,
    public alertService: AlertService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.menu = Globals.getMenu();

    this.activatedRoute.params.subscribe(params => {
      this.params = params;
    });

    // If menu object is empty or on page refresh
    if (!this.menu) {
      if (this.params.id) {
        this.loadingData = true;
        this.getMenu(this.params.id);
      }
    }

    // Navigated from menu page
    else {
      this.setIsYesPayFlag();
    }

  }

  setIsYesPayFlag() {
    if (this.menu.menuIdentifier === 'YESPAY') this.isYESPAY = true; else this.isYESPAY = false;
    if (this.isYESPAY) this.initializeData(); else this.fetchUserCardDetails();
  }

  initializeData() {
    this.loadingData = true;
    if ((!this.isYESPAY && this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL')) {
      this.goToSettings();
    } else {
      this.activatedRoute.queryParams.subscribe(data => {
        if (data) {
          this.dataObj = JSON.parse(String(data.responseString));
          this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.dataObj.url);
        }
        if (this.isYESPAY) {
          if (this.dataObj.cardNumber) { this.dataObj.cardNumber = this.trimAndKeepLastFour(this.dataObj.cardNumber.replace(/X/g, "")); }
          this.loadingData = false;
        } else {
          this.physicalCard = Globals.getPCard();
          this.loadingData = false;
        }
      });
    }
  }

  goToCardDetails() {
    this.router.navigate([`/card-detail`, this.menu.id]);
  }

  goToSettings() {
    this.router.navigate([`/settings`, this.menu.id]);
  }

  goToHome() {
    this.router.navigate(['/menu']);
  }

  getMenu(id: any) {
    this.loadingData = true;
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus;
        const isObjectPresent = this.menuList.filter((o: any) => o.id == id);
        if (!isObjectPresent.length) this.goToHome();
        else this.setMenu(this.menuList, id);
      } else {
        this.menuList = [];
      }
      this.loadingData = false;
    },
      (err) => {
        this.goToHome();
        this.menuList = [];
        this.loadingData = true;
      });
  }

  setMenu(res: any, id: any) {
    if (res && res.length > 0) {
      for (let element of res) {
        if (element.id == id) {
          this.menu = element;
          Globals.setMenu(this.menu);
          this.setIsYesPayFlag();
          break;
        }
      }
    }
  }

  fetchUserCardDetails() {
    this.loadingData = true;
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier
    }
    this.adaptersService.fetchUserCardDetails(data).then((res: any) => {
      this.handleUserCardDetailsResponse(res);
    },
      (err) => {
        this.goToHome();
        this.loadingData = false;
      }
    );
  }

  handleUserCardDetailsResponse(res: any) {
    if (res) {
      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
      } else {

        Globals.setUserCardDetails(res);
        Globals.saveVCard(res.virtualCardResponse);
        Globals.savePCard(res.physicalCardResponse);

        if ((this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL' && res.physicalCardResponse)) {
          this.initializeData();
        }
      }
    } else {
      this.goToHome();
    }
    this.loadingData = false;
  }

  // Function to trim all characters and keep the last 4
  trimAndKeepLastFour(str: string): string {
    str = str ? str.trim() : '';
    return str.substring(Math.max(str.length - 4, 0)); // Extracts the last 4 characters
  }

}
