import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-set-pin-dialog',
  templateUrl: './set-pin-dialog.component.html',
})
export class SetPinDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  editForm: UntypedFormGroup;
  isSaving=false;
  confirmationErrorText = '';
  btnDissable = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder : UntypedFormBuilder,
  ) { }

  initializeForm(){
    this.editForm = this.formBuilder.group({
      newPin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]],
      confirmPin: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
    })
  }

  ngOnInit() {
    this.initializeForm();
  }

  confirmPinChange(event){
    this.confirmationErrorText = ''
    if(this.editForm.get('newPin').value!=event) {
      this.btnDissable=true;
      if(event)this.confirmationErrorText = "New PIN and Confirm PIN must match.";
    }
    else this.btnDissable=false;
  }

  public decline() {
    this.activeModal.close(false);
  }

  public submit() {
    if(this.editForm.invalid) {
      return;
    }else{
      this.isSaving = true;
      if(this.editForm.value.newPin!=this.editForm.value.confirmPin){
        this.confirmationErrorText = "New PIN and Confirm PIN must match.";
        this.isSaving = false;
        return
      }
      const reqObj = {
        "newPin": this.editForm.value.newPin,
        "confirmPin": this.editForm.value.confirmPin
      };
      this.activeModal.close(reqObj);
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
