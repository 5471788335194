<div class="main-panel">
	<div>
		<div class="col-12">
			<alert class="alert-position"></alert>
		</div>
		<app-header title="Secure Usage Guidelines" [showMenu]="false" [showBackButton]="true" (onBack)="goBack()">
		</app-header>
		<div class="setting-wrpr terms-wrpr">
			<div class="terms-wrpr-inner">
				<ol type="1" style="padding-left: 30px;">
					<li>Customers using the YES PAY Application (the <b>“User”</b>) is advised not to share information
						like customer ID, Aadhaar Number, PAN, date of birth, debit/credit card number, CVV,
						password/passcode, one-time passwords, secret question & answers, transactional alerts etc. (the
						<b>“Sensitive Information”</b>) over a phone call, chat, or any other medium, with any third
						party as the same may facilitate unauthorized access into their YES PAY Application (the
						<b>“Application”</b>). These details would enable fraudsters to conduct unauthorized
						transactions on your account, leading to financial loss.
					</li>
					<li>User is advised not to install any application from any unknown/untrusted sources, since the
						same may grant remote access for the fraudster to their electronic device (<b>“Device”</b>)
						through which they are accessing the Application and to facilitate fraudulent transaction(s)
						through the Application. Applications shall be downloaded only from Google Play Store or the
						Apple Store, as the case may be. We advise the users not to enable the option to ‘install apps
						from untrusted sources’ in your Device settings, as this would make your device prone to
						intrusion.</li>
					<li>The Bank officials will never request/insist to reveal your login credentials or Sensitive
						Information or any other related information pertaining to your account/Application. User is
						advised not to disclose such information to any third person, even if they claim to be
						Authorised by the Bank.</li>
					<li>Always verify app permissions and grant only those permissions which are extremely necessary for
						operating the device/application.</li>
					<li>User is advised to logout from the Application or force close the Application (i.e. <i>close and
							remove it from running in the background</i>) every time after using the Application.</li>
					<li>User is expected to protect their respective Device from intrusion and unauthorized access and
						to be vigilant against the fraudulent activities like SIM swapping, hacking etc.</li>
					<li>In the event of the Device being stolen/lost/compromised, User shall immediately block the
						Application, by contacting Bank’s customer care representative on 1800 1200 or by sending SMS
						&lt;BLKYP&gt; to 9840909000.</li>
					<li>User is advised to:
						<ol type="a" style="margin-left: -24px;">
							<li style="margin-top:10px;top: 6px;">use combination of letters, numbers and special
								characters for creating password.</li>
							<li>use a strong password that is not easily predictable.</li>
							<li>change the password on a regular interval (preferably once a month).</li>
						</ol>
					</li>
					<li>
						User is advised to set personalized secret questions & answers, in addition to the password.
					</li>
					<li>User shall never share their Device password, Application password, One-Time Passwords (<b>“OTPs”</b>), secret questions and answers etc. with anybody else, even with family members or friends.</li>
					<li>User is advised not to send/share any Sensitive Information through text messages as Unencrypted text messages are prone to hacking/fraud.</li>
					<li>Users are advised not to save biometric information (<i>fingerprints, retina, face imprint etc.</i>) of any third person on the Device, since it may lead to unauthorized access to the Application.</li>
					<li>User is advised to update the Application on a periodic basis, and as and when prompted, since there may be inbuilt security control/patches/updates that are released in the newer version of Application.</li>
					<li>User should ensure that the Device is free of virus/malware before installing and while using the Application.</li>
					<li>User is advised to avoid using unsecured, unknown Wi-Fi networks. There may be rogue Wi-Fi access points in public places used for distributing malicious applications.</li>
					<li>User is advised to copy and/or use the virtual debit card number only when it is required and shall erase the data immediately after completing the transaction.</li>
					<li>User is advised to be aware that in case of unsuccessful login attempts beyond a certain limit, the access to the Application will get blocked.</li>
					<li>User is advised to cross check/verify the transaction details, including account number, bank details, and details of recipient, before initiating any money transfers.</li>
					<li>Fraudsters manipulate caller identification applications and search engines to list themselves as bank officials or other authorized persons, to gain trust and extract sensitive information for carrying out fraudulent transactions. Hence, the User should always check the Bank’s or service provider’s website for customer care numbers and/or official e-mail IDs.</li>
					<li>In case any fraudulent transaction is observed, User is advised to immediately report the details to the Bank to prevent further unauthorized transactions and also to police authorities.</li>
				</ol>
			</div>
		</div>
	</div>
</div>