import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from './_alert';
import { Globals } from './global.constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  config: any;

  public options = {
    autoClose: true,
    keepAfterRouteChange: true
  }

  private localUserObj: any = JSON.parse(localStorage.getItem("userObj"));
  private userObj = new BehaviorSubject<any>({ loggedIn: false });
  public userObjObserveable = this.userObj.asObservable();

  baseUrl: string = 'https://ypp.sodelsolutions.com/services/';

  constructor(
    private http: HttpClient,
    public alertService: AlertService
  ) { };

  setBaseURL() {
    this.baseUrl = environment.urls.baseUrl;
  };

  public updateUserDetail(reqObj: any) {
    this.userObj.next(reqObj);
  };

  public commonApiConfig(data: any, method: any, path: any, showError = true, returnResp = false) {
    data = data || {};
    let apiUrl = `${this.baseUrl}` + path;
    this.localUserObj = JSON.parse(localStorage.getItem("userObj"));
    if (this.localUserObj && this.localUserObj.token) {
      this.config = new HttpHeaders().set('Authorization', 'Bearer ' + this.localUserObj.token);
    }
    return this.customApiCall(apiUrl, data, method, { headers: this.config, observe: 'response' }, showError, returnResp);
  };

  public customApiCall(apiUrl: any, data: any, httpMethod: any, headers: any, showError: any, returnResp: any) {
    let promise = new Promise((resolve, reject) => {
      let success = (response: any) => {
        const res = response.body;
        if (res && res.responseMessage && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {

          if (showError) {
            this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), this.options);
            resolve(false);
          } else if (returnResp) {
            resolve(res);
          } else {
            resolve(false);
          }
        } else if (res && res.responseMessage) {
          const headers = response.headers.headers;
          if (headers.has('authorization')) {
            const userObj = JSON.parse(localStorage.getItem("userObj"));
            userObj.token = headers.get('authorization')[0];
            this.updateUserDetail(userObj);
            localStorage.setItem('userObj', JSON.stringify(userObj));
          }
          resolve(res.responseBody);
        } else if (!res) {
          resolve('');
        } else {
          resolve(res);
        }
      };
      let failure = (error: any) => {
        if (error) {
          if (error.status === 401) {
            Globals.logout()
            window.location.reload();
          } else if (error.status === 500) {
            this.alertService.error('Internal Server Error');
          }
          else {
            if (error.error && error.error.message && error.error.message)
              this.alertService.error(Globals.getErrorMsg("", error.error.message), this.options);
            reject(error);
          }
        }
      }
      if (httpMethod.toLowerCase() === "get") {
        this.http.get(apiUrl, headers).subscribe(success, failure)
      } else if (httpMethod.toLowerCase() === "post") {
        this.http.post(apiUrl, data, headers).subscribe(success, failure)
      }
    })
    return promise;
  };

}
