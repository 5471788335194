import * as forge from 'node-forge';
import { environment } from '../environments/environment';

export class Globals {
    
    private static user: any;
    private static nonLoggedInUser: any;

    private static userDataChangeListerner: any;

    private static vCard: any;
    private static pCard: any;
    public static reFreshCards = false;
    public static updatePhyCardFlag = false;

    private static menu: any;
    private static menuList: any;
    private static userCardDetails: any;

    public static saveUser(userData: any) {
        this.user = userData;
    }
    public static getUser() {
        return this.user;
    }
    public static saveNonLoggedInUser(userData: any) {
        this.nonLoggedInUser = userData;
    }
    public static getNonLoggedInUser() {
        return this.nonLoggedInUser;
    }

    public static saveVCard(virtualCard: any) {
        this.vCard = virtualCard;
        if (this.userCardDetails) this.userCardDetails.virtualCardResponse = virtualCard;
    }
    public static getVCard() {
        return this.vCard;
    }

    public static savePCard(physicalCard: any) {
        this.pCard = physicalCard;
        if (this.userCardDetails) this.userCardDetails.physicalCardResponse = physicalCard;
    }
    public static getPCard() {
        return this.pCard;
    }

    public static setMenuList(menu: any) {
      this.menuList = menu;
    }
    public static getMenuList() {
      return this.menuList;
    }

    public static setMenu(menu: any) {
      this.menu = menu;
    }
    public static getMenu() {
      return this.menu;
    }

    public static setUserCardDetails(userCardDetails: any) {
      this.userCardDetails = userCardDetails;
    }
    public static getUserCardDetails() {
      return this.userCardDetails;
    }

    public static setValue(key: any, value: any) {
      if (key === 'isReplaceCard') {
        this.userCardDetails.isReplaceCard = value;
      }
    }

    public static setUserDataChangeListerner(callback) {
        this.userDataChangeListerner = callback;
    }
    public static removeUserDataChangeListerner() {
        this.userDataChangeListerner = null;
    }
    public static userDataChangeEvent() {
        if (typeof this.userDataChangeListerner === 'function')
            this.userDataChangeListerner(this.user)
    }

    public static logout() {
        localStorage.clear();
        this.user = null;
        this.vCard = null;
        this.pCard = null;
        this.menu = null;
        this.menuList = null;
        this.userCardDetails = null;
    }

    public static isMobile() {
        var ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua))
            return true;
        else
            return false;
    }
    public static isAndroidMobile() {
        var ua = navigator.userAgent;
        if (/Android/i.test(ua))
            return true;
        else
            return false;
    }
    public static isIOSDevice() {
        var ua = navigator.userAgent;
        if (/iPhone|iPad|iPod/i.test(ua))
            return true;
        else
            return false;
    }

    public static getErrorMsg(errorCode, serverMsg) {
        let message;

        if (errorCode === "CB33")
            message = "Registration is not allowed for the entered mobile number. Please try a different mobile number or contact customer care."

        else if (errorCode === "CB02" || errorCode === "CB34" || errorCode === "CB48" || errorCode === "CB50" || errorCode === "YPL04")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "CB36")
            message = "Looks like a different Aadhar is already linked with the entered mobile number."

        else if (errorCode === "CB37")
            message = "Input level validation"

        else if (errorCode === "CB38")
            message = "Input level validation"

        else if (errorCode === "CB40")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "YPP001")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "YPP002")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "YPP003")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "YPP004")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "YPP005")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "YPP006")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R75")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R15")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R80")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R81")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R83")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R76")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R35")
            message = "Your Aadhar verification has failed. Please recheck the details entered."

        else if (errorCode === "R04")
            message = "Your Aadhar verification has failed. Please recheck the details entered."

        else if (errorCode === "R38")
            message = "Something went wrong page"

        else if (errorCode === "R26")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "CB07")
            message = "Please enter name as per PAN"

        else if (errorCode === "CB08")
            message = "This PAN is already linked to another mobile no. Please enter a different PAN to proceed."

        else if (errorCode === "CB09")
            message = "Please enter name as per PAN/Aadhar"

        else if (errorCode === "CB10")
            message = "Your PAN verification has failed. Please recheck the details entered."

        else if (errorCode === "CB11")
            message = "This Aadhar number is already linked to another mobile no. Please enter a different Aadhar number to proceed."

        else if (errorCode === "CB12")
            message = "Your Aadhar verification has failed. Please recheck the details entered."

        else if (errorCode === "CB24")
            message = "Loading of funds could not be initiated, please try again after sometime"

        else if (errorCode === "CB27")
            message = "Txn failure screen"

        else if (errorCode === "CB35")
            message = "Looks like a different PAN is already linked with the entered mobile number"

        else if (errorCode === "R17")
            message = "This Aadhar number is already linked to another mobile no. Please enter a different Aadhar number to proceed."

        else if (errorCode === "CB41")
            message = "This PAN is already linked to another mobile no. Please enter a different PAN to proceed."

        else if (errorCode === "CB42")
            message = "This Aadhar number is already linked to another mobile no. Please enter a different Aadhar number to proceed."

        else if (errorCode === "R02")
            message = "Mobile Number is incorrect"

        else if (errorCode === "R03")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R98")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R105")
            message = "Invalid Kit Number or Validation Number"

        else if (errorCode === "R217")
            message = "Your request could not be processed due to technical issues, please retry in some time."

        else if (errorCode === "R106")
            message = "Keychain already blocked"

        else if (errorCode === "R107")
            message = "Keychain blocked, cannot be unlocked"

        else if (errorCode === "Y503")
            message = "Kit assigned previously to this user"

        else if (errorCode === "YP999")
          message = "Unable to fetch details"

        else if (errorCode === "CB56")
          message = "DOB or Gender is not matching with registered information"

        else if (errorCode === "R29")
          message = "Unable To process request. Please try again after some time"

        if (!message)
            message = serverMsg || "Your request could not be processed due to technical issues, please retry in some time.";

        return message;
    }
}
