<!-- <div *ngFor="let alert of alerts" class="{{cssClass(alert)}}">
    <a class="close" (click)="removeAlert(alert)" style="cursor: pointer;">&times;</a>
    <span class="alert-msg" [innerHTML]="alert.message"></span>
</div> -->

<!-- <div *ngFor="let alert of alerts" class="{{cssClass(alert)}}" [class.toggled]="isErrorDisplay" [ngClass]="{'alert-box-with-title' : alert.title }"> -->
<div *ngFor="let alert of alerts" class="{{cssClass(alert)}}" [ngClass]="{'alert-box-with-title' : alert.title }">
    <div class="alert-box" *ngIf="alert.title">
        <div class="alert-box-with-title-bg" (click)="removeAlert(alert)" style="cursor: pointer;"></div>
        <div class="alert-box-inner">
            <div class="alert-title-message">{{alert.title}}</div>
            <a class="close" (click)="removeAlert(alert)" style="cursor: pointer;"
                [ngClass]="{'alert-title-close' : alert.title }">&times;</a>
            <span class="alert-msg" [innerHTML]="alert.message"></span>
        </div>
    </div>
    <div *ngIf="!alert.title">
        <a class="close" (click)="removeAlert(alert)" style="cursor: pointer;">&times;</a>
        <span class="alert-msg" [innerHTML]="alert.message"></span>
    </div>
</div>