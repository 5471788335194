import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'app/_alert/alert.service';
import { AdaptersService } from 'app/adapters.service';
import { MaskPipe } from 'app/filters/mask.pipe';
import { Globals } from 'app/global.constants';

export const Date_Formats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  providers: [MaskPipe, DatePipe, {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
    { provide: MAT_DATE_FORMATS, useValue: Date_Formats }
  ]
})

export class CardComponent implements OnInit, AfterViewInit {

  params: any;

  menu: any;
  height: any;
  isYESPAY: any;
  menuList: any;

  userCardDetails: any;

  genderType = [
    { display: 'Male', value: 'M' },
    { display: 'Female', value: 'F' }
  ];

  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
  maxDate = new Date();

  alertTitle: string = '';
  alertMessage: string = '';
  errorMessage: string = '';
  oldKitNumber: string = '';
  aadhaarErrorMessage: string = '';
  replaceCardErrorMessage: string = '';

  loading: boolean = false; // loader on Continue Button
  isLoading: boolean = true; // loader on Page
  isSaving: boolean = false;
  isReplaceCardLoading: boolean = false;

  isConsentPage: boolean = false;

  isReplaceModal: boolean = false;
  isReplaceCardPage: boolean = false;
  isCardLinkingModal: boolean = false;
  isRegistrationModal: boolean = false;

  isAadhaarPage: boolean = false;
  isActivateWalletPage: boolean = false;
  isActivateWalletPageForm: boolean = false;
  isActivateWalletPageConsent: boolean = false;

  isFailure: boolean = false;
  isSuccessful: boolean = false;
  isActivatePhysicalCardFailure: boolean = false;
  isActivatePhysicalCardSuccessful: boolean = false;

  cardActivationForm = this.formBuilder.group({
    kitNo: ["", [Validators.required]],
    validationNo: ["", [Validators.required, Validators.pattern("[0-9]{4}$")]]
  });

  replaceCardForm = this.formBuilder.group({
    newKitNo: ["", [Validators.required]],
    newValidationNo: ["", [Validators.required, Validators.pattern("[0-9]{4}$")]]
  });

  walletActivationForm = this.formBuilder.group({
    aadhaarNumber: ["", [Validators.required]],
    fullName: ["", [Validators.required]],
    dob: ["", [Validators.required]],
    gender: ["", [Validators.required]]
  });

  cardSuccessStatus = ['active', 'allocated', 'locked', 'replaced'];

  constructor(
    private router: Router,
    private maskPipe: MaskPipe,
    private datepipe: DatePipe,
    private renderer: Renderer2,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private adaptersService: AdaptersService
  ) { }


  @ViewChild('getHeight')
  elementSize: ElementRef;

  ngAfterViewInit(): void {
    var width = this.elementSize.nativeElement.offsetWidth;
    var height = this.elementSize.nativeElement.offsetHeight;
    this.height = (window.innerHeight - height - 10);
  }

  ngOnInit(): void {

    this.renderer.setStyle(document.body, 'background-image', 'url(../../assets/img/backgrounds/01-bg-blue.png)');

    this.menu = Globals.getMenu();
    this.userCardDetails = Globals.getUserCardDetails();

    this.activatedRoute.params.subscribe(params => {
      this.params = params;
    });

    // If menu object is empty or on page refresh
    if (!this.menu) {
      if (this.params.id) {
        this.isLoading = true;
        this.getMenu(this.params.id);
      }
    }

    // Navigated from menu page
    else {
      this.setIsYesPayFlag();
    }
  }

  setIsYesPayFlag() {
    if (this.menu.menuIdentifier === 'YESPAY') this.isYESPAY = true; else this.isYESPAY = false;
    if (!this.userCardDetails) this.fetchUserCardDetails();
    else this.handleUserCardDetailsResponse(this.userCardDetails);
  }

  getMenu(id: any) {
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus;
        const isObjectPresent = this.menuList.filter((o: any) => o.id == id);
        if (!isObjectPresent.length) this.goToHome();
        else this.setMenu(this.menuList, id);
      } else {
        this.isLoading = false;
        this.menuList = [];
        this.goToHome();
      }
    },
      (err) => {
        this.menuList = [];
        this.goToHome();
      });
  }

  setMenu(res: any, id: any) {
    if (res && res.length > 0) {
      for (let element of res) {
        if (element.id == id) {
          this.menu = element;
          Globals.setMenu(this.menu);
          this.setIsYesPayFlag();
          break;
        }
      }
    }
  }

  previousState() {
    window.history.back();
  }

  goToHome() {
    this.router.navigate(['/menu']);
  }

  goToCardDetail() {
    this.router.navigate([`/card-detail`, this.menu.id]);
  }

  toggleRegistrationModal(action: boolean, title: string, message: string) {
    this.isRegistrationModal = action;

    // Open
    if (this.isRegistrationModal) {
      this.alertMessage = message;
      this.alertTitle = title;
    }

    // Close
    else {
      if (this.isSuccessful) {
        this.isSuccessful = false;
        this.onIsSuccessfulClick();
      } else if (this.isFailure) {
        if (!this.isAadhaarPage) {
          this.isFailure = false;
          this.goToHome();
        }
      }
      this.alertMessage = '';
      this.alertTitle = '';
    }
  }

  toggleLinkingModal(action: boolean, title: string, message: string) {
    this.isCardLinkingModal = action;

    // Open
    if (this.isCardLinkingModal) {
      this.alertMessage = message;
      this.alertTitle = title;
    }

    // Close
    else {
      if (this.isActivatePhysicalCardSuccessful) {
        this.isActivatePhysicalCardSuccessful = false;
        this.onIsActivatePhysicalCardSuccessfulClick();
      } else if (this.isActivatePhysicalCardFailure) {
        this.isActivatePhysicalCardFailure = false;
      }
      this.alertMessage = '';
      this.alertTitle = '';
    }

  }

  replaceCardModal(value: boolean) {
    this.replaceCardErrorMessage = '';
    this.isReplaceModal = value;
    this.replaceCardForm.reset();
  }

  validateKeyChainInputs(input: any) {
    input.blur();
    input.focus();
    this.errorMessage = '';
    this.replaceCardErrorMessage = '';
  }

  onKycInput(input: any) {
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  onAadhaarNameInput(input: any) {
    input.value = input.value.replace(/[^a-zA-Z ]/g, '');
  }

  fetchUserCardDetails() {
    this.isLoading = true;
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier,
    };
    this.adaptersService.fetchUserCardDetails(data).then(
      (res: any) => {
        this.handleUserCardDetailsResponse(res);
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  registerCard() {
    this.loading = true;
    this.isLoading = true;
    this.isConsentPage = false;
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier,
    };
    this.adaptersService.registerCard(data).then(
      (res: any) => {
        if (res) {

          // If wallet registration fails
          if (res.responseStatus && (res.responseStatus.toLowerCase() === 'failure' || res.responseStatus.toLowerCase() === 'error')) {
            this.isFailure = true;
            this.isSuccessful = false;

            // YPL04 - If there is mismatch in entries of URN present in user_master and q_rupay_user tables
            // CB34 - Timeout
            if (res.responseCode === 'YPL04' || res.responseCode === 'CB34') {
              setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) }, 1000);
              this.goToHome();
            } else {
              this.toggleRegistrationModal(true,
                'Wallet Registration Failed',
                'Your ' + this.menu.menuIdentifier + ' wallet registration has been failed. Please try again after some time.'
              );
            }
          }

          else {

            Globals.setUserCardDetails(res);

            // Check res.isAadhaarVerification if true, don't call fetch
            if (res.isAadhaarVerification) {
              this.walletActivationForm.reset();
              this.isAadhaarPage = true;
            }

            // Check res.isAadhaarVerification if false, call fetch
            if (!res.isAadhaarVerification) {
              var data = {
                cardType: this.menu.ypsorPrepaidProgram.cardType,
                programId: this.menu.ypsorPrepaidProgram.programIdentifier,
              };
              this.adaptersService.fetchUserCardDetails(data).then(
                (res: any) => {
                  if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
                    setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) });
                  } else {
                    Globals.saveVCard(res.virtualCardResponse);
                    Globals.savePCard(res.physicalCardResponse);
                  }
                }
              );

              this.isFailure = false;
              this.isSuccessful = true;

              this.toggleRegistrationModal(true,
                'Registered Successfully!',
                'You have been successfully registered for ' + this.menu.menuIdentifier + ' Wallet'
              );

            }
          }

        }
        this.loading = false;
        this.isLoading = false;
      },
      (err) => {
        this.loading = false;
        this.isLoading = false;
        this.isConsentPage = true;
      }
    );
  }

  openActivationForm() {
    this.cardActivationForm.reset();
    this.isActivateWalletPageConsent = false;
    this.isActivateWalletPageForm = true;
    this.isActivateWalletPage = true;
  }

  onIsSuccessfulClick() {
    if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {
      this.isAadhaarPage = false;
      this.isActivateWalletPageConsent = true;
      this.isActivateWalletPage = true;
    } else if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
      this.goToCardDetail();
    }
  }

  activatePhysicalCard() {
    this.isSaving = true;
    this.errorMessage = '';
    var data = {
      kitNumber: this.cardActivationForm.get('kitNo').value,
      validationNumber: this.cardActivationForm.get('validationNo').value,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier
    };
    this.adaptersService.activatePhysicalCard(data).then(
      (res: any) => {

        const maskedKitNo = this.maskPipe.transform(this.cardActivationForm.value.kitNo, "4", "*", "4");

        if (res.responseStatus && (res.responseStatus.toLowerCase() === 'failure' || res.responseStatus.toLowerCase() === 'error')) {
          if (res.responseCode === 'CB34') {
            setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) }, 1000);
            this.goToHome();
          } else if (res.responseCode === 'R105') {
            this.errorMessage = 'The combination does not match. Please check and retry.';
          } else {
            this.isActivatePhysicalCardFailure = true;
            this.isActivatePhysicalCardSuccessful = false;
            this.toggleLinkingModal(true,
              this.menu.menuIdentifier + ' Card Linking Failed!',
              'Your ' + this.menu.menuIdentifier + ' Card linking with kit no. ' + maskedKitNo + ' has been failed. Please try again after some time'
            );
          }
        }

        else {

          this.isActivatePhysicalCardFailure = false;
          this.isActivatePhysicalCardSuccessful = true;

          Globals.setUserCardDetails(res);

          // Call fetchUserCardDetails after activating physical card is success.
          var data = {
            cardType: this.menu.ypsorPrepaidProgram.cardType,
            programId: this.menu.ypsorPrepaidProgram.programIdentifier,
          };
          this.adaptersService.fetchUserCardDetails(data).then(
            (res: any) => {
              if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
                setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) });
              } else {
                Globals.saveVCard(res.virtualCardResponse);
                Globals.savePCard(res.physicalCardResponse);
              }
              this.toggleLinkingModal(true,
                this.menu.menuIdentifier + ' Card Linked Successfully!',
                'Your ' + this.menu.menuIdentifier + ' Card linking with kit no. ' + maskedKitNo + ' has been linked successfully'
              );
            }
          );

        }
        this.isSaving = false;
      },
      (error) => {
        this.isSaving = false;
      }
    );
  }

  // On SUCCESS of activatePhysicalCard API
  onIsActivatePhysicalCardSuccessfulClick() {
    this.goToCardDetail();
  }

  registerAadhaar() {
    this.isSaving = true;
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier,
      aadhaarNo: this.walletActivationForm.get(['aadhaarNumber']).value,
      fullName: this.walletActivationForm.get('fullName').value ? this.walletActivationForm.get('fullName').value.trim() : '',
      dob: this.walletActivationForm.get('dob').value ? this.datepipe.transform(this.walletActivationForm.get('dob').value, 'yyyy-MM-dd') : null,
      gender: this.walletActivationForm.get('gender').value
    };
    this.adaptersService.registerAadhaar(data).then(
      (res: any) => {
        if (res) {

          if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
            if (res.responseCode === 'CB34') {
              setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) }, 1000);
              this.goToHome();
            } else if (res.responseCode === 'CB56') {
              this.aadhaarErrorMessage = Globals.getErrorMsg(res.responseCode, res.responseMessage);
            } else {
              this.isFailure = true;
              this.isSuccessful = false;
              this.toggleRegistrationModal(true, 'Wallet Registration Failed',
                'Your ' + this.menu.menuIdentifier + ' wallet registration has been failed. Please try again after some time.');
            }
          }

          else {

            this.isFailure = false;
            this.isSuccessful = true;

            Globals.setUserCardDetails(res);

            // Call fetchUserCardDetails after registration through Aadhaar is success.
            var data = {
              cardType: this.menu.ypsorPrepaidProgram.cardType,
              programId: this.menu.ypsorPrepaidProgram.programIdentifier,
            };
            this.adaptersService.fetchUserCardDetails(data).then(
              (res: any) => {
                if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
                  setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) });
                } else {
                  Globals.saveVCard(res.virtualCardResponse);
                  Globals.savePCard(res.physicalCardResponse);
                }
                this.toggleRegistrationModal(true, 'Registered Successfully!',
                  'You have been successfully registered for ' + this.menu.menuIdentifier + ' Wallet');
              }
            );

          }
        }
        this.isSaving = false;
      },
      (err) => {
        this.isSaving = false;
      }
    );
  }

  handleUserCardDetailsResponse(res: any) {

    if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {

      // User is BLOCKED
      if (res.responseCode === 'CB33') setTimeout(() => { this.alertService.error("For security reasons, login through this number is restricted. Please try with a different mobile number.", { autoClose: true, keepAfterRouteChange: true }) }, 1000);
      else setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) });

      this.goToHome();

    }

    else {

      if (res) {
        Globals.setUserCardDetails(res);
        Globals.saveVCard(res.virtualCardResponse);
        Globals.savePCard(res.physicalCardResponse);
      }

      // If user not found
      if (res && !res.isPpiUserPresent) {

        // Ask for user consent
        if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {
          this.isLoading = false;
          this.isConsentPage = true;
        }

        // Wallet registration API to be called
        else {
          this.registerCard();
        }
      }

      // If user found and user status is ACTIVE - Check if VIRTUAL or PHYSICAL
      else if (res && res.isPpiUserPresent) {
        if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
          this.goToCardDetail();
        }
        else if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {

          // If cardStatus is ACTIVE or ALLOCATED
          if (res.physicalCardResponse && res.physicalCardResponse.cardStatus && this.cardSuccessStatus.includes(res.physicalCardResponse.cardStatus.toLowerCase())) {
            this.goToCardDetail();
          }

          // If cardStatus is BLOCKED
          else if (res.physicalCardResponse && res.isReplaceCard) {
            this.oldKitNumber = res.physicalCardResponse.kitNumber;
            this.isLoading = false;
            this.isReplaceCardPage = true;
          }

          // If NO card details are present
          else if (!res.physicalCardResponse && res.isActivateCard) {
            this.isActivateWalletPage = true;
            this.openActivationForm();
          }

        }

      }
    }
    this.isLoading = false;
  }

  replaceCard() {
    this.isReplaceCardLoading = true;
    this.replaceCardErrorMessage = '';
    if (this.replaceCardForm.valid) {
      const reqObj = {
        oldKitNumber: this.oldKitNumber || null,
        newKitNumber: this.replaceCardForm.value.newKitNo,
        validationNumber: this.replaceCardForm.value.newValidationNo,
        programId: this.menu.ypsorPrepaidProgram.programIdentifier
      }
      this.adaptersService.replaceCard(reqObj).then((res: any) => {
        if (res) {
          if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
            this.replaceCardErrorMessage = res.responseMessage;
          } else {

            Globals.setValue("isReplaceCard", false);

            // Call fetchUserCardDetails after replace card is success.
            var data = {
              cardType: this.menu.ypsorPrepaidProgram.cardType,
              programId: this.menu.ypsorPrepaidProgram.programIdentifier,
            };
            this.adaptersService.fetchUserCardDetails(data).then(
              (res: any) => {
                if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
                  setTimeout(() => { this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true }) });
                }

                Globals.saveVCard(res.virtualCardResponse);
                Globals.savePCard(res.physicalCardResponse);

                setTimeout(() => {
                  this.alertService.success((this.isYESPAY ? "Keychain" : "card") + " replaced successfully!", { autoClose: true });
                });

                this.replaceCardModal(false);
                this.goToCardDetail();
              }
            );

          }
          this.isReplaceCardLoading = false;
        }
      }, (err: any) => {
        this.replaceCardModal(false);
        this.isReplaceCardLoading = false;
      });
    }
  }

  clearAadharErrorMessage() {
    this.aadhaarErrorMessage = '';
  }

}
