import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'app/_alert/alert.service';
import { AdaptersService } from 'app/adapters.service';
import { Globals } from 'app/global.constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  cardSuccessStatus = ['active', 'allocated', 'locked', 'replaced'];
  menuList: any = [];

  isLoading = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private alertService: AlertService,
    private adaptersService: AdaptersService
  ) { }

  ngOnInit(): void {
    this.renderer.setStyle(document.body, 'background-image', 'url(../../assets/img/backgrounds/01-bg-white.png)');
    this.menuList = Globals.getMenuList();
    if (!this.menuList) this.getMenu();
  }

  getMenu() {
    this.isLoading = true;
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus.sort((a, b) => a.sequence - b.sequence);
      } else {
        this.menuList = [];
      }
      this.isLoading = false;
    },
      (err) => {
        this.isLoading = false;
        this.menuList = [];
      });
  }

  navigateToCardDetails(menu: any) {
    this.isLoading = true;

    Globals.saveVCard(null);
    Globals.savePCard(null);
    Globals.setUserCardDetails(null);

    if (menu && menu.menuCategory === 'PREPAID_PROGRAMS' && menu.ypsorPrepaidProgram) {

      Globals.setMenu(menu);

      if (menu.menuIdentifier === 'YESPAY') {

        // Existing flow to be followed
        this.router.navigate([`/card-detail`, menu.id]);

      } else {

        // Check if wallet already created under the program
        var data = {
          cardType: menu.ypsorPrepaidProgram.cardType,
          programId: menu.ypsorPrepaidProgram.programIdentifier
        }
        this.adaptersService.fetchUserCardDetails(data).then((res: any) => {

          if (res) {
            Globals.setUserCardDetails(res);
            Globals.saveVCard(res.virtualCardResponse);
            Globals.savePCard(res.physicalCardResponse);
          }

          if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {

            // User is BLOCKED
            if (res.responseCode === 'CB33') setTimeout(() => { this.alertService.error("For security reasons, login through this number is restricted. Please try with a different mobile number.", { autoClose: true, keepAfterRouteChange: true }) }, 1000);
            else this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });

          }

          else {

            // If user not found
            if (res && !res.isPpiUserPresent) {
              this.router.navigate([`/card`, menu.id]);
            }

            // If user found and user status is ACTIVE - Check if VIRTUAL or PHYSICAL
            else if (res && res.isPpiUserPresent) {
              if (menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
                this.router.navigate([`/card-detail`, menu.id]);
              }
              else if (menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {

                // If cardStatus is ACTIVE, REPLACED or ALLOCATED
                if (res.physicalCardResponse && res.physicalCardResponse.cardStatus && this.cardSuccessStatus.includes(res.physicalCardResponse.cardStatus.toLowerCase())) {
                  this.router.navigate([`/card-detail`, menu.id]);
                }

                // If cardStatus is BLOCKED - isReplaceCard: true
                else if (res.physicalCardResponse && res.isReplaceCard) {
                  this.router.navigate([`/card`, menu.id]);
                }

                // If NO card details are present
                else if (!res.physicalCardResponse && res.isActivateCard) {
                  this.router.navigate([`/card`, menu.id]);
                }
              }
            }
          }
          this.isLoading = false;
        }, (err) => {

          this.isLoading = false;
        });

      }
    } else {
      this.isLoading = false;
    }
  }

  logout() {
    Globals.logout();
    this.adaptersService.logout({});
    this.router.navigate(['/login']);
  }
}

