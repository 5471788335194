<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close model-cross-icon" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body set-pin-body">
    <form [formGroup]="editForm" class="pl-3 pr-3 pt-1">
      <mat-form-field class="form-group example-full-width mb-0 w-100">
        <mat-label>New PIN</mat-label>
        <input matInput type="password" numbersOnly class="form-control new-pin-input" placeholder="" name="newPin" formControlName="newPin"autocomplete="off" maxLength="4">
        <div class="error-wrpr" *ngIf="editForm.get('newPin').invalid && (editForm.get('newPin').dirty || editForm.get('newPin').touched || editForm.get('newPin').errors.pattern)">
          <mat-error *ngIf="editForm.get('newPin').errors.required || editForm.get('newPin').errors.minlength || editForm.get('newPin').errors.maxlength || editForm.get('newPin').errors.pattern">
            Please enter 4 digit valid PIN
          </mat-error>
        </div>
      </mat-form-field>
      <mat-form-field class="form-group example-full-width mb-0 w-100 mt-10">
        <mat-label>Confirm New PIN</mat-label>
        <input matInput type="text" numbersOnly class="form-control" placeholder="" name="confirmPin" formControlName="confirmPin" autocomplete="off"  maxLength="4" (ngModelChange)="confirmPinChange($event)">
        <div class="error-wrpr" *ngIf="editForm.get('confirmPin').invalid && (editForm.get('confirmPin').dirty || editForm.get('confirmPin').touched || editForm.get('confirmPin').errors.pattern)">
          <mat-error *ngIf="editForm.get('confirmPin').errors.required || editForm.get('confirmPin').errors.pattern">
            Please enter 4 digit valid PIN
          </mat-error>
        </div>
        <div class="error-wrpr" *ngIf="confirmationErrorText">
          <mat-error>{{confirmationErrorText}}</mat-error>
        </div>
      </mat-form-field>
      <div class="text-center mt-2">
        <button type="button" class="btn btn-danger mr-2" (click)="decline()">{{ btnCancelText }}</button>
        <button type="button" class="btn btn-primary" (click)="submit()" [disabled]="editForm.invalid || isSaving || btnDissable">{{ btnOkText }}</button>
      </div>
    </form>
  </div>
  <!-- <div class="modal-footer">
  </div> -->
