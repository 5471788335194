import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../global.constants';
import { AdaptersService } from 'app/adapters.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() showBackButton: boolean;
  @Input() settingIcon: boolean;
  @Input() showMenu: boolean = true;
  @Input() showHome: boolean;
  @Input() cardBackgroundTextColor: string;
  @Output() onBack: EventEmitter<any> = new EventEmitter();
  @Input() showMenuIcon: boolean = true;

  moreToggleFlage = false;

  constructor(private router: Router, private adapterService: AdaptersService) { }

  ngOnInit(): void {

  }
  previousState() {
    if (this.onBack.observers.length)
      this.onBack.emit();
    else
      window.history.back();
  }
  moreToggle() {
    this.moreToggleFlage = !this.moreToggleFlage;
  }
  goToSettings() {
    const menu = Globals.getMenu();
    this.router.navigate(['/settings/' + menu.id]);
  }
  goToFAQs() {
    this.router.navigate(['/faqs']);
  }
  logout() {
    Globals.logout()
    this.adapterService.logout({});
    this.router.navigate(['/login'])
  }
  goToHome() {
    this.router.navigate(['/menu']);
  }
}
