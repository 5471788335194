import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AdaptersService {

  requestURL = "cobank/yespay-lite/";

  constructor(private apiService: ApiService) { }

  getOtp(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "get-otp";
    return this.apiService.commonApiConfig(reqObj, method, path);
  }
  verifyOtp(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "verify-otp";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  resendOtp(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "resend-otp";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  userDetails(reqObj: any) {
    const method = "GET";
    const path = `${this.requestURL}` + "api/user-details";
    return this.apiService.commonApiConfig(reqObj, method, path, true);
  }
  registerPpiUser(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/register-ppi-user";
    return this.apiService.commonApiConfig(reqObj, method, path);
  }
  setPasscode(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/set-passcode";
    return this.apiService.commonApiConfig(reqObj, method, path);
  }
  dashboard(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/dashboard";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  virtualCard(reqObj: any) {
    const method = "GET";
    const path = `${this.requestURL}` + "api/virtual-card";
    return this.apiService.commonApiConfig(reqObj, method, path, false);
  }
  blockVcard(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/block-vcard";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  channelFlag(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/channel-flag";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  txnLimit(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/txn-limit";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  linkKeyChain(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/link-key-chain";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  physicalCard(reqObj: any) {
    const method = "GET";
    const path = `${this.requestURL}` + "api/physical-card";
    return this.apiService.commonApiConfig(reqObj, method, path, false);
  }
  blockPcard(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/block-pcard";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  replaceCard(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/replace-card";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  fetchTxns(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/fetch-txns";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  registerIntent(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/register-intent";
    return this.apiService.commonApiConfig(reqObj, method, path);
  }
  txnStatus(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/txn-status";
    return this.apiService.commonApiConfig(reqObj, method, path, false);
  }
  setPin(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/set-key-chain-pin";
    return this.apiService.commonApiConfig(reqObj, method, path);
  }
  menu(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/yp-lite-web-menu?channel.equals=YPLITE_WEB";
    return this.apiService.commonApiConfig(reqObj, method, path);
  }
  fetchUserCardDetails(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/fetch-user-card-details";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  registerCard(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/register-card";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  activatePhysicalCard(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/activate-physical-card";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  registerAadhaar(reqObj: any) {
    const method = 'POST';
    const path = `${this.requestURL}` + 'api/register-aadhaar';
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
  logout(reqObj: any) {
    const method = 'POST';
    const path = `${this.requestURL}` + 'api/logout';
    return this.apiService.commonApiConfig(reqObj, method, path, false);
  }
  verifyOtpModifyLimit(reqObj: any) {
    const method = "POST";
    const path = `${this.requestURL}` + "api/verify-otp-modify-limit";
    return this.apiService.commonApiConfig(reqObj, method, path, false, true);
  }
}
