import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_alert';
import { ModalService } from '../_modal';
import { AdaptersService } from '../adapters.service';
import { Globals } from '../global.constants';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  params: any;

  otpForm: UntypedFormGroup;
  keychainForm: UntypedFormGroup;

  perPosTransactionLimit = 0;
  perEcomTransactionLimit = 0;

  isYESPAY: any;
  giftCard: any;
  messageModal: any;
  physicalCard: any;

  steps = [0, 1, 2, 3, 4, 5];

  txnLimits: any = {}
  userData = {} as any;
  txnFlags = { "ECOM": null, "POS": null, "ATM": null, "CL": null, "L": null, "UL": null, "BL": null } as any;

  msg: string;
  title: string;
  alertTitle: string;
  channelType: string;
  alertMessage: string;
  errorMessage: string;

  isLoading = false;
  linkKeychain = false;
  isPageLoading = false;
  isSetPinLoading = false;
  isSavePrefLoader = false;
  confirmationModalButtonFlag = false;
  responseECOM: any;
  responsePOS: any;

  menuList: any = [];
  menu: any;

  userCardDetails: any;
  cardSuccessStatus = ['active', 'allocated', 'replaced'];

  invalidOtp = false;
  displayOtpScreen = false;
  isVerifyOtpModifyLimitApiLoading = false;

  counter: any;
  interval: any;
  txnLimitResponse: any;

  constructor(private router: Router, private adaptersService: AdaptersService, public alertService: AlertService, private modalService: ModalService, private formBuilder: UntypedFormBuilder, protected activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.isPageLoading = true;
    this.initializeForm();

    this.menu = Globals.getMenu();
    this.userCardDetails = Globals.getUserCardDetails();

    this.activatedRoute.params.subscribe(params => {
      this.params = params;
    });

    // If menu object is empty or on page refresh
    if (!this.menu) {
      if (this.params.id) {
        this.isLoading = true;
        this.getMenu(this.params.id);
      }
    }

    // Navigated from previous page
    else {
      this.setIsYesPayFlag();
    }

  }

  initializeForm() {
    this.otpForm = this.formBuilder.group({
      otp: [null, [Validators.required, Validators.max(999999), Validators.pattern("[0-9]{6}$")]],
      otpReferenceNumber: [null, [Validators.required]]
    });

    this.keychainForm = this.formBuilder.group({
      oldKitNo: [],
      kitNo: ["", [Validators.required]],
      validationNo: ["", [Validators.required, Validators.pattern("[0-9]{4}$")]]
    });
  }

  setIsYesPayFlag() {
    if (this.menu.menuIdentifier === 'YESPAY') this.isYESPAY = true; else this.isYESPAY = false;
    this.callAPIs();
  }

  callAPIs() {

    // Existing Flow
    if (this.isYESPAY) {
      this.initializeData();
    }

    // Fetch User Card Details
    else if (!this.isYESPAY) {
      if (!this.userCardDetails) this.fetchUserCardDetails();
      else this.handleUserCardDetailsResponse(this.userCardDetails);
    }

  }

  // flag for removeUserDataChangeListerner
  setBalance(flag: boolean) {
    this.userData.walletBalance = this.userData.walletBalance.replace(/,/g, "");
    this.userData.remainingWalletBalanceLimit = this.userData.remainingWalletBalanceLimit.replace(/,/g, "");
    if (flag) Globals.removeUserDataChangeListerner();
  }

  initializeData() {

    this.giftCard = Globals.getVCard();
    this.physicalCard = Globals.getPCard();

    if (this.isYESPAY) {
      if (this.giftCard) {
        if (this.giftCard.transactionType) {
          this.txnFlags.ECOM = this.giftCard.transactionType.ECOM !== "D";
          this.txnFlags.L = this.giftCard.cardStatus ? !this.cardSuccessStatus.includes(this.giftCard.cardStatus.toLowerCase()) : false;
        }
        setTimeout(() => {
          if (!this.txnFlags.ECOM)
            this.confirmationModal('ECOM');
        }, 300)
        if (this.giftCard.limits) {
          this.responseECOM = Math.trunc(this.giftCard.limits.ECOM);
          this.txnLimits.ECOM = Math.trunc(this.giftCard.limits.ECOM);
        }
      }
      if (this.physicalCard) {
        if (this.physicalCard.transactionType) {
          this.txnFlags.POS = this.physicalCard.transactionType.POS !== "D";
          this.txnFlags.ATM = this.physicalCard.transactionType.ATM !== "D";
          this.txnFlags.CL = this.physicalCard.transactionType.CL !== "D";
          this.txnFlags.UL = this.physicalCard.cardStatus === 'LOCKED';
          this.txnFlags.BL = this.physicalCard.cardStatus === 'BLOCKED';
        }
        if (this.physicalCard.limits) {
          this.txnLimits.POS = Math.trunc(this.physicalCard.limits.POS);
          this.responsePOS = Math.trunc(this.physicalCard.limits.POS);
          this.txnLimits.ATM = Math.trunc(this.physicalCard.limits.ATM);
          this.txnLimits.CL = Math.trunc(this.physicalCard.limits.CL);
        }
      }
    }

    if (!this.isYESPAY) {
      if (this.giftCard && (this.menu.ypsorPrepaidProgram && this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL')) {
        if (this.giftCard.transactionType) {
          this.txnFlags.ECOM = this.giftCard.transactionType.ECOM !== "D";
          this.txnFlags.L = this.giftCard.cardStatus ? !this.cardSuccessStatus.includes(this.giftCard.cardStatus.toLowerCase()) : false;
        }
        setTimeout(() => {
          if (!this.txnFlags.ECOM)
            this.confirmationModal('ECOM');
        }, 300)
        if (this.giftCard.limits) {
          this.responseECOM = Math.trunc(this.giftCard.limits.ECOM);
          this.txnLimits.ECOM = Math.trunc(this.giftCard.limits.ECOM);
        }
      }
      if (this.physicalCard && (this.menu.ypsorPrepaidProgram && this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL')) {
        if (this.physicalCard.transactionType) {

          // Added ECOM for Physical card in !isYESPAY flow
          this.txnFlags.ECOM = this.physicalCard.transactionType.ECOM !== "D";
          this.txnFlags.L = this.physicalCard.cardStatus ? !this.cardSuccessStatus.includes(this.physicalCard.cardStatus.toLowerCase()) : false;
          setTimeout(() => {
            if (!this.txnFlags.ECOM)
              this.confirmationModal('ECOM');
          }, 300)

          this.txnFlags.POS = this.physicalCard.transactionType.POS !== "D";
          this.txnFlags.ATM = this.physicalCard.transactionType.ATM !== "D";
          this.txnFlags.CL = this.physicalCard.transactionType.CL !== "D";
          this.txnFlags.UL = this.physicalCard.cardStatus === 'LOCKED';
          this.txnFlags.BL = this.physicalCard.cardStatus === 'BLOCKED';

        }
        if (this.physicalCard.limits) {

          // Added ECOM for Physical card in !isYESPAY flow
          this.txnLimits.ECOM = Math.trunc(this.physicalCard.limits.ECOM);
          this.responseECOM = Math.trunc(this.physicalCard.limits.ECOM);

          this.txnLimits.POS = Math.trunc(this.physicalCard.limits.POS);
          this.responsePOS = Math.trunc(this.physicalCard.limits.POS);

          this.txnLimits.ATM = Math.trunc(this.physicalCard.limits.ATM);
          this.txnLimits.CL = Math.trunc(this.physicalCard.limits.CL);

        }
      }
    }

    if (!this.giftCard && !this.physicalCard) {
      this.goToCardDetail();
    }

    this.userData = Globals.getUser();

    if (!this.userData) {
      Globals.setUserDataChangeListerner((user) => {
        this.userData = user;
        this.setBalance(true);
      })
    } else {
      this.setBalance(false);
    }

    this.isPageLoading = false;
  }

  enableDisable(resp: any) {
    this.isPageLoading = true;
    Globals.reFreshCards = true;

    if (resp) {
      if (["UL", "BL"].includes(this.channelType)) {
        const reqObj = {
          "type": this.txnFlags[this.channelType] ? 'UL' : (this.channelType === "UL" ? "L" : "BL"),
          "reason": "Yes Pay Lite",
        } as any;

        if (!this.isYESPAY) {
          if (this.menu.ypsorPrepaidProgram) {
            if (this.menu.ypsorPrepaidProgram.programIdentifier) reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
            if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {
              if (this.physicalCard) reqObj.kitNumber = this.physicalCard.kitNumber;
            } else if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
              if (this.giftCard) reqObj.kitNumber = this.giftCard.kitNumber;
            }
          }
        }
        if (this.isYESPAY || (!this.isYESPAY && this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL')) {
          if (reqObj.type === 'L') {
            reqObj.reason = "User initiated Card lock";
          } else if (reqObj.type === 'UL') {
            reqObj.reason = "User initiated Card unlock";
          } else if (reqObj.type === 'BL') {
            reqObj.reason = "User initiated Card block";
          }
          this.adaptersService.blockPcard(reqObj).then((res: any) => {

            if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
              this.displayError(res);
              this.isPageLoading = false;
            }
            else {
              this.txnFlags[this.channelType] = !this.txnFlags[this.channelType];
              if (!this.isYESPAY) this.fetchUserCardDetails();
              else this.isPageLoading = false;
            }

          }, (error: any) => {
            this.isPageLoading = false;
          });
        }

        else if ((!this.isYESPAY && this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL')) {
          this.adaptersService.blockVcard(reqObj).then((res: any) => {

            if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
              this.displayError(res);
              this.isPageLoading = false;
            }
            else {
              this.txnFlags[this.channelType] = !this.txnFlags[this.channelType];
              if (!this.isYESPAY) this.fetchUserCardDetails();
              else this.isPageLoading = false;
            }

          }, (error: any) => {
            this.isPageLoading = false;
          });
        }
      }

      else if (this.channelType == "L") {
        const reqObj = {
          "type": this.txnFlags[this.channelType] ? 'unblock' : 'block',
          "reason": "Yes Pay Lite",
          "kitNumber": (this.isYESPAY && this.giftCard && this.giftCard.transactionType) ? this.giftCard.transactionType.Kit_Number : null
        } as any;
        if (!this.isYESPAY) {
          if (this.menu.ypsorPrepaidProgram) {
            if (this.menu.ypsorPrepaidProgram.programIdentifier) reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
            if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {
              if (this.physicalCard) reqObj.kitNumber = this.physicalCard.kitNumber;
            }
            if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
              if (this.giftCard) reqObj.kitNumber = this.giftCard.kitNumber;
            }
          }
        }
        this.adaptersService.blockVcard(reqObj).then((res: any) => {

          if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
            this.displayError(res);
            this.isPageLoading = false;
          }
          else {
            this.txnFlags[this.channelType] = !this.txnFlags[this.channelType];
            if (!this.isYESPAY) this.fetchUserCardDetails();
            else this.isPageLoading = false;
          }

        }, (error: any) => {
          this.isPageLoading = false;
        });
      }

      else {
        const reqObj = {
          "channelType": this.channelType,
          "transactionFlag": this.txnFlags[this.channelType] ? "D" : "E",
          "cardType": (this.isYESPAY || this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') ? "V" : "P"
        } as any;
        if (this.isYESPAY && this.channelType !== 'ECOM') {
          reqObj.cardType = (this.isYESPAY || this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') ? "P" : "V"
          reqObj.kitNumber = this.physicalCard ? this.physicalCard.kitNumber : null
        }
        if (!this.isYESPAY) {
          if (this.menu.ypsorPrepaidProgram) {
            if (this.menu.ypsorPrepaidProgram.programIdentifier) reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
            reqObj.cardType = this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL' ? "P" : "V";
            if (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL') {
              if (this.physicalCard) reqObj.kitNumber = this.physicalCard.kitNumber;
            }
            if (this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL') {
              if (this.giftCard) reqObj.kitNumber = this.giftCard.kitNumber;
            }
          }
        }
        this.adaptersService.channelFlag(reqObj).then((res: any) => {

          if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
            this.displayError(res);
            this.isPageLoading = false;
          }
          else {
            this.txnFlags[this.channelType] = !this.txnFlags[this.channelType];
            if (!this.isYESPAY) this.fetchUserCardDetails();
            else this.isPageLoading = false;
          }

        }, (error: any) => {
          this.isPageLoading = false;
        });
      }
    } else {
      this.isPageLoading = false;
    }
  }

  confirmationModal(type) {
    this.channelType = type
    var action, state;
    if (this.txnFlags[type]) {
      action = "Disable";
      state = "enabled";
    } else {
      action = "Enable";
      state = "disabled";
    }
    if (type === 'ECOM') {
      this.title = action + " Ecom Transactions";
      this.msg = "E-commerce transactions on this " + (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL' ? 'physical' : 'virtual') + " card are currently " + state + ". Would you like to " + action.toLowerCase() + " it ?"
    } else if (type === 'POS') {
      this.title = action + " POS Transactions";
      this.msg = "POS transactions on this " + (this.isYESPAY ? "keychain" : "card") + " are currently " + state + ". Would you like to " + action.toLowerCase() + " it ?"
    } else if (type === 'CL') {
      this.title = action + " CL Transactions";
      this.msg = "Contactless transactions on this " + (this.isYESPAY ? "keychain" : "card") + " are currently " + state + ". Would you like to " + action.toLowerCase() + " it ?"
    } else if (type === 'L') {
      if (this.txnFlags[type]) {
        this.title = "Unlock Card";
        this.msg = "Your " + (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL' ? 'physical' : 'virtual') + " card is currently Locked. Would you like to unlock it ?"
      } else {
        this.title = "Lock Card";
        this.msg = "Your " + (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL' ? 'physical' : 'virtual') + " card will get temporarily locked and you will not be able to transact online or reset preferences until you unlock it again."
      }
    } else if (type === 'UL') {
      if (this.txnFlags[type]) {
        this.title = "Unlock " + (this.isYESPAY ? "keychain" : "card");
        this.msg = "Your " + (this.isYESPAY ? "keychain" : "card") + " is currently Locked. Would you like to unlock it ?"
      } else {
        this.title = "Lock " + (this.isYESPAY ? "keychain" : "card");
        this.msg = "Your " + (this.isYESPAY ? "keychain" : "card") + " will get temporarily locked and you will not be able to transact at any terminal or reset preferences until you unlock it again."
      }
    } else if (type === 'BL') {
      if (!this.txnFlags[type]) {
        this.title = "Block " + (this.isYESPAY ? "keychain" : "card");
        this.msg = "Your " + (this.isYESPAY ? "keychain" : "card") + " will get permanently blocked. If you wish to replace it later, you can revisit the same dealer to get new kit details."
      }
    } else {
      this.msg = "Are you sure you want to Proceed?"
    }
    this.confirmationModalButtonFlag = true;
    this.modalService.open("confirm-modal");
  }

  infoModal(type) {
    if (type == 'LW') {
      this.title = "Lock Wallet";
      this.msg = "Your Wallet will get temporarily locked and you will not be able to transact online or reset preferences until you unlock it again.";
    }
    else if (type == 'LK') {
      this.title = "Lock " + (this.isYESPAY ? "keychain" : "card");
      this.msg = "Your " + (this.isYESPAY ? "keychain" : "card") + " will get temporarily locked and you will not be able to transact at any terminal or reset preferences until you unlock it again.";
    }
    else if (type == 'BK') {
      this.title = "Block " + (this.isYESPAY ? "keychain" : "card");
      this.msg = "Your " + (this.isYESPAY ? "keychain" : "card") + " will get permanently blocked. If you wish to replace it later, you can revisit the same dealer to get new kit details.";
    }
    this.confirmationModalButtonFlag = false;
    this.modalService.open("confirm-modal");
  }

  savePreferences(flag: string) {
    this.otpForm.reset();
    this.isSavePrefLoader = true;
    const reqObj = {} as any
    Object.keys(this.txnFlags).forEach((item) => {
      if (["ECOM", "POS"].includes(item) && this.txnFlags[item]) {
        reqObj[item.toLowerCase() + "Amount"] = Math.floor(this.txnLimits[item]) + ""
      }
    });
    if (!this.isYESPAY && this.menu.ypsorPrepaidProgram.programIdentifier) {
      reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
    }
    if (Object.keys(reqObj) && Object.keys(reqObj).length) {
      this.adaptersService.txnLimit(reqObj).then((res: any) => {
        if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
          this.displayError(res);
        }
        else {
          this.txnLimitResponse = res;
          this.displayOtpScreen = true;
          this.counter = this.txnLimitResponse.resendTime || 60;
          this.startCountDown();
          setTimeout(() => {
            document.getElementById("otp").focus();
          }, 1000);
        }
        this.isSavePrefLoader = false;
      }, (err: any) => {
        this.isSavePrefLoader = false;
        if (flag === 'resendOTP') {
          if (err && err.error && err.error.title && err.error.title.includes("Failed OTP attempts exceeded")) {
            this.alertService.error('Maximum OTP attempts exceeded');
            this.hideOtpScreen(null, null);
          }
        }
      })
    }
  }

  limitChange(type) {
    if (Number(this.txnLimits[type]) > 10000) {
      this.txnLimits[type] = "10000";
    }
  }

  goBack() {
    if (!this.isSavePrefLoader) {
      window.history.back();
    }
  }

  toggleKeychainModal() {
    this.linkKeychain = !this.linkKeychain;
  }

  validateKeyChainInputs(input: any) {
    input.blur();
    input.focus();
    this.errorMessage = '';
  }

  linkKeyChain() {
    this.errorMessage = null;
    this.keychainForm.markAllAsTouched();
    if (this.keychainForm.valid) {
      this.isLoading = true;
      const reqObj = {
        oldKitNumber: null,
        newKitNumber: this.keychainForm.value.kitNo,
        validationNumber: this.keychainForm.value.validationNo
      } as any;
      if (!this.isYESPAY
        && this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL'
        && this.menu.ypsorPrepaidProgram.programIdentifier) {
        reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
      }
      this.adaptersService.replaceCard(reqObj).then((res: any) => {
        this.isLoading = false;

        if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
          this.isLoading = false;
          this.errorMessage = res.responseMessage;
        }
        else {
          this.alertService.success((this.isYESPAY ? "Keychain" : "card") + " replaced successfully!", { autoClose: true });
          this.keychainForm.reset();
          this.toggleKeychainModal();

          if (this.isYESPAY) {
            this.getVirtualCard();
          } else {
            this.fetchUserCardDetails();
          }
        }
      }, (err: any) => {
        this.isLoading = false;
      })
    }
  }

  gotoSetPinPage() {

    // Uncomment to check set-pin functionality, else keep the code commented
    // const response = { url: 'https://yespayuat.yesbank.in/physical_card/c34c4b2d-f4ca-43b7-9ddc2d3c5f085c41/set_pin' }
    // const response = { url: 'https://ybl.sodelsolutions.com/physical_card/550d649c-2cff-444c-9603-0f1b461db84f/set_pin' }
    // this.router.navigate(['/set-pin', this.menu.id], { queryParams: response, skipLocationChange: true });

    const reqObj = {} as any;
    if (this.menu && this.menu.ypsorPrepaidProgram) {
      if (this.menu.ypsorPrepaidProgram.programIdentifier) {
        reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier
      }
      if (this.physicalCard && this.physicalCard.kitNumber) reqObj.kitNumber = this.physicalCard.kitNumber;
    }

    if (!this.isSetPinLoading) {
      this.isSetPinLoading = true;
      this.adaptersService.setPin(reqObj).then((response: any) => {
        if (response && response.url && this.menu.ypsorPrepaidProgram) {
          const responseObj = {
            url: response.url,
            cardNumber: !this.isYESPAY ? this.physicalCard.cardNumber : this.giftCard.cardNumber,
            expiryDate: this.physicalCard.expiryYear
          }
          const responseString = responseObj ? JSON.stringify(responseObj) : '';
          this.router.navigate(['/set-pin', this.menu.id], { queryParams: { responseString }, skipLocationChange: true });
        }
        else {
          this.isSetPinLoading = false;
          // this.alertService.error('Unable to fetch the details now. Please try again after some time.', { autoClose: true, title: 'Unable to Fetch' });
        }
      }), (error: any) => {
        this.isSetPinLoading = false;
      };
    }

  }

  getMenu(id: any) {
    this.isPageLoading = true;
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus;
        const isObjectPresent = this.menuList.filter((o: any) => o.id == id);
        if (!isObjectPresent.length) this.goToHome();
        else this.setMenu(this.menuList, id);
      } else {
        this.menuList = [];
      }
      this.isPageLoading = false;
    },
      (err) => {
        this.goToHome();
        this.menuList = [];
        this.isPageLoading = true;
      });
  }

  setMenu(res: any, id: any) {
    if (res && res.length > 0) {
      for (let element of res) {
        if (element.id == id) {
          this.menu = element;
          Globals.setMenu(this.menu);
          this.setIsYesPayFlag();
          break;
        }
      }
    }
  }

  goToHome() {
    this.router.navigate(['/menu']);
  }

  goToCardDetail() {
    this.router.navigate([`/card-detail`, this.menu.id]);
  }

  fetchUserCardDetails() {
    this.isPageLoading = true;
    var data = {
      cardType: this.menu.ypsorPrepaidProgram.cardType,
      programId: this.menu.ypsorPrepaidProgram.programIdentifier
    }
    this.adaptersService.fetchUserCardDetails(data).then((res: any) => {
      this.handleUserCardDetailsResponse(res);
    },
      (err) => {
        this.goToHome();
        this.isPageLoading = false;
      }
    );
  }

  handleUserCardDetailsResponse(res: any) {
    if (res) {
      if (res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
      } else {

        Globals.setUserCardDetails(res);
        Globals.saveVCard(res.virtualCardResponse);
        Globals.savePCard(res.physicalCardResponse);

        if ((this.menu.ypsorPrepaidProgram.cardType === 'VIRTUAL' && res.virtualCardResponse) || (this.menu.ypsorPrepaidProgram.cardType === 'PHYSICAL' && res.physicalCardResponse)) {
          this.initializeData();
        } else {
          this.goToCardDetail();
        }

      }
    } else {
      this.goToHome();
    }
    this.isPageLoading = false;
  }

  displayError(res: any) {
    if (res.responseCode === 'YP999') {
      this.alertService.error('Unable to modify preferences, please try again later.', { autoClose: true });
    } else {
      this.alertService.error(Globals.getErrorMsg(res.responseCode, res.responseMessage), { autoClose: true });
    }
  }

  getVirtualCard() {
    this.isPageLoading = true;
    this.adaptersService.virtualCard({}).then((res: any) => {
      if (res) {
        this.giftCard = res;
        Globals.saveVCard(res);
        if (this.userData && this.userData.isKeyChainUser) {
          this.getPhysicalCard();
        } else {
          this.initializeData();
          Globals.reFreshCards = false;
          this.isPageLoading = false;
        }
      } else {
        this.isPageLoading = false;
      }
    },
      (err) => {
        this.isPageLoading = false;
        this.router.navigate(['/login']);
      });
  }

  getPhysicalCard() {
    this.isPageLoading = true;
    this.adaptersService.physicalCard({}).then((res: any) => {
      if (res) {
        this.physicalCard = res;
        Globals.savePCard(res);
        this.initializeData();
      } else {
        this.goToCardDetail();
      }
      this.isPageLoading = false;
    },
      (err) => {
        this.isPageLoading = false;
        this.router.navigate(['/login']);
      }
    );
  }

  onOtpChange(input: any) {
    this.invalidOtp = false;
    input.value = input.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
    if (this.otpForm.get('otp').value) {
      this.otpForm.get('otp').setValue(input.value);
    } else {
      this.otpForm.get('otp').setValue(null);
    }
    this.otpForm.get('otp').updateValueAndValidity();
  }

  verifyOtpModifyLimit() {
    this.invalidOtp = false;
    this.isVerifyOtpModifyLimitApiLoading = true;
    if (this.otpForm.get('otp').value && this.otpForm.get('otp').value.length != 6) {
      this.isVerifyOtpModifyLimitApiLoading = false;
      return;
    }
    if (!this.txnLimitResponse || (this.txnLimitResponse && !this.txnLimitResponse.otp_ref_number)) {
      console.log('Invalid OTP reference number');
      this.isVerifyOtpModifyLimitApiLoading = false;
      return;
    }
    if (this.otpForm.get('otp').value) {
      const reqObj: any = {
        "otpReferenceNumber": this.txnLimitResponse.otp_ref_number,
        "otp": this.otpForm.get('otp').value,
        "programId": this.menu.ypsorPrepaidProgram.programIdentifier
      };
      this.adaptersService.verifyOtpModifyLimit(reqObj).then((res: any) => {
        if (res) {

          if (res.responseStatus && (res.responseStatus.toLowerCase() === 'failure' || res.responseStatus.toLowerCase() === 'error')) {
            if (res.responseCode === 'R05') {
              this.invalidOtp = true;
            } else if (res.responseCode === 'R29') {
              this.hideOtpScreen(res.responseCode, res.responseMessage);
            } else {
              this.hideOtpScreen(null, 'Unable To process request. Please try again after some time');
            }
          }

          else {
            if (res.code === '00') {
              Globals.reFreshCards = true;
              this.hideOtpScreen(null, null);
              if (!this.isYESPAY) this.fetchUserCardDetails();
              this.toggleMessageModal(true, 'Changes Saved Successfully!', 'Your transaction limit has been updated successfully.');
            }
          }

        }
        this.isVerifyOtpModifyLimitApiLoading = false;
      },
        (err: any) => {
          this.isVerifyOtpModifyLimitApiLoading = false;
          const otpAttempt = (err && err.error && err.error.title && err.error.title.includes("Failed OTP attempts exceeded")) ? 'Maximum OTP attempts exceeded' : '';
          if (otpAttempt) {
            this.displayOtpScreen = true;
            return;
          }
          if (err && err.status === 401) {
            this.initializeForm();
            this.hideOtpScreen(null, null);
          }
        });
    } else {
      this.isVerifyOtpModifyLimitApiLoading = false;
    }
  }

  startCountDown() {
    this.counter = this.counter || 60;
    this.isVerifyOtpModifyLimitApiLoading = false;
    this.interval = setInterval(() => {
      if (this.counter > 0)
        this.counter = this.counter - 1;
      else
        clearInterval(this.interval);
    }, 1000);
  }

  hideOtpScreen(responseCode: any, responseMessage: any) {
    this.otpForm.reset();
    this.displayOtpScreen = false;
    this.isSavePrefLoader = false;
    if (responseMessage) {
      this.alertService.error(Globals.getErrorMsg(responseCode, responseMessage), { autoClose: true });
    }
  }

  toggleMessageModal(action: boolean, title: string, message: string) {
    this.messageModal = action;

    // Open
    if (this.messageModal) {
      this.alertMessage = message;
      this.alertTitle = title;
    }

    // Close
    else {
      this.alertMessage = '';
      this.alertTitle = '';
    }
  }
}


