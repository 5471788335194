<div class="main-panel">
    <div>
        <div class="col-12">
            <alert class="alert-position"></alert>
        </div>
        <app-header title="FAQs" [showMenu]="false" [showBackButton]="true" (onBack)="goBack()">
        </app-header>
        <div class="setting-wrpr terms-wrpr">
            <div class="terms-wrpr-inner">
                <ol style="padding-left: 30px;">
                    <li><b>What is the KYC status and transaction limit on the wallet?</b><br>
                        As per RBI guidelines, the maximum wallet balance that can be maintained in a “Small PPI” is INR 10,000 and INR 2,00,000 in case of Full KYC PPI.
                    </li>
                    <li><b>How do I add funds in my wallet?</b><br>
                        You can click on ‘Add funds’ option on the dashboard. Enter the amount you wish to load and hit submit. You will now see the following options: -
                        <ol type="I" style="padding-left: 10px;">
                            <li>If you are logged in from your mobile device: </li>
                            <ol type="a" style="padding-left: 28px;">
                                <li>
                                    For Android:<br>
                                    Select a PSP app: Select any of the payment applications you are registered on and proceed to load.
                                </li>
                                <li>
                                    For iOS: <br>
                                    Download BHIM YES PAY app: Click on the app icon and download the BHIM YES PAY app.
                                </li>
                            </ol>
                            <li>If you’re logged in from a desktop, scan the QR code displayed to download the BHIM YES PAY app. Install, register and load money in the app wallet.</li>
                        </ol>
                    </li>
                    <li>
                        <b>How do I login to the portal every time?</b><br>
                        Simply enter the OTP sent on your registered mobile number.
                    </li>
                    <li>
                        <b>How do I reset the passcode?</b><br>
                        Passcode can be reset from the YES PAY lite mobile app.
                    </li>
                    <li>
                        <b>What is YES PAY ON-THE-GO keychain?</b><br>
                        YES Bank in partnership with Indian Oil Corporation, is offering a YES PAY ON-THE-GO Contactless payment instrument in the form of a ‘Keychain’ to facilitate contactless payments across merchants in India accepting RuPay-enabled cards.
                    </li>
                    <li>
                        <b>How does contactless technology function?</b><br>
                        Wearable has a chip card with an inbuilt radio frequency antenna. This antenna uses Near Field Communication (NFC) technology to establish a secure connection with contactless reader to transmit payment related data. Hence, wearable need not to be in contact with the reader, a simple tap on the reader will initiate a transaction.
                    </li>
                    <li><b>Why should I use YES PAY ON-THE-GO keychain?</b><br>
                        <ul style="list-style-type: disc; padding-left: 15px;">
                            <li>It provides you a single payment platform for making payments across merchants for everyday needs.</li>
                            <li>You need not have to worry about carrying cash for small value payments. Additionally, you are free from fear of getting fake notes or loss or theft of cash.</li>
                            <li>You can keep a digital trail of your purchases.</li>
                            <li>You don’t need to wait in long queues because contactless transactions are super quick and can be completed in less than a second.</li>
                        </ul>
                    </li>
                    <li>
                        <b>Is my YES PAY ON-THE-GO keychain protected from the threat of frauds?</b><br>
                        Yes, your keychain is as secure as any other card. It contains a highly secure EMV chip, hence it cannot be cloned easily. You need not handover the card to anybody, just tap it for completing the transaction.
                    </li>
                    <li>
                        <b>Where can I purchase this keychain from?</b><br>
                        You can visit any Indian Oil petrol pump to purchase it.
                    </li>
                    <li><b>How much do these keychains cost?</b><br>
                        The cost of keychain will be INR 100 (plus taxes).
                    </li>
                    <li><b>Will I be charged additionally for making contactless payments?</b><br>
                        No. There are no additional charges on a contactless payment.</li>
                    <li><b>How can I link/activate my keychain on the portal?</b><br>
                        Click on the ‘Link Keychain’ option on your dashboard and enter the Kit Number and Validation number (mentioned in the welcome letter handed to you). By linking the keychain on the portal, you can check wallet balance, view the card linked to the keychain and track the transaction history.</li>
                    <li><b>How to use this keychain at petrol pumps?</b><br>
                        It is a contactless form of payment, simply tap the keychain at the payment terminal and the corresponding amount will be deducted from your wallet.<br>
                        You don’t need to enter the PIN for completing contactless payments below INR 5000. Above INR 5000, you can still tap the card to make contactless payments, but PIN entry is mandatory.
                    </li>
                    <li><b>How will I know if the transaction is successful?</b><br>
                        If the transaction is successful, terminal/ device will display the message. Transaction history will also be displayed on the portal.
                    </li>
                    <li><b>I have lost my keychain; how do I prevent it from misuse?</b><br>
                        Click on the “Settings” icon and click on ‘Block Keychain’ toggle. Your keychain will be permanently blocked with immediate effect.
                    </li>
                    <li><b>Can I apply for a replacement of the keychain?</b><br>
                        Once a keychain has been blocked, the option to request a replacement becomes active. Please visit the original dealer/seller to get new kit details.
                    </li>
                    <li><b>I want to temporarily lock my keychain; how do I do that?</b><br>
                        Click on the “Settings” icon and click on ‘Lock Keychain’ toggle, your keychain will be temporarily locked with immediate effect. You will have to unlock the keychain to resume transacting.
                    </li>
                    <li><b>Can I use my keychain to do E-commerce transactions? If yes, How? </b><br>
                        There is a card number associated with the keychain which is basically linked to your virtual wallet. By entering the card details on merchant sites, you can proceed with the transaction.
                     </li>
                    <li><b>Is there any validity for the keychain?</b><br>
                        The validity of the keychain is the same as that mentioned on the card displayed on your dashboard.
                    </li>
                    <li><b>How will the refunds be handled in the event of unsuccessful /rejected or cancelled transaction?</b><br>
                        The money will be refunded into your original account and it will take around 3-5 days to reflect.
                    </li>
                    <li><b>How do I close the wallet?</b><br>
                        You can reach out to YES BANK’s toll free number – 1800 1200 or write an email to
                        <a href="mailto:yestouch@yesbank.in">yestouch@yesbank.in</a>
                    </li>
                    <li><b>If I close my wallet, will the linked virtual/IOCL card also become inactive?</b><br>
                        Yes.
                    </li>
                    <li><b>What are the other wallet and card security features available to me?</b><br>
                        Click on the ‘settings’ icon on the dashboard, there are several options which will help you manage your card and keychain (if linked). There is an ‘i’ icon against each option with information about the feature.
                    </li>
                    <li><b>How do I raise a query / complaint against a keychain?</b><br>
                        For any queries or complaints, you can reach out to YES BANK’s toll free number – 1800 1200 or write an email to <a href="mailto:yestouch@yesbank.in">yestouch@yesbank.in</a>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
