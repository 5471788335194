<div class="main-panel">
	<div>
		<div class="col-12">
	        <alert class="alert-position"></alert>
		</div>
		<app-header title="Terms & Conditions" [showMenu]="false" [showBackButton]="true" (onBack)="goBack()"></app-header>
		<div class="setting-wrpr terms-wrpr">
			<div class="terms-wrpr-inner">
				<div class="roboto-400 text-color-blck mb-20">The terms and conditions specified below for YES PAY Lite Application, constitutes the entire and exclusive contract between the User (“User”, which expression shall, unless repugnant to the meaning or context thereof, also mean and include its successors and assigns) and YES BANK (“YES BANK”, which expression shall, unless repugnant to the meaning or context thereof, also mean and include its successors and assigns). User and YES BANK individually referred as “Party” and collective referred to as the “Parties”.</div>

				<div class="roboto-400 text-color-blck mb-20">No oral or verbal agreement amending, modifying or deleting these terms and conditions are valid unless both the Parties duly confirm and agree to such amendments, modifications and deletions in writing.</div>

				<div class="roboto-400 text-color-blck mb-20">These terms and conditions shall be in addition to and not in derogation of other terms and conditions of any account or any other facility or services offered by YES BANK and/or such other terms and conditions as may be specified by YES BANK, from time to time.</div>				
				<span class="popins-500 text-color-blck terms-ttl">1. Definitions: </span>
				<div class="roboto-400 text-color-gry mtb-10">The following words and phrases shall have the meanings set out herein below in this document unless repugnant to the context:</div>
				<ol>
					<li class="text-color-gry">“Application” refers to “YES Pay Application” which will be in the nature of a Mobile/Facebook application by YES BANK which can be accessed via Facebook or downloaded from iOS App Store or Google Play store for Android mobile operating system or Windows App Store to avail products and services offered by YES BANK through BHIM YES PAY.</li>
					<li class="text-color-gry">"Wallet" shall mean a prepaid payment instrument (“PPIs”) issued by YES BANK to the User.</li>
					<li class="text-color-gry">"Partner Wallets" shall mean a prepaid payment instrument (“PPIs”) issued by various partners of YES BANK to the User.</li>
					<li class="text-color-gry">"Account(s)" shall mean an operative bank account maintained by the User with YES BANK or any other bank account which User may provide YES BANK, for availing the facility which is being offered by YES BANK.</li>
					<li class="text-color-gry">“Account Holder” shall mean a User holding an Account with YES BANK or any other bank, excluding non-resident Indians, current account, corporate account holders and foreign account holders.</li>
					<li class="text-color-gry">”Confidential information” shall refer to any oral, visual or written, tangible or intangible other information that has come to the knowledge of YES BANK in connection with the Facilities and whether marked “confidential” or not or is otherwise to be understood to be confidential, which may relate either to the User, their personal or business information and which may be technical, commercial or financial in nature. Confidential Information shall not include information that has been generally available upon or before its conveyance or information that has otherwise been in possession of Page 1 of 14 the recipient before its conveyance without a confidentiality obligation or which the such party has developed independently not utilizing information or material received from the other party.</li>
					<li class="text-color-gry">"Facilities" shall mean all the facilities offered by YES BANK through the Application from time to time.</li>
					<li class="text-color-gry">"NPCI" shall mean National Payment Corporation of India, under the provisions of the Payment and Settlement Systems Act, 2007, for the purpose of creating a robust Payment & Settlement Infrastructure in India.</li>
					<li class="text-color-gry">"Limits" shall mean the maximum value of such instruments, denominated in INR that shall be issued to any holder, as stipulated by the Reserve Bank of India, from time to time. </li>
					<li class="text-color-gry">"Mobile Number" shall mean the number specified by the User at the time of registration with the Application.</li>
					<li class="text-color-gry">"Mobile Phone" shall mean a smart phone along with a valid SIM card which is owned or used by the User for availing the Facilities from YES BANK.</li>
					<li class="text-color-gry">"Personal information" shall mean any information about the User provided by the User to and/or obtained by YES BANK in relation to or in connection with the Facilities availed or to be availed by the User.</li>
					<li class="text-color-gry">"PPIs" shall mean payment instrument, pursuant to the Master Direction on Issuance and Operation of Prepaid Payment Instruments, dated October 11, 2017, as amended from time to time, that facilitate purchase of goods and services, including financial services, remittance facilities, etc., against the value stored on such instruments.</li>
					<li class="text-color-gry">“Transaction” shall mean the fund transfer service or use of YES PAY LITE Application for payment of various products and services on merchant websites or other Facilities offered by YES BANK through the Application from time to time;</li>
					<li class="text-color-gry">"User" shall mean an Account holder of YES BANK as well as any other person (not being an existing customer of YES BANK or having any business relationship with YES BANK) who has registered for the Application and who is eligible for availing the Facilities.</li>
					<li class="text-color-gry">"UPI" shall mean Unified Payment Interface , an instant real-time money transfer by mobile device/application payment system developed by National Payment Corporation of India, an umbrella organization for operating retail payments and settlement systems in India, under the provisions of the Payment and Settlement Systems Act, 2007, for the purpose of creating a robust Payment & Settlement Infrastructure in India.</li>
					<li class="text-color-gry">"UPI Id" shall mean Virtual Payment Address, issued as part of UPI.</li>
					<li class="text-color-gry">"Virtual Card" shall mean a virtual cards issued by YES BANK within the Application</li>
					<li class="text-color-gry">"Credit Card" shall mean Credit Cards issued by YES BANK, subject to the terms and conditions as mentioned on the YES BANK website.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">2. Eligibility and Usage: </span>
				<ol>
					<li>The Facilities shall be available to all Users located in India, subject to such User having a valid Mobile Number registered with a telecom company within the territory of India and is in a position to authenticate the required credentials, including but not limited to, setting the personalized passcode for availing the Facilities.</li>
					<li>The Facilities shall be made available only to the User satisfying the eligibility criteria as mentioned herein and at the sole discretion of YES BANK. The User agrees and confirms that the Facilities may be discontinued by YES BANK at any time, with or without any prior intimation or information to the User.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">3.	Authorization:</span>
				<ol>
					<li>The User irrevocably and unconditionally authorizes YES BANK to access the User Account(s) and the personal details at anytime, which have been disclosed by the User at the time of registration and authentication of the Application or during course of transaction for availing the Facilities from YES BANK.</li>
					<li>The User irrevocably and unconditionally authorizes YES BANK to send a outward SMS from User’s Mobile Number as a part of UPI registration, pursuant to the applicable Guidelines issued NPCI. </li>
					<li>The User expressly, irrevocably and unconditionally authorizes YES BANK to disclose to any service provider or any other third party, all User information in its possession, as it may be required by YES BANK, without any requirement of a notice (oral or written) or consent of the User.</li>
					<li>The User expressly, irrevocably and unconditionally authorizes YES BANK to record, retain and preserve the User’s details and Transaction details for such time and duration as may be determined at the sole discretion of YES BANK</li>
					<li>The User expressly, irrevocably and unconditionally authorizes YES BANK and/or its agents/representatives to send any message or make calls to the Mobile Number of the User to inform the User about any promotional offers including information regarding any existing products or any new products introduced or launched by YES BANK, from time to time or any greetings, banners or other promotional massages that YES BANK may consider appropriate for the knowledge of the User.</li>
					<li>The User expressly, irrevocably and unconditionally authorizes YES BANK to send any rejection message or to reject any Transaction request, if YES BANK at its sole discretion finds that the request made by the User is not pursuant to the requirements stipulated by YES BANK under the respective Facilities. All decisions made by YES BANK shall be valid and binding upon the User.</li>
					<li>The User irrevocably and unconditionally authorizes YES BANK to debit his Account(s) maintained with YES BANK or any other bank for availing the Facilities being granted or granted by YES BANK.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">4.	Representation & Warranties by the User</span>
				<ol>
					<li>The User understands, agrees and confirms that any condition which constitutes as a pre-requisite to access and avail certain benefits under the Facilities, including but not limited to a Mobile Phone, Data Connection, etc. will be the sole discretion and responsibility of the User.</li>
					<li>The User agrees, confirms and accepts that all records, data, and details generated, maintained and preserved by YES BANK in connection with the Transactions conducted by the User or Facilities availed by the User shall be conclusive proof of the genuineness and accuracy of the transactions.</li>
					<li>The User agrees, confirms and accepts that all records, data, and details generated, maintained and preserved by YES BANK shall be valid, final and binding upon the User, without any claim, dispute or demur, whatsoever.</li>
					<li>The User irrevocably and unconditionally agrees and consents all calls or messages made by the YES BANK and/or its agents/representatives shall not be construed as a breach of the privacy of the User and the User shall not hold YES BANK and/or its agents/representatives liable or responsible for the same in that regard.</li>
					<li>The User expressly agrees and confirms that all request(s) or Transaction(s) for and/or at the request of the User through the Application shall be carried out or processed by YES BANK without any obligation upon YES BANK to verify the authenticity of any such requests or Transaction received from the User.</li>
					<li>YES BANK shall have the option to introduce any new services through this Facility, from time to time, and the User shall be deemed to have expressly authorized YES BANK to register the User for such new services.</li>
					<li>The User shall comply with the applicable Anti-Money Laundering (AML) norms governing funds transfers as stipulated by Reserve Bank of India (”RBI”), from time to time.</li>
					<li>If the User suspects that there is an error or discrepancy in the information supplied to YES BANK, the User shall immediately inform YES BANK, of such errors or discrepancies in writing. YES BANK will endeavor to correct or rectify such discrepancy or error promptly wherever possible on a best effort basis.</li>
					<li>The User accepts that any Transactions emanating from the Mobile Phone or Mobile Number registered by User with YES BANK shall be assumed to have been initiated by the User only and at the User’s free will, consent and sole discretion.</li>
					<li>The User shall be obliged to inform YES BANK, if the User becomes aware of any, unauthorized Transaction in the User’s account.</li>
					<li>The User agrees and confirms that by usage of Facilities, the User shall be deemed to have agreed to all the terms and conditions mentioned herein and all such terms and conditions stipulated by YES BANK, from time to time and shall be binding on the User in the same manner as if the User has agreed to the same in writing.</li>
					<li>For the all purposes under the Facilities, the User shall take all necessary precautions to prevent unauthorized and illegal use of Application offered through the Facilities.</li>
					<li>The User shall be responsible for maintaining the confidentiality of Passcode/ OTP/Code/password and for all the consequences which may arise due to use or misuse of such Passcode/OTP/Passcode/password.</li>
					<li>The User shall not, while using the Facilities upload, download, post or otherwise transmit any content that is unlawful, harmful, threatening, abusive, vulgar, harassing, defamatory, obscene, pornographic, profane, indecent, inflammatory, libelous, tortious, hateful, racially, ethnically, socially, politically, legally, morally, religiously objectionable or otherwise objectionable, or invade another's rights, including but not limited to rights of celebrity, or their privacy or intellectual property.</li>
					<li>The User agrees, confirms and accepts that YES BANK may keep records of all the Transactions, in any form or manner at the sole discretion of YES BANK. In the event of any dispute, YES BANK's records shall be binding and a conclusive evidence of such Transactions carried out through the Application.</li>
					<li>The User shall not to use or access the Application in any manner other than as authorized by YES BANK. In case the User uses the Application for any other purposes or if such purposes are illegal, improper or which is not authorized under these terms & conditions then YES BANK has a right to take all reasonable measures or steps in order to prevent such unauthorized access by the User.</li>
					<li>The User agrees and confirms that the Application may require maintenance from time to time and during such time it may not be possible to process the request or Transaction of the Users.</li>
					<li>The User takes full responsibility for the correctness of the information supplied by the User to YES BANK as a condition precedent for the purpose of using the Facilities being offered or granted by YES BANK to the User.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">5.	Liability of the User:</span>
				<ol>
					<li>The User shall be solely liable and responsible for the accuracy or correctness of all or any information provided by the User to YES BANK.</li>
					<li>The User shall be solely liable and responsible for all the fund transfers to the intended or correct beneficiary through the Application or through any of the merchant operators/service providers of YES BANK.</li>
					<li>The User shall be solely liable and responsible for any discrepancy or error found in the information provided by the User to YES BANK for availing the Facilities offered through the Application.</li>
					<li>The User shall be solely liable and responsible for protecting his Mobile Phone, Mobile Number and Passcode required for the use of the Facilities granted or to be granted by YES BANK.</li>
					<li>The User shall be solely liable and responsible to YES BANK for any unauthorized or unlawful use of Passcode/passwords or the Application or any fraudulent or erroneous instruction given to YES BANK</li>
					<li>The User shall be solely liable and responsible to YES BANK for all or any losses caused due to any act or omission on the part of the User and the User agrees to immediately notify YES BANK about any such unauthorized use/access made on his behalf in the Application or misuse of OTP/Passcode/password or any other breach of security regarding the Facilities of which he has knowledge or believes it to be true.</li>
					<li>The User shall be solely liable and responsible for any request made to YES BANK, including but not limited to suspension all the Facilities in the event of any change in the Mobile Number of the User or if the User’s Mobile Phone has been lost or if the User’s Mobile Phone has been allotted or assigned to any other third party.</li>
					<li>The User shall be irrevocably and unconditionally liable to YES BANK for all loss (actual and contingent) or damage or claim or demand, if the User breaches the Terms and Conditions contained herein, including any changes, amendment or modifications made by YES BANK, from time to time.</li>
					<li>The User acknowledges and agrees that the Application offered by YES BANK shall be at the User’s own risk and responsibility and such risks shall include (i) any technical error, failure, glitch, network failure, legal restraints and other reasons which is beyond the control of YES Bank; (ii) any information provided by the User turns out to be wrong/incorrect/inaccurate; (iii) for the performance of any service provider/other third party/entity involved in the process; (iv) for any error, defect, failure or interruption of the service or consequences arising out of delayed fund transfer; and (v) any delay in transmission delivery or non-delivery of online/electronic instructions or any mistake, omission or error in transmission or delivery thereof or in decrypting the instructions from any cause whatsoever or any act or even beyond control of YES BANK.</li>
					<li>Customer limited liability in case of unauthorized/fraudulent transactions involving YES PAY Wallet will be guided by YES BANK Policy: <span class="word-break">https://www.yesbank.in/pdf/customer_protection_policy_21nov17_pdf</span></li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">6.	Exclusion of Liability of YES BANK:</span>
				<ol>
					<li>The User further understands, agrees and confirms that YES BANK shall not bear any responsibility for any transaction, whatsoever, except as otherwise expressly provided herein, which is entered into between the User and other merchant operators/service providers.</li>
					<li>YES BANK shall make all reasonable efforts to ensure that the User’s information is kept confidential. However, YES Bank shall not be responsible or liable, in manner whatsoever for any divulgence or leakage of such information, irrespective of any act, omission or conduct by YES BANK and/or its employees or agents or representatives.</li>
					<li>YES BANK shall not be responsible or liable to any third persons or parties invited by the User, in connection with the Application, unless such third person parties are registered as a User on the Application.</li>
					<li>The User confirms and agrees that that Facilities offered by YES Bank over the Application could be affected by virus or other malicious, destructive or corrupting code and program and YES BANK shall not be liable or responsible for the same in any manner, whatsoever.</li>
					<li>YES BANK will not be liable for any delays in the processing of instructions or failure in the processing of instructions on account of any maintenance of the Application being conducted by YES BANK.</li>
					<li>YES BANK disclaims all and any liability, whether direct or indirect, whether arising out of loss or otherwise arising out of any failure or inability by YES BANK to honour any User instruction for any reason whatsoever.</li>
					<li>YES BANK shall not be responsible or liable for any breach of confidentiality of the User, on account of the User using such an Application.</li>
					<li>=The User agrees that YES BANK shall assume no responsibility or liability in respect of: (i) Any Transactions carried out by YES BANK upon relying on the User’s instructions in good faith; (ii) Not carrying out transactions where YES BANK has reason to believe in its sole discretion that the instructions given by the User is not genuine or are otherwise unclear, improper, vague or doubtful or contrary to law; (iii) For any loss or damage incurred or suffered by User for any error, defect, failure or interruption of the service or consequences arising out of delayed transfer/remittance and for any reason which is beyond control of YES BANK; (iv) Any unauthorized access by any third party to the information/instructions given by User to third party using the Facilities; (v) For any direct, indirect or consequential damages occurred to User while availing the Facilities.</li>
					<li>The User agrees that YES BANK shall assume no responsibility or liability in respect of any loss, damage, liability caused or suffered by User due to disclosure of all information of confidential nature.</li>
					<li>YES BANK shall not be responsible for any incidental error which occurs or may occur in spite of necessary steps taken by YES BANK to ensure the accuracy of the information provided by the User and the User shall not have any claim against YES BANK in the event of any loss or damage suffered to be suffered by the User as a consequence of the inaccurate information provided by YES BANK.</li>
					<li>YES BANK shall not liable for any deficient or bad services in any manner whatsoever, or for any loss, whatsoever that User may suffer.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">7.	Terms of Service:</span>
				<ol>
					<li>The Facilities will be provided by YES BANK only upon the specific request of the User through the Application.</li>
					<li>The User will be required to register his details as required by YES BANK for successful completion of the registration for the Application provided by or being provided by YES BANK.</li>
					<li>The User shall be required to register his details for each of the features or facilities as available on the Application provided or proposed to be provided by YES BANK, from time to time.</li>
					<li>As pre-condition for availing and/or activating the Wallet, the User shall be required to provide Aadhaar Number issued by UIDAI (Unique Identification Authority of India) or PAN (issued by NSDL) or Customer ID issued to YES BANK Account Holders which will be validated by One Time password (OTP). After successful validation of Aadhaar Number or PAN or Cust ID the demographic details such as Name, Address etc. registered against Aadhaar or PAN or Cust ID will be considered for registration of wallet. For existing Wallet Users, who does not or fail to validate their Aadhaar Number or YES BANK Customer ID (as issued by YES BANK) within 1 (one) year from the date of issuance of the Wallet, YES BANK shall have the sole right and discretion to disable and cancel such Wallets, without any further notice to the User or any kind of risk, responsibility or liability on behalf of YES Bank.</li>
					<li>The User unconditionally agrees and confirms that the usage of the Wallet shall be subject to the following conditions : (a) On successful registration of the User, default Limits as permitted by Reserve Bank of India (RBI), from time to time shall be applicable to all Wallets. These Limits shall be cumulative Limits applicable across all Partner Wallets. (b) For fund transfer from Wallet to the User’s bank account, the User will be required to add bank accounts details as beneficiary in the Application. YES BANK can introduce a cooling period (In hours: minutes) after which transfer to Accounts to beneficiary can be enabled. YES BANK can also introduce a cap on maximum beneficiaries that can be added by a particular user. (c) Full KYC (Know Your Customer) process as mandated by RBI shall be completed by the User within 1 (one) year from the date of activation and/or availing the Wallet.. In case of any non-compliance or non-completion of KYC process, YES BANK YES BANK shall have the sole right and discretion to disable and cancel such Wallets, without any further notice to the User or any kind of risk, responsibility or liability on behalf of YES Bank. All credits, debits or transfer from or into the Wallet will be allowed only upon completion of KYC process to the satisfaction of YES BANK. The decision of YES BANK in this regard shall be final and binding upon the User.</li>
					<li>The User agrees and confirms that virtual card will be governed by the Virtual card terms and Conditions available and updated on website www.yesbank.in from time to time and balance against the virtual card will be equal to the balance in the wallet.</li>
					<li>The User confirms that any instructions given by the User to YES BANK shall be effected only after validation of OTP/Passcode/Password/Fingerprint by the User for availing the Facilities.</li>
					<li>The User agrees and confirms that, once the Transaction is materialized, any stop- payment instructions given by the User will not be accepted and/or acted upon by YES BANK.</li>
					<li>The User agrees and confirms that allocation of particular UPI Id is subject to availability of particular UPI Id and at the sole discretion of YES Bank. The User acknowledges that YES BANK shall not  allow any duplication of UPI Id or same UPI Id being allotted to multiple Users.</li>
					<li>The User shall while utilizing the facility ensure that: (i) The User has authority to access and avail the Facilities; (ii) The User has complied with the applicable laws and regulations prevailing in India in connection with such Facilities; (iii) The User has provided YES BANK with all such information and/or assistance as is required by YES BANK for provide the Facilities to the User; (iv) The User shall not at any time provide to any person the details of the Accounts held by him with YES BANK or any other bank including the passwords, account number which are allotted, from time to time.</li>
					<li>The User acknowledges and agrees that YES BANK remains a mere facilitator for the Facilities and YES BANK does not warrant or claim any responsibility for the Facilities nor does YES BANK endorse any such Facilities or the standing or reputation of thee third party merchants whatsoever.</li>
					<li>The User acknowledges and agrees that, any or all charges for the Facilities offered by YES BANK shall be at the sole discretion of YES BANK. YES BANK shall be at the liberty to withdraw or modify or vary the same from time to time, without giving any notice or intimation to the User. </li>
					<li>The User acknowledges and agrees that, if his bank account is closed or blocked due to usage or in connection with the usage of the Facilities, for any reason whatsoever, the User shall settle the issue directly with his bank and shall not hold YES BANK liable or responsible for the same. Any refund of monies made to the User by YES BANK will not contain or bear any interest, as the Application is an interest free facility provided by YES BANK to the User.</li>
					<li>The User acknowledges and agrees that (i) no interest will be paid by YES BANK on the balance maintained in the YES PAY LITE wallet or Application used or maintained by the User; (ii) No incentive monetary or otherwise will be paid by YES BANK for any referrals by user about the Application and YES BANK will not be responsible for any complaints received against any such referrals; (iii) no interest will be paid by YES BANK on the amount deducted against Goal set by the user; (iv) All the scheduled transactions will be executed at 12 a.m. IST on due date provided that there is sufficient balance to execute the same.</li>
					<li>The User acknowledges and agrees that in case the User tries to load the money on the Application by modes other than communicated by YES BANK, the amount or monies may not be loaded in the Application and YES BANK will refund such amount or monies within 21 working days from the date when such amount or monies are received by YES BANK.</li>
					<li>The User acknowledges and agrees that in case the User’s name appears in the negative list published by Regulators or Government, then YES BANK may take any action as deemed fit, including but not limited to suspension or termination of the Application granted to or availed by such User.</li>
					<li>The User acknowledges and agrees that in case the User does not complete a financial transaction using the Wallet for a continuous period one (01) year, all credit transactions in the User’s Wallet(loading of wallet) shall be restricted at the sole discretion of YES BANK. The wallet will be reactivated only after completion of validation and due diligence process as defined by the bank from time to time.. The User shall remain responsible for any and all the transactions made through the Application provided by YES BANK. The User acknowledges and agrees that YES BANK may withdraw or terminate the Facilities at anytime or in case of breach of terms and conditions, as mentioned herein by the User without any prior notice, whatsoever or if YES BANK learns of demise, bankruptcy or lack of legal capacity of the User or for any reason whatsoever</li>
					<li>The User shall remain responsible for any and all the transactions made through the Application provided by YES BANK. The User acknowledges and agrees that YES BANK may withdraw or terminate the Facilities at anytime or in case of breach of terms and conditions, as mentioned herein by the User without any prior notice, whatsoever or if YES BANK learns of demise, bankruptcy or lack of legal capacity of the User or for any reason whatsoever.</li>
					<li>The User acknowledges and agrees that YES BANK may provide the Facilities through this Application, directly or through its associates, representatives, agents or contracted service providers on its behalf.</li>
					<li>The User acknowledges, agrees and accepts that in case any discrepancy or inconsistency is found or revealed in the information provided by the User to YES BANK with regard to the Facilities, the complete onus, liability or responsibility of such information will rest with the User. The User acknowledges, agrees and confirms to furnish accurate and correct information at all times to YES BANK in connection with the Facilities. If the User suspects that there is or was an error or discrepancy in the information supplied to YES Bank, the User shall inform or notify YES Bank immediately. Upon receipt of such information or notification, YES BANK will endeavor to correct the error promptly wherever possible on a best effort basis.</li>
					<li>The User acknowledges, agrees and accepts that if the User does not log in to the YES PAY LITE App for a period of one (1) year, the YES PAY points, if any, accrued by the User will automatically expire at the end of the calendar day of one year, without any further requirement of notice or intimation (in any form or manner) by YES Bank to the User.</li>
					<li>The User acknowledges, agrees and accepts that once the YES PAY points expire, as mentioned above, all the YES PAY points stands forfeited, without any possibility of reinstatement, renewal or rollover. All or any such requests or demands by the User will not be entertained by YES Bank. The User further acknowledges, agrees and accepts that YES Bank shall, under no circumstances, be liable or responsible due to the expiry or forfeiture of the YES PAY points after a lapse of one (1) year.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">8.	Termination:</span>
				<ol>
					<li>YES BANK may, at its sole discretion, withdraw temporarily or terminate the Facilities, either wholly or in part, at any time without giving prior notice to the User if the User has breached the terms and Conditions as mentioned herein or if YES BANK learns of the death, bankruptcy or lack of legal capacity of the User.</li>
					<li>The User acknowledges and agrees that the closure of the Application of the User will automatically terminate the Facilities. The User can voluntarily choose to close their Wallet. In the event of a voluntary closure of the Wallet, the balance amount in the Wallet will be transferred to a verified (basis verification conducted by YES BANK) bank account of the User only. The User unconditionally agrees to provide the bank account details (Account Number and IFSC Code) to enable the Bank to verify the account details maintained with YES BANK or any other bank established and operating in India.</li>
					<li>The User acknowledges and agrees that upon closure request from the User, YES BANK shall be entitled to debit a specified amount (being determined at the sole discretion of the Bank communicated to the User at the time of such request) before the actual amount is transferred to the bank account of the User. The User unconditionally and irrevocably agrees to abide by the verification process stipulated by YES BANK before the actual funds are transmitted and/or transferred to the bank account of the User.</li>
					<li>Except as otherwise provided by the applicable law or regulation, YES BANK reserves the sole right to terminate the Facilities and/or expand, reduce or suspend any Transactions allowed using this Facilities or change the process and transaction Limits associated with this Facilities based on security issues, at any time, without any prior notice to the User</li>
					<li>5.	YES BANK may, at its sole discretion, withdraw temporarily the Facilities, either wholly or in part, at any time without giving prior notice and without any reasoning to the User.</li>
					<li>YES BANK may, without prior notice, suspend the Facilities at any time during for the purpose of any maintenance work or repair may be required to be carried out or in case of any emergency or for security reasons, which require the suspension or termination of the Facility.</li>
				</ol>

				<span class="popins-500 text-color-blck terms-ttl mb-10">9.	Indemnity:</span>
				<ol>
					<li>In consideration of YES BANK agreeing to provide the Facilities to the User, the User shall, at his own expense, irrevocably and unconditionally agrees to indemnify, defend and hold harmless YES BANK and its directors, officers, owners, agents, co-branders or other partners, employees, information providers, licensors, licensees, consultants, contractors and other applicable third parties (collectively "Indemnified Parties") from and against any and all losses, damages, claims, demands, causes of action, debt or liability, including reasonable attorney's fees, and costs incurred or suffered or likely to suffer by the Indemnified Parties arising out of, related to, or which may arise from: (i) any breach or non-compliance by the User of any of the Terms and Conditions, mentioned herein or any other additional terms & conditions and policies stipulated by YES BANK, from time to time; (ii) any claim, demand, dispute or litigation against the Indemnified Parties caused due to the actions or omissions of the User arising out of or in connection with the use of the Facilities; and (iii) any negligence or violation or alleged violation of any law or rights of a third party by the User.</li>
					<li>The User agrees and confirms that this indemnity shall remain valid and subsisting and binding upon the User, notwithstanding partial or full withdrawal of the Facilities by YES BANK</li>
					<li>The User agrees and confirms that this indemnity is a continuing indemnity and shall remain valid and subsisting and binding upon the User, notwithstanding the termination or expiration of the Facilities.</li>
					<li>The User agrees and acknowledges to pay the Indemnifies Parties such amount as may be determined by the Indemnified Parties to be sufficient to indemnify it against any such loss, cost, and claim or expenses even though they may not have arisen or are contingent in nature.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">10. Confidentiality:</span>
				<ol>
					<li>To the extent not prohibited by applicable law, the YES BANK shall be entitled to transfer any Confidential Information relating to the User or any other information given by the User for utilizing or availing of the Facilities, to and between its branches, representative offices, affiliates, representatives, auditors and third parties selected by YES BANK, wherever situated.</li>
					<li>YES BANK shall be entitled at any time to disclose any and all information concerning the User within the knowledge and possession of YES BANK to any other bank or association or financial institution or any other body, as it deems fit.</li>
					<li>This clause will survive the termination or expiration of the Facilities granted by YES BANK to the User.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">11. Disclaimers:</span>
				<ol>
					<li>YES BANK shall be absolved of any or all liability in case: (i) The User fails to avail the Facilities due to force majeure conditions including but not limited to not being in the required geographical range or any other reason including natural calamities or any legal restraints or any technical lapses in the telecommunication network or any other reasons beyond the actual control of YES BANK; (ii) Any kind of liability arising due to a loss; direct or indirect incurred by the User or any other person due to any lapses in the Facilities owing to the above-mentioned reasons; (iii) Any loss of any Confidential Information during processing or transmission or any unauthorized access by any other person or breach of Confidentiality; (iv) There is any lapse or failure on the part of the service providers or any third party affecting the Facilities.</li>
					<li>YES BANK makes no warranty, either express or implied, as to the quality of the service provided by any such service provider or any third party, in connection with or arising out of the Facilities.</li>
					<li>YES Bank does not warrant the confidentiality or security of the messages or notifications whether personal or otherwise transmitted through the Application in respect of the Facilities.</li>
					<li>YES BANK makes no warranty or representation of any kind in relation to the system and the network or their function or performance or for any loss or damage whenever and howsoever suffered or incurred by the User or by any person resulting from or in connection with the Facilities.</li>
					<li>YES BANK and/or its directors and employees, agent or contractors, shall not be liable for and in respect of any loss or damage whether direct, indirect or consequential, including but not limited to loss of revenue, profit, business, contracts, anticipated savings or goodwill, loss of use or value of any equipment including software, whether foreseeable or not, suffered by the User or any person howsoever arising from or relating to any delay, interruption, suspension, resolution or error of YES BANK in receiving and processing the request and in formulating and returning responses or any failure, delay, interruption, suspension, restriction, or error in transmission of any information or message to and from the telecommunication equipment of the User and the network of any service provider and YES Bank's system or any breakdown, interruption, suspension or failure of the telecommunication equipment of the User, YES BANK's system or the network of any service provider and/or any third party who provides such services as is necessary to provide the Facilities.</li>
					<li>Notwithstanding anything in the contrary provided in the Terms and Conditions mentioned herein, YES BANK shall not be involved in or in any way liable to the User for any dispute between the User and a cellular services provider or any third party service provider (whether appointed by YES BANK in that behalf or otherwise).</li>
					<li>YES BANK shall not be held responsible for the confidentiality, secrecy and security of the personal or account information being sent through the facility for effecting the User's instructions.</li>
					<li>YES BANK shall not be held liable for any loss suffered by the user due to disclosure of the personal information to a third party by YES BANK, for reasons inclusive but not limited to participation in any telecommunication or electronic clearing network, in compliance with a legal directive, for statistical analysis or for credit rating.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">12. Sub-Contracting:</span>
				<ol>
					<li>YES BANK may sub contract the performance of its obligations under the Facilities to any third party or independent contractor without any notice or intimation or consent of the User.</li>
					<li>Nothing in this Terms and Conditions shall be construed to create any contractual relationship between YES BANK and the sub-contractor or independent service provider, nor is there any obligation on the part of YES BANK to pay any money due from the sub- contractor or independent service provider.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">13. Severability:</span>
				<ol>
					<li>If any term or provision of this document is declared invalid by a Court of competent jurisdiction, the remaining terms and provisions of this Purchase Order shall remain unimpaired and in full force and effect</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">14. Third Party Beneficiaries:</span>
				<ol>
					<li>Except as specifically set forth or referred to herein, nothing contained or implied herein is intended or shall be construed to convey any rights upon any person or entity other than the User.</li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">15. Fees and Customer Support:</span>
				<ol>
					<li>YES BANK shall charge fees to the user when user transfers any amount from YES PAY LITEWallet to any non YES BANK Account. The Fees shall be 2.5% of the transfer amount. YES BANK reserves the right to amend and modify the fees charged to users.</li>
					<li>For more information related to YES PAY Wallet users can visit www.yesbank.in</li>
					<li>For any queries/complaints, users can reach out to YES BANK’s Toll Free No-1800 2000 or write an email to yestouch@yesbank.in</li>
					<li>In case resolution provided does not meet your expectations, user can approach Grievance Redressal Officer at head.grievanceredressal@yesbank.in. If resolution provided by Grievance Redressal Officer is not satisfactory user can approach Principal Nodal Officer at principal.nodalofficer@yesbank.in</li>
					<li>5.	The User is hereby informed that YES BANK is covered under the Banking Ombudsman Scheme, 2006 of the Reserve Bank of India. Under this scheme, any grievance against the Bank, if not addressed within 30 days can be addressed to the Banking Ombudsman of the concerned city. Please follow the below link for contact details:<span class="word-break">https://www.rbi.org.in/Scripts/bs_viewcontent.aspx?Id=164</span></li>
				</ol>
				<span class="popins-500 text-color-blck terms-ttl mb-10">16. Governing law and Jurisdiction:</span>
				<ol>
					<li>All Terms and Conditions mentioned herein and amended or modified by YES BANK from time to time, will be construed in accordance with and all disputes will be governed under the laws of India.</li>
					<li>Any or all disputes, controversy or claim arising out of or in connection with All Terms and Conditions mentioned herein and amended or modified by YES BANK from time to time, including its breach, termination or validity thereof will be submitted to the exclusive jurisdiction of the Courts at Mumbai.</li>
				</ol>
				<div class="roboto-400 text-color-blck mb-20">The User has read and understood the implication of the above, Terms and Conditions and has agreed to avail the Facilities through the Application, out of his free will, informed consent and volition.</div>	
				<div class="roboto-400 text-color-blck mb-20">To read the Terms & Conditions in regional languages, please visit the Documents Section on: <span class="word-break">https://www.yesbank.in/digital-banking/yes-pay.</span></div>	
			</div>
		</div>
	</div>
</div>

