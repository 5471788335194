<div class="main-panel">
  <div>
    <div class="col-12">
      <alert class="alert-position"></alert>
    </div>
    <app-header title="{{showQrPage ? 'QR Code Generation' : showTransactionResult ? 'Transaction Details' : 'Add Funds'}}" [showMenu]="false" showBackButton="true" (onBack)="previousState()"></app-header>

    <div class="payment-wrpr" *ngIf="showQrPage">
      <div class="payment-head">
        <div class="payment-head-qr" *ngIf="userData">
          <span>Adding money to Wallet</span>
          <span>{{userData.fullName}}</span>
        </div>
        <div class="payment-head-qr-amt" *ngIf="showQrPage"><span class="rupee-symbol">₹
          </span>{{amountForm.get('amount').value | number}}</div>
      </div>
      <div class="qr-img-wrpr">
        <qr-code class="d-flex justify-content-center" value="{{qrString}}" size="25vh" margin="0" centerImageSrc="undefined" centerImageSize="undefined" errorCorrectionLevel="H"></qr-code>
      </div>
      <div class="qr-txt">This QR code can be scanned from any payment app to load the wallet.</div>
      <div class="upi-img upi-img-btn-90">
        <img src="../../assets/img/upi.svg">
      </div>
      <div class="add-funds-btm-btn" (click)="previousState()">Cancel Transaction</div>
    </div>

    <div class="payment-wrpr payment-wrpr-no-head" *ngIf="showTransactionResult">
      <div class="status-img-wrpr">
        <img *ngIf="txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'SUCCESS'" src="../../assets/img/success.svg">
        <img *ngIf="!txnStatusList.includes(txnData?.txnStatus) || (txnData?.txnStatus == 'SUCCESS' && txnStatusInProcessList.includes(txnData?.sorTxnStatus?.toUpperCase()))" src="../../assets/img/pending.svg">
        <img *ngIf="txnData?.txnStatus == 'FAIL' || (txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'FAILURE')" src="../../assets/img/error.svg">
      </div>

      <div class="transfer-amt" *ngIf="showTransactionResult"><span class="rupee-symbol">₹</span>{{amountForm.get('amount').value | number}}</div>

      <div *ngIf="txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'SUCCESS'" class="transfer-lbl">Added To Wallet</div>
      <div *ngIf="!txnStatusList.includes(txnData?.txnStatus) || (txnData?.txnStatus == 'SUCCESS' && (txnStatusInProcessList.includes(txnData?.sorTxnStatus?.toUpperCase())))" class="transfer-lbl">Adding To Wallet</div>
      <div *ngIf="txnData?.txnStatus == 'FAIL' || (txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'FAILURE')" class="transfer-lbl">Wallet Recharge Failed</div>

      <div class="transfer-sts-dt">{{(txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'SUCCESS') ?
        'Successful' : ((!txnStatusList.includes(txnData?.txnStatus) || (txnData?.txnStatus == 'SUCCESS' &&
        (txnStatusInProcessList.includes(txnData?.sorTxnStatus?.toUpperCase())))) ? 'Transaction in Process' :
        'Failure')}} • {{txnDate | date:'dd MMM yyyy, hh:mm aaa'}}</div>

      <div class="transfer-info">
        <div *ngIf="txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'SUCCESS'" class="transfer-info-lst">
          <div class="transfer-info-label">Transaction ID</div>
          <div class="transfer-info-ids">
            <span style="overflow: hidden;">{{merchantRequestId}}</span>
            <img (click)="copyText(merchantRequestId)" src="../../assets/img/copy-red.svg">
          </div>
        </div>
        <div class="transfer-info-lst">
          <div class="transfer-info-label">UPI Ref. No.</div>
          <div class="transfer-info-ids">
            <span>{{gatewayTransactionId}}</span>
            <img (click)="copyText(gatewayTransactionId)" src="../../assets/img/copy-red.svg">
          </div>
        </div>
        <div class="transfer-info-lst">
          <div class="transfer-info-label">Paid by</div>
          <div class="transfer-info-ids">
            <span>UPI</span>
          </div>
        </div>
        <div *ngIf="txnData?.txnStatus == 'SUCCESS' && txnData?.sorTxnStatus == 'SUCCESS'" class="transfer-info-lst">
          <div class="transfer-info-label">Current Balance:</div>
          <div class="transfer-info-ids">
            <span><span class="rupee-symbol">₹ </span>{{userData ? (userData.walletBalance | number) : 0}}</span>
          </div>
        </div>
      </div>
      <div class="contact-info">
        For any queries/complaints, you can reach out to YES BANK's Toll Free No. <a href="tel:1800 2000">1800 2000</a> or write an email to <a href="mailto:yestouch@yesbank.in">yestouch@yesbank.in</a>
      </div>
      <div class="upi-img upi-img-btn-70">
        <img src="../../assets/img/upi.svg">
      </div>
      <div class="add-funds-btm-btn" (click)="goPrevious()">Done</div>
    </div>

    <div class="payment-wrpr" *ngIf="showAmountPage">
      <div class="payment-head">
        <div class="row">
          <div class="col-6" *ngIf="userData">
            <div class="payment-lable" *ngIf="userData">Current Balance</div>
            <div class="payment-amt"><span class="rupee-symbol" *ngIf="userData">₹ </span>
              <span *ngIf="isYESPAY && userData">{{userData?.walletBalance || 0}}</span>
              <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'">{{physicalCardObj?.walletBalance || 0}}</span>
              <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'">{{virtualCardObj?.walletBalance || 0}}</span>
            </div>
          </div>
          <div class="col-6" *ngIf="userData">
            <div class="payment-lable" *ngIf="userData">Available Limit</div>
            <div class="payment-amt"><span class="rupee-symbol" *ngIf="userData">₹ </span>
              <span *ngIf="userData">{{userData?.remainingWalletBalanceLimit || 0}}</span>
              <!-- <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'">{{physicalCardObj?.remainingWalletBalanceLimit || 0}}</span>
              <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'">{{virtualCardObj?.remainingWalletBalanceLimit || 0}}</span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="login-wrpr add-funds" *ngIf="isYESPAY || isYESPAY && menu?.menuIdentifier !== 'CHALO'">
        <form [formGroup]="amountForm">

          <mat-form-field class="form-group example-full-width mb-2">
            <mat-label>Enter Amount</mat-label>
            <span class="rupee-symbol" matPrefix>₹&nbsp;</span>
            <input name="amount" formControlName="amount" matInput type="tel" class="form-control" autocomplete="off" (input)="amountChange($event.target)" (paste)="$event.preventDefault()" #amountInput>
            <mat-error *ngIf="amountForm.get('amount').errors && amountForm.get('amount').errors.max">Amount Cannot be greater than remaining balance limit</mat-error>
          </mat-form-field>

          <div class="text-center ot-none" *ngIf="isMobile && !qrString">
            <button mat-stroked-button color="accent" class="my-4" [ngClass]="{'disabled-btn':!amountForm.get('amount').value}" [disabled]="amountForm.invalid || isSaving" (click)="getUpiIntent('apps')">
              Proceed
              <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
                <span class="sr-only"></span>
              </span>
            </button>
          </div>

        </form>
      </div>

      <div>
        <ng-conainer *ngIf="isMobile && qrString"><a [href]="sanitize()" (click)="checkTxnStatus('APPS')" class="qr-link upi-app upi-pay1">UPI Apps</a></ng-conainer>
        <div class="qr-link" *ngIf="!isMobile" (click)="getUpiIntent('QR')">Generate QR Code
          <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
            <span class="sr-only"></span>
          </span>
        </div>
      </div>

      <!-- Chalo Card Info -->
      <!-- <div class="chalo-card-info" *ngIf="!isYESPAY && isYESPAY === false && menu?.menuIdentifier === 'CHALO'">
        <div class="chalo-best-card-container">
          <div class="chalo-best-card-title">
            <img class="chalo-best-card-title-img" src="../../assets/img/chalo-best-card-title-img.svg">
            <span class="chalo-best-card-title-text">
              How to load your Chalo Best Card using this wallet?
            </span>
          </div>
          <div class="chalo-best-card-body">
            <ol type="1" class="chalo-info">
              <li>
                <div>
                  <span class="load-this-chalo">
                    <span class="mw75p-pr10px">Load this Chalo wallet using your UPI app.</span>
                    <img class="mr20px" src="../../assets/img/chalo-info-1.svg">
                  </span>
                  <div class="chalo-best-card-partition"></div>
                </div>
              </li>
              <li>
                <div class="mw75p-mb5px">Now to transfer intended amount to your Chalo Card follow these steps:</div>
                <ul style="list-style-type:disc; padding-left:12px;">
                  <li>
                    <span class="chalo-info-bullets">
                      <span class="mw75p-pr10px">Tap your Chalo Card twice on the POS machine with the bus conductor.</span>
                      <img class="mr20px" src="../../assets/img/chalo-info-2.svg">
                    </span>
                  </li>
                  <li>
                    <span class="chalo-info-bullets">
                      <span class="mw75p-pr10px">Enter the amount which you want to transfer to the card.</span>
                      <img class="mr20px" src="../../assets/img/chalo-info-3.svg">
                    </span>
                  </li>
                  <li>
                    <span class="chalo-info-bullets">
                      <span class="mw75p-pr10px">Your Chalo Card will be loaded with that amount.</span>
                      <img class="mr5px" src="../../assets/img/chalo-info-4.svg">
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      </div> -->

      <div class="chalo-pg-wrpr" *ngIf="!isYESPAY && isYESPAY === false && menu?.menuIdentifier !== 'CHALO'">
        <div class="bg-blue-wrpr">
          <div class="upi-info-wrpr">
            <div class="upi-info-icon"><img src="../../assets/img/android.svg"></div>
            <div class="upi-info-dcrb">If you're an Android Mobile User, here's how you can reload your wallet. Please login on the portal through your mobile and follow below steps. <br>
              <a href="https://yplite.yespayhub.in">https://yplite.yespayhub.in</a></div>
          </div>
          <div class="upi-info-image-line"></div>
          <div class="upi-info-image">
            <img src="../../assets/img/upi-info.svg">
          </div>
        </div>
        <div class="bg-blue-wrpr">
          <div class="upi-info-wrpr">
            <div class="upi-info-icon"><img src="../../assets/img/ios.svg"></div>
            <div class="upi-info-dcrb">If you're an iOS Mobile User, please visit your nearest bus conductor/terminal to load the funds in the wallet.</div>
          </div>
        </div>
        <div class="chalo-footer">
          <div class="upi-img-wrpr">
            <img src="../assets/img/pwrd-upi.svg">
          </div>
          <div class="back-btn-footer">
            Back
          </div>
        </div>
      </div>
    </div>

    <div class="settings-wrpr terms-wrpr" *ngIf="downloadApp && menu?.menuIdentifier !== 'CHALO'">
      <ul style="padding-left: 30px; margin-bottom: 40px;">
        <b>To add funds, scan the QR code below and download the BHIM YES PAY App.</b>
      </ul>
      <qr-code class="d-flex justify-content-center" value="{{qrString}}" size="350" margin="0"
        centerImageSrc="undefined" centerImageSize="undefined" errorCorrectionLevel="H">
      </qr-code>
    </div>

    <div class="payment-wrpr" *ngIf="downloadAppIOS">
      <div class="payment-head">
        <div class="row">
          <div class="col-6" *ngIf="userData">
            <div class="payment-lable" *ngIf="userData">Current Balance</div>
            <div class="payment-amt">
              <span class="rupee-symbol" *ngIf="userData">₹ </span>
              <span *ngIf="isYESPAY && userData">{{userData?.walletBalance || 0}}</span>
              <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'">{{physicalCardObj?.walletBalance || 0}}</span>
              <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'">{{virtualCardObj?.walletBalance || 0}}</span>
            </div>
          </div>
          <div class="col-6" *ngIf="userData">
            <div class="payment-lable" *ngIf="userData">Available Limit</div>
            <div class="payment-amt"><span class="rupee-symbol" *ngIf="userData">₹ </span>
              <span *ngIf="userData">{{userData?.remainingWalletBalanceLimit || 0}}</span>
              <!-- <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'">{{physicalCardObj?.remainingWalletBalanceLimit || 0}}</span>
              <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'">{{virtualCardObj?.remainingWalletBalanceLimit || 0}}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="ld-my-txt-wrpr">
        <div class="ld-my-txt">To add funds download the BHIM YES PAY App from the App Store.</div>
        <a class="istore-link" (click)="goToAppStore()">
          <img src="../../assets/img/wallet.svg">
          <span>Bhim Yes Pay APP</span>
        </a>
      </div>
    </div>

  </div>
</div>

<div class="payment-loading text-center d-none" [ngClass]="{'d-block':showPaymentLoader}">
  <div class="payment-loading-head">
    <img style="height: 35px;" src="../../assets/img/logo/logo-blue.svg">
  </div>
  <div class="payment-loading-body">
    <div class="stage-dot-flashing">
      <div class="dot-flashing"></div>
    </div>
    <div class="wt-frpy-cnf">Waiting For Payment Confirmation</div>
    <div class="redt-upi">{{paymentConfirmationMessage}}</div>
  </div>
  <div class="upi-img">
    <img src="../../assets/img/upi.svg">
  </div>
</div>
<div class="alert-modal d-none" [ngClass]="{'d-block': addFundsModal}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">
    <div class="alert-modal-head d-flex align-items-center justify-content-between"
      style="justify-content: right !important;"><span (click)="toggleModal()"></span></div>
    <div class="alert-modal-body" style="text-align: justify;">
      Due to some technical issue, we have disabled this functionality. Sorry for the inconvenience caused
    </div>
  </div>
</div>

<!-- chalo card set pin page -->
<div class="main-panel" *ngIf="!isYESPAY && isYESPAY === false && menu?.menuIdentifier === 'CHALO'">
  <app-header title="Add Funds" [showMenu]="false" showBackButton="true" (onBack)="previousState()" [settingIcon]="false" [showMenuIcon]="true"></app-header>
  <div class="payment-wrpr">
    <div class="payment-head">
      <div class="row">
        <div class="col-6">
          <div class="payment-lable">Current Balance</div>
          <div class="payment-amt"><span class="rupee-symbol">₹ </span>
            <span *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL'">{{physicalCardObj.walletBalance || 0}}</span>
          </div>
        </div>
        <div class="col-6" *ngIf="userData">
          <div class="payment-lable">Available Limit</div>
          <div class="payment-amt"><span class="rupee-symbol">₹ </span>
            <span *ngIf="userData">{{userData?.remainingWalletBalanceLimit || 0}}</span>
          </div>
        </div>
      </div>
    </div>
  
  <div class="pg-app-info-wrpr">
    <div class="pg-app-info-bl-bg">
      <div class="d-flex">
        <div class="pg-app-icon">
          <img src="../../assets/img/setPin/android-icon.svg">
        </div>
        <div class="pg-app-txt"><span>If you're an Android Mobile User, here's how you can reload your wallet. Please login on the portal through your mobile and follow below steps.</span><a href="https://yplite.yespayhub.in">https://yplite.yespayhub.in</a></div>
      </div>
      <div class="pg-app-info-img">
        <img src="../../assets/img/setPin/app-info-img.svg">
      </div>
    </div>
    <div class="pg-app-info-bl-bg">
      <div class="d-flex">
        <div class="pg-app-icon">
          <img src="../../assets/img/setPin/ios-icon.svg">
        </div>
        <div class="pg-app-txt">If you're an iOS Mobile User, please visit your nearest bus conductor/terminal to load the funds in the wallet.</div>
      </div>
    </div>
    <div class="pg-app-ftr">
      <div class="upi-icn-wrpr">
        <img src="../../assets/img/setPin/upi-icon.svg">
      </div>
      <div class="pg-app-bck" (click)="previousState()">Back</div>
    </div>
  </div>
  </div>
</div>