import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { CardTransactionComponent } from './card-transaction/card-transaction.component';
import { AddFundsComponent } from './add-funds/add-funds.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthGuard } from './auth/auth.guard';
import { TermsComponent } from './terms/terms.component';
import { SecureUsageGuidelinesComponent } from './secure-usage-guidelines/secure-usage-guidelines.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SetPinComponent } from './set-pin/set-pin.component';
import { MenuComponent } from './menu/menu.component';
import { CardComponent } from './card/card.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'menu', component: MenuComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'card/:id', component: CardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'card-detail/:id', component: CardDetailComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'card-transaction/:id', component: CardTransactionComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'add-funds/:id', component: AddFundsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'settings/:id', component: SettingsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'terms', component: TermsComponent, pathMatch: 'full' },
  { path: 'secure-usage-guidelines', component: SecureUsageGuidelinesComponent, pathMatch: 'full' },
  { path: 'faqs', component: FaqsComponent, pathMatch: 'full' },
  { path: 'set-pin/:id', component: SetPinComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
