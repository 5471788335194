import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from "../../data.service";
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-card-replace-dialog',
  templateUrl: './card-replace-dialog.component.html',
})
export class CardReplaceDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  editForm: UntypedFormGroup;
  isSaving=false;
  infoModalOpen = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder : UntypedFormBuilder,
    private dataService:DataService,
    private apiService: ApiService
  ) { }

  initializeForm(){
    this.editForm = this.formBuilder.group({
      kitNo: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      cardLastDigits: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]+$')]],
    })
  }

  ngOnInit() {
    this.initializeForm();
  }

  public decline() {
    this.activeModal.close(false);
  }

  public submit() {
    if(this.editForm.invalid) {
      return;
    }else{
      this.isSaving = true;
      const reqObj = {
        "kitNo": this.editForm.value.kitNo,
        "cardLastDigits": this.editForm.value.cardLastDigits
      };
      this.activeModal.close(reqObj);
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  infoModalClose(){
    this.infoModalOpen = !this.infoModalOpen
  }

}
