<div class="row m-0" id="topSec">
  <h2 class="col-12 p-3 back-btn-header" id="myUserLabel">
    <label class="login-back-btn">
      <img src="../../assets/img/back.svg" *ngIf="showBackButton" (click)="previousState()">
      <img src="../../assets/img/home.svg" *ngIf="showHome" (click)="goToHome()">
      <span *ngIf="title" [ngStyle]="{'color': (showHome && cardBackgroundTextColor) ? cardBackgroundTextColor : (showHome ? '#000000' : '#ffffff'), 'vertical-align': showHome ? 'bottom' : 'middle'}">{{title}}</span>
    </label>
    <div *ngIf="showMenu" class="log-out-menu log-out-menu-ellipse c-pointer" (click)="moreToggle()">
      <img src="../../assets/img/menu.svg">
    </div>
    <div *ngIf="showMenuIcon" class="log-out-menu log-out-menu-ellipse c-pointer" (click)="moreToggle()">
      <img src="../../assets/img/setPin/menu-icon.svg">
    </div>
    <div class="log-out-menu log-out-menu-setting c-pointer" *ngIf="settingIcon" (click)="goToSettings()">
      <img src="../../assets/img/settings_black.svg">
    </div>
    <div class="ellipse-backdrop d-none" *ngIf="showMenu || showMenuIcon" (click)="moreToggleFlage=false"
      [ngClass]="{'d-block':moreToggleFlage}"></div>
    <ul *ngIf="showMenu || showMenuIcon" class="ellipse-dropdown d-none" [ngClass]="{'d-block':moreToggleFlage}">
      <li (click)="moreToggleFlage=false;goToFAQs()">FAQs</li>
      <li (click)="logout()">Logout</li>
    </ul>
  </h2>
</div>
