<div class="row m-0">
  <div class="col-12" [ngStyle]="{'overflow-y': 'auto', 'height': height + 'px' , 'position': 'relative'}">
    <alert class="alert-position"></alert>

    <span *ngIf="!isLoading">
      <img style="margin: 23px 0 0px 5px; cursor: pointer; position: absolute; z-index: 999"
        src="../../assets/img/back.svg" alt="Logo" (click)="goToHome()">
    </span>
    <div class="col-12 justify-content-center">
      <img class="logo" style="margin: 25px auto 15px; display: block; padding-left : 10px"
        src="../../assets/img/logo/logo-white.svg" alt="Logo">
    </div>

    <div class="topSec">

      <div *ngIf="!isLoading">
        <div class="wl-txt-dashboard">Welcome to {{menu?.menuIdentifier}}</div>
        <div class="wl-t-ybp-dashboard">E-Wallet portal !</div>
      </div>

      <div class="card-info">

        <ng-container *ngIf="isConsentPage || isActivateWalletPage">
          <div class="card-container" *ngIf="menu?.ypsorPrepaidProgram?.programDetails">
            <div class="card-body">
              <ul style="list-style-type:disc; padding-left:12px;">
                <li *ngFor="let programDetail of menu?.ypsorPrepaidProgram?.programDetails?.split('|')">
                  <span class="chalo-info-bullets">
                    <span class="pr10px">{{programDetail}}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-i" *ngIf="menu?.ypsorPrepaidProgram?.programInstruction">
            <span class="card-i-text">
              <span style="color: red">Important:</span> {{menu?.ypsorPrepaidProgram?.programInstruction}}
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="isLoading">
          <div class="loader-logo">
            <div class="snippet">
              <div class="stage">
                <div class="dot-pulse"></div>
              </div>
            </div>
          </div>

          <div class="card-w">
            <span class="card-w-text">
              <div style="font-weight: 700;">Waiting For Verification</div>
              <div style="font-weight: 400;">Please wait while we are verifying your {{menu?.menuIdentifier}} details</div>
            </span>
          </div>
        </ng-container>

      </div>
    </div>

    <div class="d-none"
      [ngClass]="{'d-block': isConsentPage || isActivateWalletPage || isAadhaarPage || isReplaceCardPage}">
      <div class="bottomSec" #getHeight>

        <ng-container *ngIf="isActivateWalletPage">

          <div class="login-wrpr" *ngIf="isActivateWalletPageConsent">
            <div class="login-ttl" style="margin-bottom: 15px !important;">Do you have a {{menu?.menuIdentifier}} Card
              with you?</div>
            <div style="margin-bottom: 40px">
              <b>Note:</b> You will need a Kit number & Validation number to activate the {{menu?.menuIdentifier}} Card.
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div (click)="goToHome()"
                class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center">
                No</div>
              <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
                (click)="openActivationForm()">Yes</div>
            </div>
          </div>

          <!-- Activate Card -->
          <div class="login-wrpr" *ngIf="isActivateWalletPageForm">
            <div class="login-ttl" style="margin-bottom: 25px !important;">Activate {{menu?.menuIdentifier}}
              {{menu?.menuIdentifier === "CHALO" ? "BEST" : ""}} Card</div>
            <form [formGroup]="cardActivationForm">
              <mat-form-field class="form-group example-full-width mb-3">
                <mat-label>Kit No.</mat-label>
                <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" numbersOnly
                  class="form-control" placeholder="" name="kitNo" formControlName="kitNo" autocomplete="off">
                <mat-error
                  *ngIf="cardActivationForm.get('kitNo').errors && cardActivationForm.get('kitNo').errors.required">
                  Kit Number is Required
                </mat-error>
              </mat-form-field>
              <mat-form-field class="form-group example-full-width mb-3">
                <mat-label>Validation No.</mat-label>
                <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" minlength="4" maxlength="4"
                  numbersOnly class="form-control" placeholder="Please enter last 4 digits of the card no."
                  name="validationNo" formControlName="validationNo" autocomplete="off">
                <mat-error
                  *ngIf="cardActivationForm.get('validationNo').errors && (cardActivationForm.get('validationNo').errors.required || cardActivationForm.get('validationNo').errors.pattern)">
                  Validation number to be 4 numeric digits only
                </mat-error>
                <div *ngIf="errorMessage" class="alert-error-msg">{{errorMessage}}</div>
              </mat-form-field>
              <div class="d-flex align-items-center justify-content-between">
                <button mat-stroked-button color="accent" [disabled]="cardActivationForm.invalid || isSaving"
                  (click)="activatePhysicalCard()" class="my-2">Proceed
                  <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
                    <span class="sr-only"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>

        </ng-container>

        <div class="login-wrpr" *ngIf="isAadhaarPage">
          <div class="login-ttl" style="margin-bottom: 25px !important;">Activate {{menu?.menuIdentifier}} E-Wallet
          </div>
          <form [formGroup]="walletActivationForm">
            <mat-form-field class="form-group form-group-input example-full-width mb-2">
              <mat-label>Enter Aadhaar No.</mat-label>
              <input matInput name="aadhaarNumber" formControlName="aadhaarNumber" type='tel' maxlength='12'
                (input)="onKycInput($event.target)" class="form-control" placeholder="">
              <div class="error-wrpr"
                *ngIf="walletActivationForm.get('aadhaarNumber').invalid && (walletActivationForm.get('aadhaarNumber').dirty || walletActivationForm.get('aadhaarNumber').touched || walletActivationForm.get('aadhaarNumber').errors.pattern)">
                <mat-error>Please enter a valid 12 digit Aadhar No.</mat-error>
              </div>
            </mat-form-field>
            <mat-form-field class="form-group form-group-input example-full-width mb-2">
              <mat-label>Enter Full Name as per Aadhaar</mat-label>
              <input matInput name="fullName" formControlName="fullName" type="text" class="form-control" (input)="onAadhaarNameInput($event.target)"
              placeholder="">
              <div class="error-wrpr"
                *ngIf="walletActivationForm.get('fullName').invalid && (walletActivationForm.get('fullName').dirty || walletActivationForm.get('fullName').touched || walletActivationForm.get('fullName').errors.pattern)">
                <mat-error>Please enter Full Name as per Aadhaar</mat-error>
              </div>
            </mat-form-field>
            <mat-form-field class="form-group form-group-input example-full-width mb-2">
              <mat-label>DOB (DD/MM/YYYY)</mat-label>
              <input matInput name="dob" [max]="minDate" formControlName="dob" [matDatepicker]="picker"
                class="form-control" maxlength="10" date-slash (dateChange)="clearAadharErrorMessage()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="walletActivationForm.get('dob').errors?.matDatepickerParse">
                Please enter a valid DOB (DD/MM/YYYY)
              </mat-error>
              <mat-error *ngIf="walletActivationForm.get('dob').errors?.matDatepickerMax">
                Minimum age required is 10
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form-group example-full-width mb-2">
              <mat-label>Choose Gender</mat-label>
              <mat-select name="gender" formControlName="gender" (selectionChange)="clearAadharErrorMessage()">
                <mat-option [value]="item.value" *ngFor="let item of genderType">{{item.display}}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="walletActivationForm.get('gender').errors && walletActivationForm.get('gender').errors.required">
                Gender is Required
              </mat-error>
              <div *ngIf="aadhaarErrorMessage" class="alert-error-msg">{{aadhaarErrorMessage}}</div>
            </mat-form-field>
            <div class="d-flex align-items-center justify-content-between">
              <button mat-stroked-button color="accent" [disabled]="walletActivationForm.invalid || isSaving"
                (click)="registerAadhaar()" class="my-4">Proceed
                <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
                  <span class="sr-only"></span>
                </span>
              </button>
            </div>
          </form>
        </div>

        <div class="login-wrpr" *ngIf="isReplaceCardPage">
          <div class="login-ttl" style="margin-bottom: 15px !important;">Your card is blocked!</div>
          <div style="margin-bottom: 40px">Would you like to replace the card?</div>
          <div class="d-flex align-items-center justify-content-between">
            <div (click)="goToHome()"
              class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center">
              No</div>
            <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
              (click)="replaceCardModal(true)">Yes</div>
          </div>
        </div>

        <ng-container *ngIf="isConsentPage">
          <div class="note-btm" *ngIf="isConsentPage">
            Note: By clicking on continue, you will be registered for {{menu?.menuIdentifier}} e-Wallet
          </div>
          <div *ngIf="isConsentPage" class="card-btm-btn" [ngClass]="{'disabled': loading}" (click)="registerCard()">
            Continue&nbsp;
            <span class="spinner-border loadingSplinner" role="status" *ngIf="loading">
              <span class="sr-only">
              </span>
            </span>
          </div>
        </ng-container>

      </div>

    </div>
  </div>
</div>

<div class="alert-modal d-none" [ngClass]="{'d-block': isRegistrationModal}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">

    <div class="alert-modal-head d-flex align-items-center justify-content-between">
      {{alertTitle}}
      <span (click)="toggleRegistrationModal(false, '', '')"></span>
    </div>

    <div *ngIf="alertMessage" class="alert-modal-body">
      {{alertMessage}}
    </div>

    <div *ngIf="isSuccessful" class="alert-modal-footer d-flex align-items-center justify-content-between"
      (click)="toggleRegistrationModal(false, '', '')">
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
        style="width: 100% !important;">Okay</div>
    </div>
  </div>
</div>

<div class="alert-modal d-none" [ngClass]="{'d-block': isCardLinkingModal}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">

    <div class="alert-modal-head d-flex align-items-center justify-content-between">
      {{alertTitle}}
      <span (click)="toggleLinkingModal(false, '', '')"></span>
    </div>

    <div *ngIf="alertMessage" class="alert-modal-body">
      {{alertMessage}}
    </div>

    <div *ngIf="isActivatePhysicalCardSuccessful"
      class="alert-modal-footer d-flex align-items-center justify-content-between"
      (click)="toggleLinkingModal(false, '', '')">
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
        style="width: 100% !important;">Okay</div>
    </div>
  </div>
</div>

<div class="alert-modal d-none" [ngClass]="{'d-block': isReplaceModal}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">
    <div class="alert-modal-head d-flex align-items-center justify-content-between">Replace Card<span
        (click)="replaceCardModal(false)"></span></div>
    <div class="alert-modal-body" style="padding: 30px 20px 0px 20px;">
      <div class="login-wrpr" style="padding: 0px !important;" [ngClass]="{'v-hidden': isReplaceCardLoading}">
        <form [formGroup]="replaceCardForm">
          <mat-form-field class="form-group example-full-width mb-3">
            <mat-label>New Kit No.</mat-label>
            <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" numbersOnly class="form-control"
              placeholder="" name="newKitNo" formControlName="newKitNo" autocomplete="off">
            <mat-error
              *ngIf="replaceCardForm.get('newKitNo').errors && replaceCardForm.get('newKitNo').errors.required">
              Kit Number is Required
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-group example-full-width mb-3">
            <mat-label>New Validation No.</mat-label>
            <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" minlength="4" maxlength="4"
              numbersOnly class="form-control" placeholder="Please enter last 4 digits of the card no."
              name="newValidationNo" formControlName="newValidationNo" autocomplete="off">
            <mat-error
              *ngIf="replaceCardForm.get('newValidationNo').errors && (replaceCardForm.get('newValidationNo').errors.required || replaceCardForm.get('newValidationNo').errors.pattern)">
              Validation number to be 4 numeric digits only
            </mat-error>
          </mat-form-field>
          <div *ngIf="replaceCardErrorMessage" class="alert-error-msg-popup">
            {{replaceCardErrorMessage}}
          </div>
        </form>
      </div>
      <div class="spinner-container" *ngIf="isReplaceCardLoading">
        <span class="spinner-border spinner-large loadingSplinner" role="status">
          <span class="sr-only"></span>
        </span>
      </div>
    </div>
    <div class="alert-modal-footer d-flex align-items-center justify-content-between">
      <div class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center"
        (click)="replaceCardModal(false)">Cancel</div>
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
        [ngClass]="{'disabled': (replaceCardForm.invalid || isReplaceCardLoading)}" (click)="replaceCard()">Proceed</div>
    </div>
  </div>
</div>
