<!-- <div class="jw-modal">
    <div class="jw-modal-body">
        <ng-content></ng-content>
    </div>
</div>
<div class="jw-modal-background"></div> -->

<div class="alert-modal">
	<div class="backdrop"></div>
	<div class="alert-modal-content">
		<div class="alert-modal-head d-flex align-items-center justify-content-between">{{title}} <span (click)="close()"></span></div>
		<div class="alert-modal-body"><ng-content></ng-content></div>
		<div *ngIf="showButtons" class="alert-modal-footer d-flex align-items-center justify-content-between">
			<div class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center" (click)="onButtonClick(false)">Cancel</div>
			<div class="alert-modal-footer-btn d-flex align-items-center justify-content-center" (click)="onButtonClick(true)">Proceed</div>
		</div>
	</div>
</div>