import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AdaptersService } from '../adapters.service';
import { Globals } from 'app/global.constants';

@Component({
  selector: 'app-card-transaction',
  templateUrl: './card-transaction.component.html',
  styleUrls: ['./card-transaction.component.scss'],
  providers: [DatePipe]
})
export class CardTransactionComponent implements OnInit {

  page: number = 1;

  menu: any;
  params: any;
  isYESPAY: any;
  observer: any;
  pagination: any;
  moment: any = moment;

  isLoading = false;

  txnsList = [];
  menuList: any = [];
  txnTypes: string[] = ['UPI_PPI_INWARD', 'UPI_PPI_OUTWARD', 'BBPS', 'COBANK_UPI_LOAD', 'RECHARGE', 'MERCHANT', 'E_COLLECT', 'FastagRetail_Load', 'SendMoney', 'Bank Transfer', 'FEERATE', 'ECOMMERCE', 'RequestMoney', 'PAYU', 'UPI', 'CC', 'CBS', 'CASHBACK', 'DEAL', 'Dutch', 'Non App Transfer', 'TEMP'];
  txnTypesForWalletBalance: string[] = ['UPI_PPI_INWARD', 'E_COLLECT', 'ECOMMERCE', 'RequestMoney', 'PAYU', 'UPI', 'CC', 'CBS', 'DEAL', 'Dutch'];

  @HostListener('scroll') onScroll(e: any): void {
    if ((e.target.offsetHeight + e.target.scrollTop) >= e.target.scrollHeight)
      this.getTransactions(true);
  }
  constructor(
    private adaptersService: AdaptersService,
    protected activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    protected elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.params = params;
    });

    // If menu object is empty or on page refresh
    if (!this.menu) {
      if (this.params.id) {
        this.getMenu(this.params.id);
      }
    }

    // Navigated from menu page
    else {
      this.setIsYesPayFlag();
    }
  }

  getMenu(id: any) {
    this.isLoading = true;
    this.adaptersService.menu({}).then((res: any) => {
      if (res && res.ypLiteWebMenus && res.ypLiteWebMenus.length > 0) {
        this.menuList = res.ypLiteWebMenus;
        const isObjectPresent = this.menuList.filter((o: any) => o.id == id);
        if (!isObjectPresent.length) this.goToHome();
        else this.setMenu(this.menuList, id);
      } else {
        this.menuList = [];
      }
    },
      (err) => {
        this.menuList = [];
        this.goToHome();
      });
  }

  setMenu(res: any, id: any) {
    if (res && res.length > 0) {
      for (let element of res) {
        if (element.id == id) {
          this.menu = element;
          Globals.setMenu(this.menu);
          this.setIsYesPayFlag();
          break;
        }
      }
    }
  }

  setIsYesPayFlag() {
    if (this.menu.menuIdentifier === 'YESPAY') this.isYESPAY = true; else this.isYESPAY = false;
    this.initializeData();
  }

  goToHome() {
    this.router.navigate(['/menu']);
  }

  initializeData() {
    this.getTransactions(false);
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting)
        this.getTransactions(true);
    });
  }

  previousState() {
    window.history.back();
  };

  filterTxnType(filteredArr) {
    return filteredArr.transaction_type.toUpperCase() !== 'POINTS'
  }

  getTransactions(nextPage) {
    const yearOldDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10))
    if (nextPage)
      this.page = Number(this.pagination.current_page) + 1
    if (!this.pagination || this.page <= this.pagination.total_pages) {
      const reqObj = {
        "startDate": this.datePipe.transform(yearOldDate, 'dd-MM-yyyy'),
        "endDate": this.datePipe.transform(new Date(), 'dd-MM-yyyy'),
        "page": this.page.toString()
      } as any;
      if (!this.isYESPAY && this.menu && this.menu.ypsorPrepaidProgram && this.menu.ypsorPrepaidProgram.programIdentifier) {
        reqObj.programId = this.menu.ypsorPrepaidProgram.programIdentifier;
      }
      this.adaptersService.fetchTxns(reqObj).then((res: any) => {
        this.isLoading = false;
        if (res) {
          this.pagination = res.pagination
          res.transactions.forEach((item: any) => {
            if (item.transaction_tag !== null) {
              try {
                if (typeof item.transaction_tag === 'string')
                  item.transaction_tag = JSON.parse(item.transaction_tag);
              } catch (e) {
                const merchantDetails = item.transaction_tag.split("|")
                if (merchantDetails.length > 2) {
                  item.transaction_tag = { "Merchant_Name": merchantDetails[2], "Txn_Type": merchantDetails[0] }
                } else {
                  item.transaction_tag = {} as any;
                }
              }
            } else {
              item.transaction_tag = {} as any;
            }
          })
          if (nextPage)
            this.txnsList = this.txnsList.concat(res.transactions.filter(this.filterTxnType));
          else
            this.txnsList = res.transactions.filter(this.filterTxnType);
          setTimeout(() => {
            const target = this.elementRef.nativeElement.querySelector('.last-item');
            this.observer.observe(target)
          }, 300)
        }
      },
        (err) => {
          window.history.back();
        });
    } else {
      const target = this.elementRef.nativeElement.querySelector('.last-item');
    }
  }

  getDisplayDetail(item: any) {
    item.displayDetail = !item.displayDetail;
  }
}
