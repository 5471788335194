import { Component, ViewEncapsulation, ElementRef, Input, Output, OnInit, OnDestroy, EventEmitter, Renderer2 } from '@angular/core';

import { ModalService } from './modal.service';

@Component({ 
    selector: 'jw-modal', 
    templateUrl: 'modal.component.html', 
    styleUrls: ['modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() showButtons:boolean
    @Input() title:string;
    private element: any;
    @Output() onClick: EventEmitter<any> = new EventEmitter();


    constructor(private modalService: ModalService, private elementRef: ElementRef,  protected renderer: Renderer2,) {
        this.element = elementRef.nativeElement;
    }

    onButtonClick(flag:boolean): void {
        this.close();
        if(this.onClick.observers.length)
            this.onClick.emit(flag);
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        this.title = this.title || "Confirm";

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        if (!this.elementRef.nativeElement.contains(this.element)) {
            this.renderer.appendChild(this.elementRef.nativeElement, this.element);
        }

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'jw-modal') {
                this.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.renderer.setStyle(this.element, 'display', 'block');
        this.renderer.addClass(document.body, 'jw-modal-open');
    }

    // close modal
    close(): void {
        this.renderer.setStyle(this.element, 'display', 'none');
        this.renderer.addClass(document.body, 'jw-modal-open');
    }
}