import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { AlertService } from '../_alert';
import { AdaptersService } from '../adapters.service';
import { ApiService } from '../api.service';
import { Globals } from '../global.constants';
import { DataService } from 'app/data.service';
import * as moment from 'moment';

export const Date_Formats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [DatePipe, {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
    { provide: MAT_DATE_FORMATS, useValue: Date_Formats }
  ]
})
export class LoginComponent implements OnInit {

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  @ViewChild('ngPassInput', { static: false }) ngPassInput: any;
  @ViewChild('ngCpassInput', { static: false }) ngCpassInput: any;
  @ViewChild('ngPassInputClear', { static: false }) ngPassInputClear: any;
  @ViewChild('ngCpassInputClear', { static: false }) ngCpassInputClear: any;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '35px',
      height: '35px',
      fontSize: '20px'
    }
  };
  configNew = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '25px',
      height: '35px',
      fontSize: '14px'
    }
  };
  configPasscode = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '25px',
      height: '35px',
      fontSize: '14px'
    }
  };
  configConfirmPasscode = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: true,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      width: '25px',
      height: '35px',
      fontSize: '14px'
    }
  };
  configConfirm = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
    placeholder: '',
    inputStyles: {
      width: '25px',
      height: '35px',
      fontSize: '14px'
    }
  };

  kycForm: UntypedFormGroup;
  editForm: UntypedFormGroup;
  passForm: UntypedFormGroup;
  mobileForm: UntypedFormGroup;

  otpSecDisp = false;
  mobileSecDisp = true;
  setPasscodeDisp = false;
  registrationSecDisp = false;

  counter: any;
  otpToken: any;
  interval: any;
  captchSrc: any;
  kycDetails: any;
  userDetails: any;
  persistObject: any;
  maxAttempt: any = '';

  kycType = [];
  genderType = [
    { display: 'Male', value: 'M' },
    { display: 'Female', value: 'F' }
  ];

  isSaving = false;
  isResend = false;
  isOtpSaving = false;
  enableCaptcha = false;
  showOtpComponent = true;
  matchCheckPassed = false;
  repeatCheckPassed = false;
  lengthCheckPassed = false;
  sequenceCheckPassed = false;

  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));
  maxDate = new Date();

  sugCheck: boolean = true;
  eventFlag: boolean = true;

  pwdFlags = { pwd: false, confirmpwd: false }

  constructor(
    private router: Router,
    private datepipe: DatePipe,
    private renderer: Renderer2,
    private apiService: ApiService,
    private dataService: DataService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private adaptersService: AdaptersService,
  ) { }

  ngOnInit() {
    this.renderer.setStyle(document.body, 'background-image', 'url(../../assets/img/bg1.png)');
    this.initializeForm();
    const user = Globals.getNonLoggedInUser()
    if (user != null) {
      this.mobileSecDisp = false
      this.otpSecDisp = false
      this.setUserRegStep(user);
    }
    this.persistObject = this.dataService.getKycDetails();
    if (this.persistObject) {
      if (this.persistObject.userDetailsObj) {
        this.userDetails = this.persistObject.userDetailsObj;
      }
      if (this.persistObject.kycDetails) {
        this.kycDetails = this.persistObject.kycDetails;
        this.kycDetails.dob ? this.kycForm.get('kycDob').setValue(this.kycDetails.dob) : this.kycForm.get('kycDob').setValue(null);
        this.kycDetails.fullName ? this.kycForm.get('kycName').setValue(this.kycDetails.fullName) : this.kycForm.get('kycName').setValue(null);
        this.kycDetails.gender ? this.kycForm.get('kycGender').setValue(this.kycDetails.gender) : this.kycForm.get('kycGender').setValue(null);
        this.kycDetails.mobileNo ? this.mobileForm.get('mobile').setValue(this.kycDetails.mobileNo) : this.mobileForm.get('mobile').setValue(null);
        this.kycDetails.kycTnc ? this.kycForm.get('kycTnc').setValue(this.kycDetails.kycTnc) : this.kycForm.get('kycTnc').setValue(null);
        this.kycDetails.kycType ? this.kycForm.get('kycType').setValue(this.kycType[this.kycType.findIndex((item) => item.display === this.kycDetails.kycType.display)]) : this.kycForm.get('kycType').setValue(null);
        if (this.kycDetails.typeOfkyc === "PAN") {
          this.kycForm.get('kycNumber').setValidators([Validators.pattern('^([a-zA-Z]){3}([pP]){1}([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}$'), Validators.required]);
          this.kycForm.get('kycNumber').setValue(this.kycDetails.panNo)
        } else {
          this.kycForm.get('kycNumber').setValidators([Validators.pattern('^([1-9]{1}([0-9]{11}))$'), Validators.required]);
          this.kycForm.get('kycNumber').setValue(this.kycDetails.aadhaarNo);
        }
        this.sugCheck = this.kycDetails.sugCheck;
      }
    }
  }

  initializeForm() {
    this.mobileForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      cardLastSixDigits: [''],
      kitNo: [''],
      expiryDate: [''],
      pinNo: [''],
      captcha: []
    });
    this.editForm = this.formBuilder.group({
      otp: ['', Validators.required],
      captcha: []
    })
    this.kycForm = this.formBuilder.group({
      kycType: ['', Validators.required],
      kycNumber: ['', Validators.required],
      kycName: ['', Validators.required],
      kycDob: ['', Validators.required],
      kycGender: ['', Validators.required],
      kycTnc: [false, Validators.requiredTrue],
    })
    const ref = this;
    this.passForm = this.formBuilder.group({
      passcode: ['', Validators.required],
      cpasscode: ['', Validators.required],
    })
  }

  onKycInput(input: any) {
    if (this.kycForm.value.kycType.value === "AADHAAR")
      input.value = input.value.replace(/[^0-9]/g, '');
    else
      input.value = input.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
  }

  submit() {
    this.maxAttempt = '';
    this.isSaving = true;
    const reqObj: any = {}
    reqObj.mobileNo = this.mobileForm.value.mobile
    this.adaptersService.getOtp(reqObj).then((res: any) => {
      if (res) {
        if (res.enableCaptcha) {
          this.loadCaptcha(false);
        }
        this.mobileNumberSaveSuccess(res);
      } else
        this.isSaving = false;
    },
      (err) => {
        this.isSaving = false;
        this.maxAttempt = (err && err.error && err.error.message && err.error.message.includes("left before your account is locked")) ? err.error.message : '';
        if (err.error.enableCaptcha) {
          this.loadCaptcha(err);
        }
      });
  }

  mobileNumberSaveSuccess(res: any) {
    this.otpToken = res.otpToken;
    if (res.resendTime)
      this.counter = res.resendTime;
    this.isSaving = false;
    this.mobileSecDisp = false;
    this.otpSecDisp = true;
    this.enableOtpAutoFocus();
    this.startCountDown();
  }

  startCountDown() {
    this.counter = this.counter || 60;
    this.isSaving = false;
    this.interval = setInterval(() => {
      if (this.counter > 0)
        this.counter = this.counter - 1;
      else
        clearInterval(this.interval);
    }, 1000);
  }

  submitOtp() {
    if (this.editForm.get('otp').value.length === 6) {
      this.isOtpSaving = true;
      this.maxAttempt = '';
      const reqObj: any = {
        "otpToken": this.otpToken,
        "otp": this.editForm.get('otp').value
      };
      if (this.enableCaptcha) reqObj.captcha = this.editForm.value.captcha;
      this.adaptersService.verifyOtp(reqObj).then((res: any) => {
        if (res && res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
          if (res.responseCode === 'CB18') {
            this.mobileForm.get('mobile').setValue(null);
            this.mobileForm.get('mobile').updateValueAndValidity();
            this.mobileSecDisp = true;
            this.otpSecDisp = false;
          }
          res.responseMessage.includes("Failed OTP attempts exceeded") ? this.alertService.error('Maximum OTP attempts exceeded') : this.alertService.error(res.responseMessage);
        } else {
          this.loginToHome(res)
        }
        this.isOtpSaving = false;
      },
        (err) => {
          this.isOtpSaving = false;
          const otpAttempt = (err && err.error && err.error.title && err.error.title.includes("Failed OTP attempts exceeded")) ? 'Maximum OTP attempts exceeded' : '';
          if (otpAttempt) {
            this.maxAttempt = '';
            this.reloadscreen();
            return;
          }
          this.maxAttempt = (err && err.error && err.error.message && err.error.message.includes("left before your account is locked")) ? err.error.message : '';
          if (err.error.enableCaptcha) {
            this.loadCaptcha(err);
          }
          if (err && err.status === 401) {
            this.initializeForm();
            this.previousState();
          }
        });
    }
  }

  loginToHome(res) {
    let updateReqObj = {
      "loggedIn": false,
      "token": res.jwtToken
    };
    this.apiService.updateUserDetail(updateReqObj);
    localStorage.setItem('userObj', JSON.stringify(updateReqObj));
    this.getUserDetails();
  }

  getUserDetails() {
    this.adaptersService.userDetails({}).then((res: any) => {
      if (res) {
        this.userDetails = res;
        if (this.userDetails && this.userDetails.kycStatus) {
          localStorage.setItem('kycStatus', this.userDetails.kycStatus);
        }
        Globals.saveNonLoggedInUser(this.userDetails);
        this.setUserRegStep(res);
      } else {
        this.isOtpSaving = false;
      }
    },
      (error) => {
        this.isOtpSaving = false;
      });
  }

  setUserRegStep(res: any) {
    if (res) {
      const kycNumberInput = this.kycForm.controls["kycNumber"];
      if (res.isPpiRegistered) {
        if (res.lastPasscodeSetAt) {
          const localUserObj = JSON.parse(localStorage.getItem("userObj"));
          localUserObj.loggedIn = true;
          localStorage.setItem('userObj', JSON.stringify(localUserObj));
        } else {
          this.setPasscodeDisp = true;
        }
        this.getUserData();
      } else {
        this.isSaving = false;
        this.isOtpSaving = false;
        this.otpSecDisp = false;
        if (!res.yesPayPan && !res.yesPayUidai) {
          this.kycType = [
            { display: 'Aadhar', value: 'AADHAAR' },
            { display: 'PAN', value: 'PAN' }
          ];
          kycNumberInput.setValidators([Validators.required, Validators.pattern('^([1-9]{1}([0-9]{11}))$')]);
        } else {
          if (res.yesPayUidai) {
            this.kycType.push({ display: 'Aadhar', value: 'AADHAAR' });
            kycNumberInput.setValidators([Validators.pattern('^([1-9]{1}([0-9]{11}))$'), Validators.required]);
          }
          if (res.yesPayPan) {
            this.kycType.push({ display: 'PAN', value: 'PAN' })
            kycNumberInput.setValidators([Validators.pattern('^([a-zA-Z]){3}([pP]){1}([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}$'), Validators.required]);
          }
        }
        this.registrationSecDisp = true;
        this.kycForm.get('kycType').setValue(this.kycType[0]);
        kycNumberInput.updateValueAndValidity();
      }
    } else {
      this.isOtpSaving = false;
    }
  }

  getUserData() {
    this.router.navigate(["/menu"]);
  }

  kycTypeChange() {
    const kycTypeField = this.kycForm.get('kycType');
    const kycNumber = this.kycForm.controls["kycNumber"];
    kycNumber.setValue('');
    if (kycTypeField.value.value == 'PAN')
      kycNumber.setValidators([Validators.required, Validators.pattern('^([a-zA-Z]){3}([pP]){1}([a-zA-Z]){1}([0-9]){4}([a-zA-Z]){1}$')]);
    else
      kycNumber.setValidators([Validators.required, Validators.pattern('^([1-9]{1}([0-9]{11}))$')]);
    kycNumber.updateValueAndValidity();
  }

  onOtpChange(otp: any) {
    if (otp && otp.toString().length === 6) {
      this.editForm.get('otp').setValue(otp);
      this.editForm.get('otp').updateValueAndValidity();
      const node = (<HTMLInputElement>document.getElementById("ngOtpInput"));
      if (node && this.eventFlag === true) {
        this.eventFlag = false;
        node.addEventListener("keydown", ({ key }) => {
          if (key === "Enter" && this.editForm.get('otp').value.length === 6) {
            this.submitOtp();
          }
        })
      }
    }
    else {
      this.editForm.get('otp').setValue('');
      this.editForm.get('otp').updateValueAndValidity();
    }
  }

  resendOtp() {
    this.isResend = true;
    const reqObj = {
      "otpToken": this.otpToken,
    };
    this.adaptersService.resendOtp(reqObj).then((res: any) => {
      if (res && res.responseStatus && (res.responseStatus.toLowerCase() === "failure" || res.responseStatus.toLowerCase() === "error")) {
        if (res.responseCode === 'CB32') {
          this.mobileForm.get('mobile').setValue(null);
          this.mobileForm.get('mobile').updateValueAndValidity();
          this.mobileSecDisp = true;
          this.otpSecDisp = false;
        }
        if (res.responseMessage) this.alertService.error('Maximum OTP attempts exceeded');
      } else {
        this.mobileNumberSaveSuccess(res);
      }
      this.isResend = false;
    },
      (err) => {
        this.isResend = false;
        const otpAttempt = (err && err.error && err.error.title && err.error.title.includes("OTP failed attempts exceeded")) ? err.error.title : '';
        if (otpAttempt) {
          this.maxAttempt = '';
          this.reloadscreen();
          return;
        }
        if (err.status === 401) {
          this.initializeForm();
          this.previousState();
        }
      });
  }

  enableOtpAutoFocus() {
    this.showOtpComponent = true;
    if (this.ngOtpInput) {
      this.ngOtpInput.setValue(null);
    }
    this.editForm.get('otp').setValue(null);
    setTimeout(() => {
      this.config.disableAutoFocus = false;
      this.showOtpComponent = true;
    });
  }

  previousState() {
    this.mobileSecDisp = false;
    this.otpSecDisp = false
  }

  loadCaptcha(err: any) {
    this.captchSrc = '';
    if (err && err.error && err.error.limitExpire) { this.reloadscreen(); return }
    setTimeout(() => {
      const timeStmp = new Date().getTime();
      this.editForm.get('captcha').setValue('');
      this.mobileForm.get('captcha').setValue('');
      const SERVER_API_URL = this.apiService.baseUrl;
      const kitNo = this.mobileForm.get('kitNo').value;
      this.captchSrc = `${SERVER_API_URL}captcha?kitNo=${kitNo}&timeStamp=${timeStmp}`
      this.enableCaptcha = true;
      this.editForm.get('captcha').setValidators([Validators.required]);
      this.editForm.get('captcha').updateValueAndValidity();
      this.mobileForm.get('captcha').setValidators([Validators.required]);
      this.mobileForm.get('captcha').updateValueAndValidity();
    });
  }

  reloadscreen() {
    this.mobileSecDisp = false;
    this.otpSecDisp = false;
    this.enableCaptcha = false;
    this.showOtpComponent = true;
    this.initializeForm();
  }

  registerPpi() {
    this.isSaving = true;
    this.adaptersService.registerPpiUser(this.createKycObj("rp")).then((res: any) => {
      if (res) {
        this.registrationSecDisp = false;
        this.setPasscodeDisp = true;
      }
      this.isSaving = false;
    }, (err) => {
      this.isSaving = false;
    });
  }

  showPasscode(type) {
    this.pwdFlags[type] = !this.pwdFlags[type]
    if (this.pwdFlags[type]) {
      if (type === 'pwd')
        this.ngPassInputClear.setValue(this.passForm.get('passcode').value);
      else
        this.ngCpassInputClear.setValue(this.passForm.get('cpasscode').value);
    } else {
      if (type === 'pwd')
        this.ngPassInput.setValue(this.passForm.get('passcode').value);
      else
        this.ngCpassInput.setValue(this.passForm.get('cpasscode').value);
    }
  }

  onPassChange(pass: any) {
    this.passForm.get('passcode').setValue(pass);
    if (pass.toString().length === 6)
      this.lengthCheckPassed = true;
    else
      this.lengthCheckPassed = false;
    if ("0123456789".indexOf(pass.toString()) == -1)
      this.sequenceCheckPassed = true;
    else
      this.sequenceCheckPassed = false;
    if (/^(\d)(?!\1+$)\d*$/.test(pass.toString()))
      this.repeatCheckPassed = true;
    else
      this.repeatCheckPassed = false;
  }

  onPassConfirmChange(pass: any) {
    this.passForm.get('cpasscode').setValue(pass);

    if (pass.toString() === this.passForm.get('passcode').value)
      this.matchCheckPassed = true;
    else
      this.matchCheckPassed = false;
  }

  onPassClearChange(pass: any) {
    this.passForm.get('passcode').setValue(pass);
    if (pass.toString().length === 6)
      this.lengthCheckPassed = true;
    else
      this.lengthCheckPassed = false;
    if ("0123456789".indexOf(pass.toString()) == -1)
      this.sequenceCheckPassed = true;
    else
      this.sequenceCheckPassed = false;
    if (/^(\d)(?!\1+$)\d*$/.test(pass.toString()))
      this.repeatCheckPassed = true;
    else
      this.repeatCheckPassed = false;
  }

  onPassConfirmClearChange(pass: any) {
    this.passForm.get('cpasscode').setValue(pass);
    if (pass.toString() === this.passForm.get('passcode').value)
      this.matchCheckPassed = true;
    else
      this.matchCheckPassed = false;
  }

  setPasscode() {
    this.isSaving = true;
    const reqObj = {
      "mobileNo": this.userDetails.mobileNumber,
      "passcode": this.passForm.value.passcode,
    }
    this.adaptersService.setPasscode(reqObj).then((res: any) => {
      if (res) {
        this.getUserData();
        setTimeout(() => {
          this.alertService.success("Passcode set successfully");
        }, 1500)
        const localUserObj = JSON.parse(localStorage.getItem("userObj"));
        localUserObj.loggedIn = true;
        this.apiService.updateUserDetail(localUserObj);
        localStorage.setItem('userObj', JSON.stringify(localUserObj));
      }
      this.isSaving = false;
    }, (err) => {
      this.isSaving = false;
    });
  }

  createKycObj(flag: string) {
    const reqObj: any = {};
    reqObj.typeOfkyc = this.kycForm.value.kycType.value;
    if (reqObj.typeOfkyc === "PAN") {
      reqObj.panNo = this.kycForm.value.kycNumber;
      reqObj.yesPayPan = this.userDetails.yesPayPan;
    } else {
      reqObj.aadhaarNo = this.kycForm.value.kycNumber;
      reqObj.yesPayUidai = this.userDetails.yesPayUidai;
    }
    reqObj.fullName = this.kycForm.value.kycName;
    if (flag === "rp") reqObj.dob = this.datepipe.transform(this.kycForm.value.kycDob, 'dd-MM-yyyy');
    else {
      reqObj.dob = this.kycForm.value.kycDob;
      reqObj.kycTnc = this.kycForm.value.kycTnc;
      reqObj.kycType = this.kycForm.value.kycType;
    }
    reqObj.gender = this.kycForm.value.kycGender;
    reqObj.mobileNo = this.mobileForm.value.mobile;
    if (this.userDetails.isPpiUserPresent) { reqObj.isPpiUserPresent = "True"; }
    else { reqObj.isPpiUserPresent = "False"; }
    reqObj.sugCheck = this.sugCheck;
    reqObj.sugAcceptanceTimestamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    return reqObj;
  }

  goToTermsPage() {
    this.dataService.setKycDetails(this.createKycObj("tc"), this.userDetails);
    this.router.navigate(['/terms']);
  }

  goSecureUsageGuidelines() {
    this.sugCheck = false;
    this.dataService.setKycDetails(this.createKycObj("sg"), this.userDetails);
    this.router.navigate(['/secure-usage-guidelines']);
  }
}
