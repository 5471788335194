import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-secure-usage-guidelines',
  templateUrl: './secure-usage-guidelines.component.html',
  styleUrls: ['./secure-usage-guidelines.component.scss']
})
export class SecureUsageGuidelinesComponent implements OnInit {

  constructor(private router: Router) { }

	ngOnInit(): void {
	}
	goBack(){
		window.history.back();
	}
}
