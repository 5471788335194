<div class="row m-0">
  <div class="col-12">
    <div class="topSec">
      <alert class="alert-position"></alert>
      <div>
        <img class="logo" src="../../assets/img/logo/logo-white.svg" alt="Logo">
        <div [hidden]="setPasscodeDisp">
          <div class="wl-txt">Welcome to YES PAY Lite </div>
          <div class="wl-t-ybp">e-Wallet portal !</div>
        </div>
        <!-- <div [hidden]="!setPasscodeDisp"> -->
        <div *ngIf="setPasscodeDisp">
          <div class="wl-txt wl-txt-1">Set Passcode</div>
          <div class="wl-t-ybp wl-t-ybp-small">Set Passcode for a quick and easy access to YES PAY Lite Mobile App</div>
        </div>
      </div>
    </div>
    <!-- <div class="bottomSec" [hidden]="!mobileSecDisp || otpSecDisp"> -->
    <div class="bottomSec" *ngIf="mobileSecDisp || !otpSecDisp">
      <div class="login-wrpr">
        <div class="login-ttl">Login</div>
        <form [formGroup]="mobileForm">
          <mat-form-field class="form-group example-full-width mb-2">
            <mat-label>Mobile No.</mat-label>
            <input matInput type="tel" numbersOnly class="form-control" placeholder="" name="mobile" formControlName="mobile" minLength="10" maxLength="10" autocomplete="off">
            <div class="error-wrpr" *ngIf="mobileForm.get('mobile').invalid && (mobileForm.get('mobile').dirty || mobileForm.get('mobile').touched || mobileForm.get('mobile').errors.pattern)">
              <mat-error *ngIf="mobileForm.get('mobile').errors.required || mobileForm.get('mobile').errors.minlength || mobileForm.get('mobile').errors.maxlength || mobileForm.get('mobile').errors.pattern">
                Please enter only 10 digit valid phone number
              </mat-error>
            </div>
          </mat-form-field>
          <div class="row mb-0 maxAttempt" *ngIf="maxAttempt">
            <div class="col ml-0">
              <small class="text-danger resendText">{{maxAttempt}}</small>
            </div>
          </div>
          <div class="text-center ot-none">
            <button mat-stroked-button color="accent" class="my-4" [disabled]="mobileForm.invalid || isSaving" (click)="submit()">
              Get OTP
              <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
                <span class="sr-only"></span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="bottomSec" [hidden]="!otpSecDisp"> -->
    <div class="bottomSec" *ngIf="otpSecDisp">
      <div class="login-wrpr">
        <div class="login-ttl">YES PAY Lite</div>
        <div class="login-subtl">
          Please enter the OTP sent on your registered mobile number - {{mobileForm.get('mobile').value}}
        </div>
        <form [formGroup]="editForm">
          <div class="form-group row pb-0 mb-1 form-group-otp">
            <ng-otp-input class="col-12 mt-2 text-center" id="ngOtpInput" *ngIf="showOtpComponent" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
            <div class="error-wrpr" *ngIf="editForm.get('otp').invalid && (editForm.get('otp').dirty || editForm.get('otp').touched)">
              <small class="form-text text-danger" *ngIf="editForm.get('otp').errors.required">
                This field is required.
              </small>
              <small class="form-text text-danger" *ngIf="editForm.get('otp').errors.maxlength">
                This field cannot be longer than 6 characters.
              </small>
            </div>
          </div>
          <div class="row mb-0">
            <div class="col ml-0 text-center">
              <small class="text-danger resendText" *ngIf="counter">Resend OTP in {{counter}} Seconds</small>
              <button mat-stroked-button color="accent" class="my-0 resend-btn" *ngIf="counter==0" (click)="resendOtp()" [disabled]="isResend">
                Resend OTP
              </button>
            </div>
          </div>
          <div class="text-center ot-none">
            <button mat-stroked-button color="accent" class="my-4" (click)="submitOtp()" [disabled]="editForm.invalid || isOtpSaving" [ngClass]="{'bt-mt-14' : enableCaptcha}">
              Submit OTP
              <span class="spinner-border loadingSplinner" *ngIf="isOtpSaving" role="status">
                <span class="sr-only"></span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="bottomSec" [hidden]="!registrationSecDisp"> -->
    <div class="bottomSec" *ngIf="registrationSecDisp">
      <div class="login-wrpr login-wrpr-kyc">
        <div class="login-ttl">Activate e-Wallet</div>
        <form [formGroup]="kycForm">
          <mat-form-field class="form-group example-full-width mb-2">
            <mat-label>Choose KYC type</mat-label>
            <mat-select name="kycType" formControlName="kycType" (selectionChange)="kycTypeChange()">
              <mat-option [value]="item" *ngFor="let item of kycType">{{item.display}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="form-group form-group-input example-full-width mb-2">
            <mat-label>Enter {{kycForm.value.kycType.display}} No.</mat-label>
            <input matInput name="kycNumber" formControlName="kycNumber" type="{{kycForm.value.kycType.value == 'PAN' ? 'text':'tel'}}" maxlength="{{kycForm.value.kycType.value == 'PAN' ? '10':'12'}}" (input)="onKycInput($event.target)" class="form-control" placeholder="">
            <div class="error-wrpr" *ngIf="kycForm.get('kycNumber').invalid && (kycForm.get('kycNumber').dirty || kycForm.get('kycNumber').touched || kycForm.get('kycNumber').errors.pattern)">
              <mat-error>
                {{kycForm.value.kycType.value == 'AADHAAR' ? 'Please enter a valid 12 digit Aadhar No' :
                'Please enter a valid Pan No'}}
              </mat-error>
            </div>
          </mat-form-field>
          <mat-form-field class="form-group form-group-input example-full-width mb-2">
            <mat-label>Enter Full Name as per {{kycForm.value.kycType.display}}</mat-label>
            <input matInput name="kycName" formControlName="kycName" type="text" class="form-control" placeholder="">
          </mat-form-field>
          <mat-form-field class="form-group form-group-input example-full-width mb-2">
            <mat-label>DOB (DD/MM/YYYY)</mat-label>
            <input matInput name="kycDob" [max]="minDate" formControlName="kycDob" [matDatepicker]="picker" class="form-control" maxlength="10" date-slash>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="kycForm.get('kycDob').errors?.matDatepickerParse">
              Please enter a valid DOB (DD/MM/YYYY)
            </mat-error>
            <mat-error *ngIf="kycForm.get('kycDob').errors?.matDatepickerMax">
              <!-- DOB cannot be greater than {{kycForm.get('kycDob').errors?.matDatepickerMax?.max | date:'dd/MM/yyyy'}} -->
              Minimum age required is 10
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-group example-full-width mb-2">
            <mat-label>Choose Gender</mat-label>
            <mat-select name="kycGender" formControlName="kycGender">
              <mat-option [value]="item.value" *ngFor="let item of genderType">{{item.display}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="form-group example-full-width custom-checkbox">
            <input type="checkbox" class="d-none" id="terms" name="kycTnc" formControlName="kycTnc">
            <label for="terms">I agree to the <span (click)="goToTermsPage()">Terms and Conditions</span> and <span
                (click)="goSecureUsageGuidelines()">Secure Usage Guidelines</span> of the bank</label>
          </div>
          <div class="activation-alert" *ngIf="sugCheck">
            <span style="color: red;">Alert!</span>
            To proceed further, it is mandatory to read and agree to the secured usage guidelines
          </div>
          <div class="text-center ot-none proceed-btn">
            <button mat-stroked-button color="accent" [disabled]="kycForm.invalid || isSaving || sugCheck"
              (click)="registerPpi()" class="my-4">Proceed
              <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
                <span class="sr-only"></span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="bottomSec" [hidden]="!setPasscodeDisp"> -->
    <div class="bottomSec" *ngIf="setPasscodeDisp">
      <div class="login-wrpr">
        <!-- <div class="login-ttl">Activate e-Wallet</div> -->
        <form [formGroup]="passForm">
          <div class="form-group form-group-ent-ps passcode-inp-wrpr">
            <div class="passcode-lable">Enter Passcode</div>
            <ng-otp-input class="mt-2 text-left" [ngClass]="{'hidden':pwdFlags['pwd']}" #ngPassInput [config]="configPasscode" (onInputChange)="onPassChange($event)"></ng-otp-input>
            <ng-otp-input class="mt-2 text-left" [ngClass]="{'hidden':!pwdFlags['pwd']}" #ngPassInputClear [config]="configNew" (onInputChange)="onPassClearChange($event)"></ng-otp-input>
            <div class="passcode-tggle" (click)="showPasscode('pwd')"><img *ngIf="pwdFlags['pwd']" src="../../assets/img/hide.svg" alt="Logo"><img *ngIf="!pwdFlags['pwd']" src="../../assets/img/show.svg" alt="Logo"></div>
          </div>
          <div class="passcode-strg-chk">
            <div class="passcode-strg-lst" [ngClass]="{'active': lengthCheckPassed}">Passcode should be 6-digits.</div>
            <div class="passcode-strg-lst" [ngClass]="{'active': sequenceCheckPassed}">Sequential numbers not allowed.</div>
            <div class="passcode-strg-lst" [ngClass]="{'active': repeatCheckPassed}">All 6 digits cannot be the same</div>
          </div>
          <div class="form-group-cnf-ps form-group passcode-inp-wrpr">
            <div class="passcode-lable">Confirm Passcode</div>
            <ng-otp-input class="mt-2 text-left" [ngClass]="{'hidden':pwdFlags['confirmpwd']}" #ngCpassInput [config]="configConfirmPasscode" (onInputChange)="onPassConfirmChange($event)"></ng-otp-input>
            <ng-otp-input class="mt-2 text-left" [ngClass]="{'hidden':!pwdFlags['confirmpwd']}" #ngCpassInputClear [config]="configConfirm" (onInputChange)="onPassConfirmClearChange($event)"></ng-otp-input>
            <div class="passcode-tggle" (click)="showPasscode('confirmpwd')">
              <img class="hidden" src="../../assets/img/hide.svg" alt="Logo">
              <img *ngIf="pwdFlags['confirmpwd']" src="../../assets/img/hide.svg" alt="Logo">
              <img *ngIf="!pwdFlags['confirmpwd']" src="../../assets/img/show.svg" alt="Logo">
            </div>
          </div>
          <div class="passcode-strg-chk">
            <div class="error-msg" [ngClass]="{'active': !matchCheckPassed && passForm.get('cpasscode').value}">
              Passcode doesn't match</div>
          </div>
          <div class="text-center ot-none">
            <button mat-stroked-button color="accent" (click)="setPasscode()" [disabled]="!lengthCheckPassed || !sequenceCheckPassed || !repeatCheckPassed || !matchCheckPassed || isSaving" class="my-4">
              Proceed
              <span class="spinner-border loadingSplinner" *ngIf="isSaving" role="status">
                <span class="sr-only"></span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
