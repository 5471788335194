<!-- <div class="main-panel" [style.background-color]="((isYESPAY && physicalCardObj) || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardBackgroundColorCode)) ? ((physicalCardObj && userData?.isKeyChainUser) ? '#D5C19E' : menu?.ypsorPrepaidProgram?.cardBackgroundColorCode) : '#FFFFFF'"> -->
<div class="main-panel" [style.background-color]="menu?.ypsorPrepaidProgram?.cardBackgroundColorCode || '#FFFFFF'">

  <div class="card-loader" *ngIf="isTxnApiFailed &&
    ((!isYESPAY && isNonYesPayApiLoading === false && (isNonYesPayApiFailed || ((menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' && !virtualCardObj) || (menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && !physicalCardObj))))
    || (isYESPAY && isYesPayApiLoading === false && (isYesPayApiFailed || !virtualCardObj)))">
    <div class="d-flex justify-content-center">Unable to fetch details!</div>
    <div class="d-flex justify-content-center align-items-center" style="cursor: pointer;" (click)="callAllAPIs(true);">
      <img src="../../assets/img/reloadCard.svg">Reload
    </div>
  </div>

  <div>
    <div class="row m-0">
      <div class="col-12">
        <alert class="alert-position"></alert>
      </div>
    </div>

    <app-header [showHome]="true" [showMenuIcon]="false" cardBackgroundTextColor="{{menu?.ypsorPrepaidProgram?.cardBackgroundTextColor ? menu?.ypsorPrepaidProgram?.cardBackgroundTextColor : '#000000'}}" title="{{menu?.menuTitle}}" [settingIcon]="((!isYESPAY && !isNonYesPayApiLoading && !isNonYesPayApiFailed && (virtualCardObj || physicalCardObj)) ||
      (isYESPAY && !isYesPayApiLoading && !isYesPayApiFailed && physicalCardObj))"></app-header>

    <div class="row m-0">
      <div class="col-12 p-2" style="display: contents;">
        <div class="img-container">

          <div class="card-loader" style="top: 30% !important;" *ngIf="!isTxnApiFailed &&
            ((!isYESPAY && isNonYesPayApiLoading === false && (isNonYesPayApiFailed || ((menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' && !virtualCardObj) || (menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && !physicalCardObj))))
            || (isYESPAY && isYesPayApiLoading === false && (isYesPayApiFailed || !virtualCardObj)))">
            <div class="d-flex justify-content-center">Unable to fetch details!</div>
            <div class="d-flex justify-content-center align-items-center" (click)="callAllAPIs(false)"
              style="cursor: pointer;">
              <img src="../../assets/img/reloadCard.svg">Reload
            </div>
          </div>

          <!-- Card -->
          <!-- <div class="cardBg ml-auto mr-auto text-center" [style.background-image]="'url(' + menu?.ypsorPrepaidProgram?.cardImageLink + ')'"
            *ngIf="((isYESPAY && virtualCardObj && !isYesPayApiLoading && !isYesPayApiFailed) || (!isYESPAY && !isNonYesPayApiLoading && !isNonYesPayApiFailed && ((menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' && virtualCardObj) || (menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && physicalCardObj))))"
            [ngClass]="{ 'vcard': (isYESPAY && !userData?.isKeyChainUser), 'non-yespay-card': !isYESPAY, 'unable-to-load': (!isYESPAY && (!virtualCardObj && !physicalCardObj)) }">
            <h1 class="cardNumber">
              <span class="cardNumber-wrap">
                {{isYESPAY ? virtualCardObj?.cardNumber : (!isYESPAY && virtualCardObj &&
                menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' ? virtualCardObj?.cardNumber :
                physicalCardObj?.cardNumber)}}
              </span>
              <button class="crd-cpy">
                <img (click)="copyCardNumber()" src="../../assets/img/vCardNewCopy.svg">
              </button>
            </h1>
            <h2 class="expDate clearfix"><span class="vld-thr">VALID THRU</span>
              <div>
                <div class="mh-ye-txt">
                  {{((isYESPAY && virtualCardObj) || (!isYESPAY && virtualCardObj && menu?.ypsorPrepaidProgram?.cardType
                  === 'VIRTUAL'))
                  ? virtualCardObj?.expiryMonth +' / '+ virtualCardObj?.expiryYear
                  : ((!isYESPAY && physicalCardObj && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL')
                  ? (physicalCardObj?.expiryYear)?.substr(0, 2) + ' / ' + (physicalCardObj?.expiryYear)?.substr(-2, 4)
                  : '-- / --')}}
                </div>
              </div>
            </h2>
            <h2 class="cardUserName" *ngIf="isYESPAY">{{userData?.fullName}}</h2>
          </div> -->

          <div class="cardBg ml-auto mr-auto text-center" *ngIf="((isYESPAY && virtualCardObj && !isYesPayApiLoading && !isYesPayApiFailed) || (!isYESPAY && !isNonYesPayApiLoading && !isNonYesPayApiFailed && ((menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' && virtualCardObj) || (menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && physicalCardObj))))" [ngClass]="{ 'vcard': (isYESPAY && !userData?.isKeyChainUser), 'non-yespay-card': !isYESPAY, 'unable-to-load': (!isYESPAY && (!virtualCardObj && !physicalCardObj)) }">
            <div class="txnSec-spinner" *ngIf="menu?.ypsorPrepaidProgram?.show!=true && menu?.ypsorPrepaidProgram?.error!=true">
              <div class="txnSec-inner pl-0"><span class="spinner-border" role="status"><span class="sr-only">Loading...</span></span></div>
            </div>
            <img src="{{menu?.ypsorPrepaidProgram?.cardImageLink}}" [ngClass]="{'card-image-visible' : menu?.ypsorPrepaidProgram?.show}" class="card-image" (load)="menu.ypsorPrepaidProgram.show=true" (error)="menu.ypsorPrepaidProgram.error=true">
            <ng-container *ngIf="menu?.ypsorPrepaidProgram?.show!=true && menu?.ypsorPrepaidProgram?.error==true">
              <h2 class="unable-to-load"><span>UNABLE TO LOAD IMAGE</span><br>
                <!-- <img (click)="callAllAPIs(false)" style="cursor: pointer;" src="../../assets/img/reloadCard.svg"> -->
              </h2>
            </ng-container>
            <ng-container *ngIf="menu?.ypsorPrepaidProgram?.show">
              <h1 class="cardNumber">
                <span class="cardNumber-wrap"> {{isYESPAY ? virtualCardObj?.cardNumber : (!isYESPAY && virtualCardObj && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' ? virtualCardObj?.cardNumber : physicalCardObj?.cardNumber)}}</span>
                <button class="crd-cpy"><img (click)="copyCardNumber()" src="../../assets/img/vCardNewCopy.svg"></button>
              </h1>
              <h2 class="expDate clearfix"><span class="vld-thr">VALID THRU</span>
                <div>
                  <div class="mh-ye-txt">{{((isYESPAY && virtualCardObj) || (!isYESPAY && virtualCardObj && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL')) ? virtualCardObj?.expiryMonth +' / '+ virtualCardObj?.expiryYear : ((!isYESPAY && physicalCardObj && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL') ? (physicalCardObj?.expiryYear)?.substr(0, 2) + ' / ' + (physicalCardObj?.expiryYear)?.substr(-2, 4) : '-- / --')}}</div>
                </div>
              </h2>
              <h2 class="cardUserName" *ngIf="isYESPAY">{{userData?.fullName}}</h2>
            </ng-container>

            <!-- Inserted card balance info box code here for layout purpose -->

            <div class="card-balance-info" *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && physicalCardObj && cardBalanceInfo">
              <span class="card-balance-info-header-text">Card Balance</span>
              <img style="cursor: pointer;" src="../../assets/img/close.png" (click)="toggleCardBalanceInfo()">
              <p class="card-balance-info-description-text">
                The card balance displayed here is not updated real-time. It may take take upto 24 hrs to reflect actual balance.
              </p>
            </div>


          </div>
        </div>

        

      </div>
      <div class="col-12 text-center min-h-62">
        <div>
          <div class="row card-info-row" [ngStyle]="{'color': menu?.ypsorPrepaidProgram?.cardBackgroundTextColor ? menu?.ypsorPrepaidProgram?.cardBackgroundTextColor : '#000000'}">

            <ng-container *ngIf="((isYESPAY && virtualCardObj && !isYesPayApiLoading && !isYesPayApiFailed) || (!isYESPAY && !isNonYesPayApiLoading && !isNonYesPayApiFailed && (virtualCardObj || physicalCardObj)))">

              <!-- <div class="card-loader">
                <div class="d-flex justify-content-center">Unable to fetch details!</div>
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../../assets/img/reloadCard.svg">Reload
                </div>
              </div> -->

              <div class="col card-info">
                <div style="white-space: nowrap;">{{(!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' &&
                  menu?.ypsorPrepaidProgram?.physicalCardSubType === 'NCMC') ? 'Wallet ' : ''}}
                  Balance
                </div>
                <div><span class="card-currency rupee-symbol">₹ </span>
                  <span>{{isYESPAY ? (userData?.walletBalance?.trim() || 0) :
                    (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' ? (physicalCardObj?.walletBalance?.trim() || 0) :
                    (virtualCardObj?.walletBalance?.trim() || 0)) | number}}</span>
                </div>
              </div>

              <div class="vl-line" *ngIf="(!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' &&
                menu?.ypsorPrepaidProgram?.physicalCardSubType === 'NCMC')">
              </div>

              <div class="col card-info card-bln-icn" style="min-width: 140px;" *ngIf="(!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' &&
                menu?.ypsorPrepaidProgram?.physicalCardSubType === 'NCMC')">
                <div class="ml-10">Card Balance
                  <img style="cursor: pointer" src="../../assets/img/question-mark.svg"
                  (click)="toggleCardBalanceInfo()">
                </div>
                <div><span class="card-currency rupee-symbol">₹ </span>
                  <span>{{(physicalCardObj?.cardBalance?.trim() || 0) | number}}</span>
                </div>
              </div>

              <div class="vl-line"></div>

              <div class="col card-info">
                <div class="ml-10">Status</div>

                <div *ngIf="virtualCardObj && (isYESPAY || (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'))" class="text-uppercase">
                  {{cardStatusList.includes(virtualCardObj?.cardStatus?.toLowerCase()) ? 'ACTIVE' : virtualCardObj?.cardStatus}}
                </div>

                <div *ngIf="physicalCardObj && (!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL')" class="text-uppercase">
                  {{cardStatusList.includes(physicalCardObj?.cardStatus?.toLowerCase()) ? 'ACTIVE' : physicalCardObj?.cardStatus}}
                </div>

              </div>

              <div class="vl-line"
                *ngIf="((isYESPAY && virtualCardObj) || (!isYESPAY && ((menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && menu?.ypsorPrepaidProgram?.physicalCardSubType !== 'NCMC') || (menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'))))">
              </div>

              <div
                *ngIf="((isYESPAY && virtualCardObj) || (!isYESPAY && ((menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && menu?.ypsorPrepaidProgram?.physicalCardSubType !== 'NCMC') || (menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL'))))"
                class="col card-info">
                <div class="cvv-wrpr card-info">
                  <div>CVV</div>
                  <div *ngIf="fetchingCvv" class="loading-cvv">
                    <span class="spinner-border small-loader" [ngStyle]="{'color': menu?.ypsorPrepaidProgram?.cardBackgroundTextColor ? menu?.ypsorPrepaidProgram?.cardBackgroundTextColor : '#000000'}" role="status">
                      <span class="sr-only">Loading...</span>
                    </span>
                  </div>
                  <div *ngIf="!fetchingCvv" [ngClass]="{'cvv-open': !hideCvv,'cvv-start': hideCvv}"
                    [ngStyle]="{ 'padding-bottom' : '2px' }">
                    <span (click)="showCvv()">{{hideCvv?'***': decryptedCvv}}
                      <i class="icon-hide" *ngIf="!hideCvv"></i>
                      <i class="icon-view" *ngIf="hideCvv"></i>
                    </span>
                  </div>
                </div>
              </div>

            </ng-container>

          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card-btn-wpr">
          <div class="card-btn" (click)="toggleKeychainModal()"
            *ngIf="isYESPAY && !isYesPayApiLoading && !isYesPayApiFailed && (userData && (!userData?.isKeyChainUser || (physicalCardObj && physicalCardObj?.cardStatus == 'BLOCKED')))">
            {{physicalCardObj && physicalCardObj?.cardStatus == 'BLOCKED' ? 'Replace Keychain' : 'Link Keychain'}}
          </div>
          <div (click)="goToAddfunds()"
            *ngIf="((isYESPAY && !isYesPayApiLoading && !isYesPayApiFailed && virtualCardObj) || (!isYESPAY && !isNonYesPayApiLoading && !isNonYesPayApiFailed && (virtualCardObj || physicalCardObj)))"
            class="card-btn" [ngStyle]="{ 'color' : 'white' }">
            Add Funds
          </div>
        </div>
        <!-- <div class="non-yespay-card-i"
          *ngIf="(!isYESPAY && menu?.ypsorPrepaidProgram?.programInstruction && menu?.ypsorPrepaidProgram?.programInstruction?.trim() != '' && !isNonYesPayApiLoading && !isNonYesPayApiFailed && (virtualCardObj || physicalCardObj))">
          <img src="../../assets/img/chalo-best-card-title-img.svg">
          <span class="non-yespay-card-i-text">{{menu?.ypsorPrepaidProgram?.programInstruction}}</span>
        </div> -->
      </div>
      <div class="spinner-container" style="max-height: 341px; margin-top: 0" *ngIf="((isYESPAY && isYesPayApiLoading) || (!isYESPAY && isNonYesPayApiLoading))">
        <span class="spinner-border spinner-large loadingSplinner" role="status">
          <span class="sr-only"></span>
        </span>
      </div>

      <!-- <div class="row m-0 mt-1" id="tran-sce-info" [ngStyle]="{'top': (!isYESPAY && menu?.ypsorPrepaidProgram?.programInstruction && menu?.ypsorPrepaidProgram?.programInstruction?.trim() != '' && !isNonYesPayApiLoading && !isNonYesPayApiFailed && (virtualCardObj || physicalCardObj)) ? '477px' : '406px'}"> -->
      <div class="row m-0 mt-1" id="tran-sce-info">

        <div class="card-loader" *ngIf="isTxnApiFailed &&
          !(((!isYESPAY && !isNonYesPayApiLoading && (isNonYesPayApiFailed || ((menu?.ypsorPrepaidProgram?.cardType === 'VIRTUAL' && !virtualCardObj) || (menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && !physicalCardObj))))
          || (isYESPAY && !isYesPayApiLoading && (isYesPayApiFailed || !virtualCardObj))))">
          <div class="d-flex justify-content-center">Unable to fetch details!</div>
          <div class="d-flex justify-content-center align-items-center" (click)="getTransactions()"
            style="cursor: pointer;"><img src="../../assets/img/reloadCard.svg">Reload</div>
        </div>

        <div class="spinner-container" *ngIf="isTxnLoading">
          <span class="spinner-border spinner-large loadingSplinner" role="status">
            <span class="sr-only"></span>
          </span>
        </div>

        <div class="col-12 mt-0 txnSec-wrpr h-100" *ngIf="!isTxnLoading && !isTxnApiFailed">
          <div class="h-100">
            <div class=" txnSec-wrpr-content h-100">
              <h2 class="lts-txn-col">
                <span class="lts-txn-ttl">Latest Transactions</span>
              </h2>
              <div class="txnSec h-90" [ngClass]="{'txnSec-90': txnsList?.length <= 5}" *ngIf="!isTxnLoading && !isTxnApiFailed">
                <div *ngFor="let txn of txnsList | slice:0:5">
                  <div class="txnSec-bdr d-flex flex-wrap" (click)="getDisplayDetail(txn)">
                    <div class="txnSec-inner w-100">

                      <div class="txnSec-img">
                        <img *ngIf="txn.entry_type == 'DEBIT'" src="../../assets/img/DEBIT.svg">
                        <img *ngIf="txn.entry_type == 'CREDIT'" src="../../assets/img/CREDIT.svg">
                      </div>

                      <!-- INFO 1 -->
                      <div class="d-flex justify-content-between align-items-center" style="margin: 4px 0 8px;">
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'UPI_PPI_INWARD'">Money Received</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'UPI_PPI_OUTWARD'">{{txn.transaction_tag.Payee_Name ? txn.transaction_tag.Payee_Name : "UPI Spend"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'BBPS'">{{txn.transaction_tag.biller_name ? txn.transaction_tag.biller_name : "Bill Payment"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'COBANK_UPI_LOAD'">Wallet Top Up</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'RECHARGE'">{{txn.transaction_tag.operator ? txn.transaction_tag.operator + ' ' + txn.transaction_tag.mobile_number.slice(-4) : "Mobile Recharge"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'MERCHANT'">{{txn.transaction_tag.Merchant_Name ? txn.transaction_tag.Merchant_Name : "Merchant Spend"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'E_COLLECT'">Wallet Top Up</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'FastagRetail_Load'">Fastag recharge</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'SendMoney'">Wallet Transfer</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'Bank Transfer'">Bank Transfer</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'FEERATE'">Bank Transfer Fee</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'ECOMMERCE'">{{txn.transaction_tag.Merchant_Name ? txn.transaction_tag.Merchant_Name : "Merchant Spend"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'RequestMoney'">Money Received</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'PAYU'">Wallet Top Up</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'UPI'">Wallet Top Up</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'CC'">Wallet Top Up</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'CBS'">Wallet Top Up</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'CASHBACK'">Cashback Received</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'DEAL'">{{txn.transaction_tag.Merchant_Name ? txn.transaction_tag.Merchant_Name : "Merchant Spend"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'Dutch'">Money Received</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'Non App Transfer'">{{txn.entry_type === 'CREDIT' ? "Money Received" : "Money Spent"}}</div>
                        <div class="tran-ttl" *ngIf="txn.transaction_type === 'TEMP'">{{txn.entry_type === 'CREDIT' ? "Money Received" : "Money Spent"}}</div>
                        <div class="tran-ttl" *ngIf="!txnTypes.includes(txn.transaction_type) && txn.entry_type === 'CREDIT'">Money Received</div>
                        <div class="tran-ttl" *ngIf="!txnTypes.includes(txn.transaction_type) && txn.entry_type === 'DEBIT'">Money Spent</div>
                        <div class="tran-ttl" [ngClass]="{'active': txn.entry_type == 'CREDIT'}">{{(txn.entry_type == 'CREDIT' ? '+' : '-')+ ' ₹ '}}{{txn.amount | number}}</div>
                      </div>

                      <!-- INFO 2 -->
                      <div class="d-flex justify-content-between">
                        <div class="tran-dt">
                          <ng-container *ngIf="txn.displayDetail">
                            <div *ngIf="txn.transaction_type === 'UPI_PPI_INWARD'">UPI</div>
                            <div *ngIf="txn.transaction_type === 'UPI_PPI_OUTWARD'">{{txn.transaction_tag.Payee_VPA}}</div>
                            <div *ngIf="txn.transaction_type === 'BBPS'">{{txn.transaction_tag.biller_category}}</div>
                            <div *ngIf="txn.transaction_type === 'COBANK_UPI_LOAD'">{{txn.transaction_tag.Payer_Name}}{{txn.transaction_tag.Payer_VPA ? ' | ' + txn.transaction_tag.Payer_VPA : '' }}</div>
                            <div *ngIf="txn.transaction_type === 'RECHARGE'">{{txn.transaction_tag.operator ? "Mobile Recharge" : ''}}</div>
                            <div *ngIf="txn.transaction_type === 'MERCHANT'">{{txn.transaction_tag.Txn_Type}}</div>
                            <div *ngIf="txn.transaction_type === 'E_COLLECT'">E-Collect</div>
                            <div *ngIf="txn.transaction_type === 'FastagRetail_Load'"></div>
                            <div *ngIf="txn.transaction_type === 'SendMoney'"></div>
                            <div *ngIf="txn.transaction_type === 'Bank Transfer'"></div>
                            <div *ngIf="txn.transaction_type === 'FEERATE'"></div>
                            <div *ngIf="txn.transaction_type === 'ECOMMERCE'">ECOM</div>
                            <div *ngIf="txn.transaction_type === 'RequestMoney'">UPI Collect</div>
                            <div *ngIf="txn.transaction_type === 'PAYU'">PayU</div>
                            <div *ngIf="txn.transaction_type === 'UPI'">UPI</div>
                            <div *ngIf="txn.transaction_type === 'CC'">Credit Card</div>
                            <div *ngIf="txn.transaction_type === 'CBS'">YES BANK</div>
                            <div *ngIf="txn.transaction_type === 'CASHBACK'"></div>
                            <div *ngIf="txn.transaction_type === 'DEAL'">ECOM</div>
                            <div *ngIf="txn.transaction_type === 'Dutch'">Collect</div>
                            <div *ngIf="txn.transaction_type === 'Non App Transfer'"></div>
                            <div *ngIf="txn.transaction_type === 'TEMP'"></div>
                          </ng-container>
                          <div *ngIf="txn?.wallet_balance" class="wlt-blnc" [ngStyle]="{'padding-top':
                                txn.displayDetail
                            && ((txnTypesForWalletBalance?.includes(txn?.transaction_type?.trim()))
                            || (txn?.transaction_type === 'UPI_PPI_OUTWARD' && txn?.transaction_tag?.Payee_VPA)
                            || (txn?.transaction_type === 'BBPS' && txn?.transaction_tag?.biller_category)
                            || (txn?.transaction_type === 'RECHARGE' && txn?.transaction_tag?.operator)
                            || (txn?.transaction_type === 'MERCHANT' && txn?.transaction_tag?.Txn_Type)
                            || (txn?.transaction_type === 'COBANK_UPI_LOAD' && (txn?.transaction_tag?.Payer_Name || txn?.transaction_tag?.Payer_VPA)))
                            ? '8px' : '0px'}">
                            <img src="../../assets/img/wallet-ruppe.svg">
                            <span>: {{(txn?.wallet_balance) ? 'Rs. ' + txn?.wallet_balance : ''}}</span>
                          </div>
                        </div>
                        <div class="tran-dt">{{moment(txn.transaction_date).format('DD/MM/YYYY • h:mm A')}}</div>
                      </div>

                      <div class="arrow-icon">
                        <span class="arrow-down" *ngIf="!txn.displayDetail"></span>
                        <span class="arrow-up" *ngIf="txn.displayDetail"></span>
                      </div>

                    </div>

                    <div class="trx-dtls" *ngIf="txn.displayDetail">
                      <div class="trx-dt">Txn ID: {{txn.merchant_reference_number}}
                      </div>
                    </div>

                  </div>
                </div>

                <div class="txnSec-bdr" *ngIf="!isTxnLoading && !isTxnApiFailed && txnsList?.length === 0">
                  <div class="txnSec-inner-notext" *ngIf="!isTxnLoading && txnsList?.length === 0">
                    <span *ngIf="!isTxnLoading && txnsList?.length === 0">
                      No transactions present!
                    </span>
                  </div>
                </div>

                <div style="position: absolute; bottom: 20px; left: 0; right: 0;">
                  <button class="View-more-btn text-more" [ngStyle]="{'opacity' : txnsList?.length < 5 ? '0.5' : '1'}" (click)="goToTransactionPage()" [disabled]="txnsList?.length < 5">View More</button>
                </div>

                <!-- <span class="View-more-btn text-more" *ngIf="txnsList?.length > 5" (click)="goToTransactionPage()">
                  View More
                </span> -->
              </div>

              <!-- <div class="spinner-container" *ngIf="isTxnLoading">
                <span class="spinner-border spinner-large loadingSplinner" role="status">
                  <span class="sr-only"></span>
                </span>
              </div> -->

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="alert-modal d-none" [ngClass]="{'d-block':linkKeychain}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">
    <div class="alert-modal-head d-flex align-items-center justify-content-between">{{alertTitle}}<span
        (click)="toggleKeychainModal()"></span></div>
    <div class="alert-modal-body">
      <div class="login-wrpr" *ngIf="!(showAlertMsg)" [ngClass]="{'v-hidden':isLoading}">
        <form [formGroup]="keyChainForm">
          <mat-form-field class="form-group example-full-width mb-3">
            <mat-label>Enter{{isReplace? ' new' : ''}} Kit No.</mat-label>
            <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" numbersOnly class="form-control"
              placeholder="" name="kitNo" formControlName="kitNo" autocomplete="off">
            <mat-error *ngIf="keyChainForm.get('kitNo').errors && keyChainForm.get('kitNo').errors.required">
              Kit Number is Required
            </mat-error>
          </mat-form-field>
          <mat-form-field class="form-group example-full-width mb-3">
            <mat-label>Enter{{isReplace? ' new' : ''}} Validation No.</mat-label>
            <input matInput type="tel" (input)="validateKeyChainInputs($event.target)" minlength="4" maxlength="4"
              numbersOnly class="form-control" placeholder="Please enter last 4 digits of the card no."
              name="validationNo" formControlName="validationNo" autocomplete="off">
            <mat-error
              *ngIf="keyChainForm.get('validationNo').errors && (keyChainForm.get('validationNo').errors.required || keyChainForm.get('validationNo').errors.pattern)">
              Validation number to be 4 numeric digits only
            </mat-error>
          </mat-form-field>
          <div *ngIf="errorMessage" class="alert-error-msg-popup">
            {{errorMessage}}
          </div>
        </form>
      </div>
      <div class="spinner-container" *ngIf="isLoading">
        <span class="spinner-border spinner-large loadingSplinner" role="status">
          <span class="sr-only"></span>
        </span>
      </div>
      <div *ngIf="showAlertMsg" class="responseMsg">
        {{alertMsg}}
      </div>
    </div>
    <div class="alert-modal-footer d-flex align-items-center justify-content-between" *ngIf="!showAlertMsg"
      [ngClass]="{'v-hidden':isLoading}">
      <div class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center"
        (click)="toggleKeychainModal()">Cancel</div>
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center"
        [ngClass]="{'disabled': keyChainForm.invalid}" (click)="linkKeyChain()">Proceed</div>
    </div>
    <div class="alert-modal-footer d-flex align-items-center justify-content-between"
      *ngIf="showAlertMsg && keychainLinkStatus" [ngClass]="{'v-hidden':isLoading}">
      <div class="alert-modal-footer-btn alert-modal-footer-btn-cancel d-flex align-items-center justify-content-center"
        (click)="toggleKeychainModal()">Later</div>
      <div class="alert-modal-footer-btn d-flex align-items-center justify-content-center" (click)="gotoSetPinPage()">
        Set PIN Now</div>
    </div>
  </div>
</div>

<!-- <div class="card-balance-info"
  *ngIf="!isYESPAY && menu?.ypsorPrepaidProgram?.cardType === 'PHYSICAL' && physicalCardObj && cardBalanceInfo">
  <span class="card-balance-info-header-text">Card Balance</span>
  <img style="cursor: pointer;" src="../../assets/img/close.png" (click)="toggleCardBalanceInfo()">
  <p class="card-balance-info-description-text">
    The card balance displayed here is not updated real-time. It may take take upto 24 hrs to reflect actual balance.
  </p>
</div> -->

<div class="alert-modal d-none" [ngClass]="{'d-block': addFundsModal}">
  <div class="backdrop"></div>
  <div class="alert-modal-content">
    <div class="alert-modal-head d-flex align-items-center justify-content-between"
      style="justify-content: right !important;"><span (click)="goToAddfunds()"></span></div>
    <div class="alert-modal-body" style="text-align: justify; padding-bottom: 20px;">
      Due to some technical issue, we have disabled this functionality. Sorry for the inconvenience caused
    </div>
  </div>
</div>

<app-loader loaderText="Please Wait..." class="d-none"></app-loader>
