import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { AuthInterceptor } from './auth.interceptor';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputModule } from 'ng-otp-input';
import { QrCodeModule } from 'ng-qrcode';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AlertModule } from '../app/_alert';
import { ModalModule } from './_modal';
import { AddFundsComponent } from './add-funds/add-funds.component';
import { CardDetailComponent } from './card-detail/card-detail.component';
import { CardTransactionComponent } from './card-transaction/card-transaction.component';
import { CardComponent } from './card/card.component';
import { DataService } from "./data.service";
import { CardReplaceDialogComponent } from './dialog-box/card-replace-dialog/card-replace-dialog.component';
import { CardReplaceDialogService } from './dialog-box/card-replace-dialog/card-replace-dialog.service';
import { ConfirmationDialogComponent } from './dialog-box/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './dialog-box/confirmation-dialog/confirmation-dialog.service';
import { SetPinDialogComponent } from './dialog-box/set-pin-dialog/set-pin-dialog.component';
import { SetPinDialogService } from './dialog-box/set-pin-dialog/set-pin-dialog.service';
import { DateSlashDirective } from './directive/dateslash.directive';
import { OnlynumberDirective } from './directive/onlynumber.directive';
import { FaqsComponent } from './faqs/faqs.component';
import { MaskPipe } from './filters/mask.pipe';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './menu/menu.component';
import { SecureUsageGuidelinesComponent } from './secure-usage-guidelines/secure-usage-guidelines.component';
import { SetPinComponent } from './set-pin/set-pin.component';
import { SettingsComponent } from './settings/settings.component';
import { TermsComponent } from './terms/terms.component';

@NgModule({
    declarations: [
        LoginComponent,
        CardDetailComponent,
        CardTransactionComponent,
        ConfirmationDialogComponent,
        CardReplaceDialogComponent,
        SetPinDialogComponent,
        OnlynumberDirective,
        DateSlashDirective,
        AddFundsComponent,
        SetPinComponent,
        MenuComponent,
        SettingsComponent,
        MenuComponent,
        HeaderComponent,
        LoaderComponent,
        MaskPipe,
        TermsComponent,
        SecureUsageGuidelinesComponent,
        FaqsComponent,
        CardComponent,
        AppComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        NgOtpInputModule,
        NgbModule,
        AlertModule,
        InfiniteScrollModule,
        QrCodeModule,
        MatSliderModule,
        ModalModule
    ],
    providers: [
        DataService,
        ConfirmationDialogService,
        CardReplaceDialogService,
        SetPinDialogService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
